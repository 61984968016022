import React, { useCallback, useState, useEffect, useReducer } from 'react';
import type { FC, ChangeEvent } from 'react';
import type {
  ContentMostViewedResource,
} from 'src/common/types';
import {
  Box,
  Container,
  Divider,
  Tab,
  Tabs
} from '@mui/material';
import {
  useParams,
  useLocation,
  fetchDetailsContentMostViewed,
  _contentMostViewed,
  AccessModel,
  useNavigate,
  root
} from 'src/common';
import Page from 'src/components/Page';
import ContentMostViewedModel from 'src/model/content/ContentMostViewed';
import DetailsEditForm from './Forms/DetailsEditForm';
import Details from './Details';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import GenericHeader from 'src/components/header/GenericHeader';

const ContentMostViewedDetailsView: FC = () => {
  const model = ContentMostViewedModel.getInstance();
  const access = new AccessModel(model.ResourceCode);
  const navigate = useNavigate();
  const location = useLocation();
  const [state, dispatch] = useReducer(_contentMostViewed, { contentMostViewed: {} });
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editMode, setEditMode] = useState({
    details: false,
    subscription: false,
  });
  const [currentTab, setCurrentTab] = useState<string>('details');
  const { id } = useParams();

  const tabs = [
    { value: 'details', label: 'Détails' }
  ];

  const getContentMostViewedById = useCallback(async () : Promise<void> => {
    if (!id || isNaN(+id)) {
      navigate(model.Path.Home);
    }else if(access.canRead()){
      const model = new ContentMostViewedModel();
      model.PathName = location.pathname;
      const contentMostViewed = await model.getContentMostViewedById(id);
      if (!model.error) {
        dispatch(fetchDetailsContentMostViewed(contentMostViewed));
      }
    }
  }, [id, location]);

  useEffect(() => {
    (async () => {
      await getContentMostViewedById();
    })();
  }, []);

  const setEditModeContentMostViewed = (isEdit: boolean) => {
    setEditMode({
      ...editMode,
      [currentTab]: isEdit
    });
    setIsEditing(isEdit);
  };

  const switchMode = (isEdit: boolean) => {
    setEditModeContentMostViewed(isEdit);
  };

  const handleTabsChange = (event: ChangeEvent<any>, value: string): void => {
    setEditModeContentMostViewed(false);
    setCurrentTab(value);
  };

  const handleRefreshContentMostViewed = (contentMostViewd: ContentMostViewedResource) => {
    dispatch(fetchDetailsContentMostViewed(contentMostViewd));
    setEditModeContentMostViewed(false);
  };

  const editContentMostViewed = () => {
    setEditModeContentMostViewed(true);
  };

  const { contentMostViewed } = state;

  if (Object.keys(contentMostViewed).length === 0) {
    return <LoadingScreen />
  }

  model.Title = contentMostViewed.title;
  
  const renderContent = () => {
    switch (currentTab) {
      case 'details':
        if (editMode.details) {
          return (
            <DetailsEditForm
              model={model} 
              contentMostViewed={contentMostViewed}
              onHandleRefreshContentMostViewed={handleRefreshContentMostViewed}
              onSwitchMode={(l) => switchMode(l)}
            />
          );
        }
        return (
          <Details model={model} contentMostViewed={contentMostViewed} />
        );
      default:
        return '';
    }
  };

  return (
    <Page
      sx={root}
      title={model.DetailPageTitle}
    >
      <Container maxWidth={false}>
        <GenericHeader 
          id={id} 
          model={model} 
          headerTitle={model.Title} 
          actions={['editDetail','delete']}
          editMode={editMode.details}
          onEditDetail={() => editContentMostViewed()}
        />
        <Box mt={2}>
          <Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            value={currentTab}
            variant="scrollable"
            textColor="secondary"
          >
            {tabs.map((tab) => (
              <Tab
                key={tab.value}
                label={tab.label}
                value={tab.value}
              />
            ))}
          </Tabs>
        </Box>
        <Divider />
        <Box mt={3}>
          { renderContent() }
        </Box>
      </Container>
    </Page>
  );
};

export default ContentMostViewedDetailsView;
