import { FC } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, ListItem, SvgIcon, Typography } from '@mui/material';
import type { ContentPublicationsResource } from 'src/common/types';
import { ExternalLink as OtherIcon } from 'react-feather';
import { ContentPublicationsModel } from 'src/common';
import GeneralInfo from './GeneralInfo';
import ContentInfo from './ContentInfo';
import VisibilityInfo from 'src/components/visibility/VisibilityInfo';
import CoverInfo from './CoverInfo';

export interface DetailsProps {
  contentPublication: ContentPublicationsResource;
  model: ContentPublicationsModel;
}

const noStyle = {
  width: '100%',
  justifyContent: 'flex-start',
  fontWeight: 'normal',
  justifyItems: 'flex-start',
  display: 'flex'
}

const Details: FC<DetailsProps> = ({
  contentPublication,
  model
}) => {
  const { cover } = contentPublication;
  return (
    <Grid container spacing={3}>
      <Grid item lg={4} md={6} xl={4} xs={12}>
        <ListItem sx={noStyle}>
          <GeneralInfo contentPublication={contentPublication} model={model} />
        </ListItem>
      </Grid>
      <Grid item lg={4} md={6} xl={4} xs={12}>
        <ListItem sx={noStyle}>
          <VisibilityInfo content={contentPublication} />
        </ListItem>
        <ListItem sx={noStyle}>
          <ContentInfo contentPublication={contentPublication} model={model} />
        </ListItem>
      </Grid>
      <Grid item lg={4} md={6} xl={4} xs={12}>
        <ListItem sx={noStyle}>
          {
            cover && (
              <ListItem sx={noStyle}>
                <CoverInfo cover={cover} />
              </ListItem>
            )
          }
        </ListItem>
      </Grid>
   </Grid> 
  );
}

Details.propTypes = {
  contentPublication: PropTypes.object.isRequired
};

export default Details;
