import React, { useState } from 'react';
import type { ChangeEvent, FC } from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Box, CardContent, TextField } from '@mui/material';
import {
    Formik,
    Mode,
    CaijButtonSubmit,
    CaijButtonReset,
    btnSubmit,
    CaijInput,
    labelConfig,
    CaijCard,
    handleChangeImageUrl,
    DocLanguage,
    handleChangeSwitch,
    CaijSwitch
} from 'src/common';
import {
    KnownDocumentType,
    type CreateResponse,
    type DocumentResource,
    type DocumentResourceForCreate,
    type DocumentResourceForEdit,
    type Error
} from 'src/common/types';
import Authorize from 'src/components/Authorize';
import MyCard from 'src/components/card/MyCard';
import { Root } from 'src/components/styled';
import UploadImage from 'src/components/image/UploadImage';
import CaijFormImage from 'src/components/image/CaijFormImage';
import SpecialFilesModel from 'src/model/content/SpecialFiles';
import { $enum } from 'ts-enum-util';
import HtmlContentEditor from 'src/components/content/HtmlContentEditor';
import { EditableDocumentModel } from 'src/model/EditableDocument';
import CaijDraftEditor from 'src/components/draftEditor/CaijDraftEditor';

export interface SpecialFilesEditFormProps {
    model: SpecialFilesModel;
    documentContentModel: EditableDocumentModel;
    document: DocumentResource;
    createMode?: boolean;
    onSubmit?: (values: DocumentResource) => void;
}

async function redirect(model: SpecialFilesModel, response: Error | CreateResponse): Promise<void> {
    await model.redirect(model.Path.Home, {
        status: response.status,
        message: response.message
    });
};

const SpecialFilesEditForm: FC<SpecialFilesEditFormProps> = ({
    model,
    documentContentModel,
    document,
    onSubmit,
    createMode
}) => {
    const [isAuth, setIsAuth] = useState<boolean>();
    const { Titre, Description, Content, Cover } = model;

    const handleSubmit = async (values) => {
        const { title, description, cover, lang, visible, searchable, content, archived, published, publisher, documentType, collection } = values;
        const id = title.replace(/[^a-zA-Z0-9]/g, "");
        if (createMode) {
            const submitData: DocumentResourceForCreate = {
                cover,
                documentId: id,
                collection: "special_files",
                title,
                documentStore: "BLOB",
                documentStoreInfo: "dossiers-speciaux",
                lang,
                publisher: "CAIJ",
                documentType: KnownDocumentType.SpecialFiles,
                content: content || "",
                visible,
                searchable,
                metadatas: JSON.stringify({ description, archived: archived ? new Date().toISOString() : null }),
                identifier: id,
                filepath: id + ".html",
                published
            }

            if (onSubmit) {
                onSubmit(submitData);
                return;
            }

            const response = await model.createSpecialFile(submitData);
            if (!model.error) {
                await redirect(model, response);
            }
            model.resetError();
        } else {
            const metadatas = document.metadatas ? JSON.parse(document.metadatas) : null;
            const submitData: DocumentResourceForEdit = {
                ...document as DocumentResourceForEdit,
                cover,
                title,
                lang,
                visible,
                searchable,
                publisher,
                published,
                collection,
                documentType,
                metadatas: JSON.stringify({ description, archived: archived ? metadatas?.archived || new Date().toISOString() : null }),
            }

            if (onSubmit) {
                onSubmit(submitData);
                return;
            }

            const response = await model.updateDocument(submitData);
            if (!model.error) {
                await redirect(model, response);
            }
            model.resetError();
        }
    }

    return (
        <Formik
            initialValues={{
                ...document,
                title: document?.title || "",
                description: document.metadatas && JSON.parse(document?.metadatas)?.description || "",
                archived: document.metadatas && JSON.parse(document?.metadatas)?.archived ? true : false,
                visible: document?.visible || false,
                searchable: document?.searchable || false,
                lang: document?.lang || "fr",
                cover: document?.cover || ""
            }}
            validateOnChange={false}
            onSubmit={handleSubmit}
            validationSchema={Yup.object().shape({
                title: Titre.required(true),
                description: Description.required(true),
                content: createMode ? Content.required(true) : null
            })}
        >
            {({
                errors, handleBlur, handleChange, handleSubmit, setFieldValue, values, touched, isSubmitting
            }) => (
                <form onSubmit={handleSubmit}>
                    <MyCard>
                        <CardContent>
                            <Root>
                                <CaijInput
                                    required
                                    name={Titre.Name}
                                    id={Titre.Name}
                                    value={values.title}
                                    helperText={touched.title && errors.title}
                                    error={Boolean(touched.title && errors.title)}
                                    label={Titre.Label}
                                    InputLabelProps={{ shrink: true, required: true, }}
                                    inputAttr={{ maxLength: Titre.MaxLength, 'data-testid': 'title' }}
                                    onHandleBlur={handleBlur}
                                    onHandleChange={handleChange}
                                    setFieldValue={setFieldValue}
                                />
                                <CaijDraftEditor
                                    title={labelConfig.description}
                                    dataTestId='description'
                                    value={values.description || ''}
                                    name={Description.Name}
                                    setFieldValue={setFieldValue}
                                    errors={errors.description as string}
                                    allowedValidate
                                    touched={touched.description as boolean}
                                />
                                <Box>
                                    <CaijSwitch
                                        name='visible'
                                        label={labelConfig.active}
                                        checked={values.visible}
                                        value={values.visible}
                                        sx={{ mb: 2 }}
                                        inputProps={{ 'aria-label': 'visible', 'data-testid': 'visible' }}
                                        onHandleChangeSwitch={handleChangeSwitch}
                                        setFieldValue={setFieldValue}
                                    />
                                </Box>
                                <Box>
                                    <CaijSwitch
                                        name='searchable'
                                        label={labelConfig.searchable}
                                        checked={values.searchable}
                                        value={values.searchable}
                                        sx={{ mb: 2 }}
                                        inputProps={{ 'aria-label': 'searchable', 'data-testid': 'searchable' }}
                                        onHandleChangeSwitch={handleChangeSwitch}
                                        setFieldValue={setFieldValue}
                                    />
                                </Box>
                                <Box>
                                    <CaijSwitch
                                        name='archived'
                                        label={labelConfig.archived}
                                        checked={values.archived}
                                        value={values.archived}
                                        sx={{ mb: 2 }}
                                        inputProps={{ 'aria-label': 'archived', 'data-testid': 'archived' }}
                                        onHandleChangeSwitch={handleChangeSwitch}
                                        setFieldValue={setFieldValue}
                                    />
                                </Box>
                                <TextField
                                    required
                                    label={labelConfig.docLanguage}
                                    id='lang'
                                    name='lang'
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                        setFieldValue('lang', e.target.value);
                                    }}
                                    select
                                    SelectProps={{ native: true }}
                                    value={values.lang || ''}
                                    variant='outlined'
                                    InputLabelProps={{ shrink: true, required: true }}
                                    inputProps={{ 'aria-label': 'lang', 'data-testid': 'lang' }}
                                    sx={{ marginBottom: 3, marginTop: 2, width: '50%' }}
                                >
                                    {
                                        $enum(DocLanguage).map((value, key) => <option value={key} key={key}>{value}</option>)
                                    }
                                </TextField>
                                <CaijCard title={model.Cover.Label} allowedBackgroundColor={false}>
                                    {values.cover && (
                                        <CaijFormImage
                                            model={model}
                                            id={model.Cover.Name}
                                            name={model.Cover.Name}
                                            src={values.cover}
                                            alt={values.cover}
                                            setFieldValue={setFieldValue}
                                        />
                                    )
                                    }
                                    <UploadImage
                                        imgLabel={Cover.Label}
                                        maxLength={Cover.MaxLength}
                                        resourceCode={model.ResourceCode}
                                        style={{ height: '3em' }}
                                        btnText={values.cover ? labelConfig.imageEditBtnFr : labelConfig.imageBtnFr}
                                        imgName={Cover.Name}
                                        onHandleChangeImageUrl={handleChangeImageUrl}
                                        setFieldValue={setFieldValue}
                                    />
                                </CaijCard>
                            </Root>
                            {createMode &&
                                <HtmlContentEditor
                                    inForm
                                    pageModel={model}
                                    documentModel={documentContentModel}
                                    onChange={setFieldValue}
                                    name={model.Content.Name}
                                    label={model.Content.Label}
                                    initialContent=''
                                    errors={errors.content as string}
                                />
                            }
                            <Box mt={2}>
                                <Authorize
                                    resourceCode={model.ResourceCode}
                                    mode={Mode.edit}
                                    onIsAuth={(l) => setIsAuth(l)}
                                >
                                    <CaijButtonSubmit disabled={!isAuth || isSubmitting} sx={btnSubmit} />
                                </Authorize>
                                <CaijButtonReset disabled={isSubmitting} pathName={model.Path.getDetail(document.documentId)} />
                            </Box>
                        </CardContent>
                    </MyCard>
                </form>
            )}
        </Formik>
    )
}

SpecialFilesEditForm.propTypes = {
    document: PropTypes.object,
    onSubmit: PropTypes.func
}

SpecialFilesEditForm.defaultProps = {
    onSubmit: null
}

export default SpecialFilesEditForm;