import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Box, CardContent } from '@mui/material';
import {
  Formik,
  handleChange,
  handleChangeCheckBox,
  Mode,
  CaijButtonSubmit,
  CaijButtonReset,
  btnSubmit
} from 'src/common';
import type {
  ContentMostViewedResource,
  ContentMostViewedResourceForEdit,
  ContentMostViewedResourceForCreate,
  Error,
  CreateResponse
} from 'src/common/types';
import ContentMostViewedModel from 'src/model/content/ContentMostViewed';
import Authorize from 'src/components/Authorize';
import ContentMostViewedForm from 'src/components/content/contentMostViewed/Forms';
import { Root } from 'src/components/styled';
import MyCard from 'src/components/card/MyCard';
import validateSchema from '../Schema';

export interface ContentMostViewedEditFormProps {
  model: ContentMostViewedModel;
  contentMostViewed: ContentMostViewedResource;
  onSubmit?: (values: ContentMostViewedResource) => void
}

async function redirect(model: ContentMostViewedModel, response: Error | CreateResponse) : Promise<void>{
  await model.redirect(model.Path.Home, { 
    status: response.status, 
    message: response.message
  });
};

const ContentMostViewedEditForm: FC<ContentMostViewedEditFormProps> = ({
  model,
  contentMostViewed,
  onSubmit
}) => {
  const handleSubmit = async (values: ContentMostViewedResource) => {
    if (onSubmit) {
      onSubmit(values);
      return;
    }
    const model = new ContentMostViewedModel();
    let submitData = values as ContentMostViewedResourceForEdit;
    if (submitData && submitData.id >= 0) {
      const response = await model.updateContentMostViewed(submitData);
      if (!model.error) {
        await redirect(model, response);
      }
    } else {
      submitData = values as ContentMostViewedResourceForCreate;
      const response = await model.insertContentMostViewed(submitData);
      if (!model.error) {
        await redirect(model, response); 
      }
    }
  }

  return (
    <Formik
      initialValues={{...contentMostViewed}}
      validateOnChange={false}
      validationSchema={validateSchema(model)}
      onSubmit={handleSubmit}
    >
      {({
        errors, handleBlur, handleSubmit, setFieldValue, values, touched, isSubmitting
      }) => (
        <form onSubmit={handleSubmit}>
          <MyCard>
            <CardContent>
              <Box display="flex" flexDirection="column" alignItems="flex-start">
                <Root>
                  <ContentMostViewedForm
                    model={model}
                    contentMostViewed={values}
                    errors={errors}
                    touched={touched}
                    onHandleBlur={handleBlur}
                    onHandleChange={handleChange}
                    onHandleChangeCheckBox={handleChangeCheckBox}
                    setFieldValue={setFieldValue}
                  />
                </Root>
                <Box>
                  <Authorize
                    resourceCode={model.ResourceCode}
                    mode={contentMostViewed.id ? Mode.edit : Mode.add}
                  >
                    <CaijButtonSubmit disabled={isSubmitting} sx={btnSubmit} />
                  </Authorize>
                  <CaijButtonReset disabled={isSubmitting} pathName={model.Path.Home} />
                </Box>
              </Box>
            </CardContent>
          </MyCard>
        </form>
      )}
    </Formik>
  );
};

ContentMostViewedEditForm.propTypes = {
  contentMostViewed: PropTypes.object.isRequired,
  onSubmit: PropTypes.func
};

ContentMostViewedEditForm.defaultProps = {
  onSubmit : null
};

export default ContentMostViewedEditForm;
