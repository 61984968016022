import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import type { ContentMostViewedResource } from 'src/common/types';
import ContentMostViewedInfo from './ContentMostViewedInfo';
import { ContentMostViewedModel } from 'src/common';

interface DetailsProps {
  model: ContentMostViewedModel;
  contentMostViewed?: ContentMostViewedResource;
}

const Details: FC<DetailsProps> = ({
  model,
  contentMostViewed
}) => (
  <Grid
    container
    spacing={3}
  >
    <Grid
      item
      lg={6}
      md={6}
      xl={6}
      xs={12}
    >
      <ContentMostViewedInfo model={model} contentMostViewed={contentMostViewed} />
    </Grid>
  </Grid>
);

Details.propTypes = {
  contentMostViewed: PropTypes.object
};

Details.defaultProps = {
  contentMostViewed: {}
};

export default Details;
