import React, { useEffect, useCallback, useReducer } from 'react';
import type { FC } from 'react';
import { Box, Container } from '@mui/material';
import {
  useLocation,
  fetchContentPublishers,
  _contentPublisher,
  AccessModel,
  deleteContentPublisher,
  root
} from 'src/common';
import ContentPublishersModel from 'src/model/content/ContentPublishers';
import Page from 'src/components/Page';
import Results from './Results';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import GenericHeader from 'src/components/header/GenericHeader';

const initialState = {
  contentPublishers: [],
  isLoading: false,
  isEmptyList: true,
};

const ContentPublishersListView: FC = () => {
  const model = ContentPublishersModel.getInstance();
  const access = new AccessModel(model.ResourceCode);
  const location = useLocation();
  const [state, dispatch] = useReducer(_contentPublisher, initialState);

  const getContentPublishers = useCallback(async () : Promise<void> => {
    if(access.canRead()){
      const model = new ContentPublishersModel();
      model.PathName = location.pathname;
      const contentPublishers = await model.getContentPublishers();
      if (!model.error) {
        dispatch(fetchContentPublishers(contentPublishers, true, !Array.isArray(contentPublishers) || contentPublishers.length === 0));
      }
    }
  }, [location]);

  useEffect(() => {
    (async () => {
      await getContentPublishers();
    })();
  },[]);

  const handleDelete : (code: string) => Promise<void> = async code => {
    if(code && access.canDelete()){
      if(await model.delete(code))
        dispatch(deleteContentPublisher(code));
    }
  };

  const { contentPublishers, isEmptyList, isLoading } = state;

  if (!isLoading) return <LoadingScreen />;

  return (
    <Page sx={root} title={model.PageTitle}>
      <Container maxWidth={false}>
        <GenericHeader model={model} actions={['add']} headerTitle={model.HeaderTitle} />
        <Box mt={3}>
          <Results
            model={model}
            contentPublishers={contentPublishers}
            onDeleteContentPublisher={handleDelete}
            isEmptyList={isEmptyList}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default ContentPublishersListView;
