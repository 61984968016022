import { forwardRef, useState } from 'react';
import type { 
  LibraryTypeResource, 
  CustomerResource, 
  DatabankItemResource, 
  DocumentCollectionItemResource, 
  DatabankResource, 
  DocumentCollectionResource, 
  SubscriptionResource, 
  LibraryServiceLibraryOptionsDto, 
  SubscriptionProductResource, 
  ProductItemResource,
  ProductResource,
  LibraryServiceResource,
  LibraryResource,
  LibraryItemResource
} from 'src/common/types';
import {
  Collapse, 
  ListItem,
  Typography, 
  ListItemIcon, 
  TableRow,
  TableCell,
  ClassNameMap,
  Grid,
  Divider,
  Box,
  List
} from '@mui/material';
import { Box as BoxIcon } from 'react-feather';
import { 
  Link as RouterLink,
  ListType,
  Label,
  CARD_HEADER_TITLE_COLLECTION,
  CARD_HEADER_TITLE_DATABANK,
  CARD_HEADER_TITLE_PRODUCT_SERVICE,
  CARD_HEADER_TITLE_SUBSCRIPTION,
  EXCLUS_CARD_HEADER,
  INCLUS_CARD_HEADER,
  CARD_HEADER_TITLE_SUBSCRIPTION2,
  CustomerModel,
  CARD_HEADER_TITLE_REMOTE_ACCESS_DATABANK,
  CARD_HEADER_TITLE_LIBRARY,
  ProductModel,
  DocCollectionModel
} from 'src/common';
import { NavigateFunction } from 'react-router';
import MyCard from 'src/components/card/MyCard';
import CardHeaderListInfo from 'src/components/card/CardHeaderListInfo';
import CaijListItemText from 'src/components/CaijListItemText';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

export interface Size {
  md: number;
  xs: number;
  lg: number;
};

type TypeListArray =  LibraryServiceLibraryOptionsDto[] | SubscriptionProductResource[] | ProductItemResource[] | DatabankItemResource[] | DocumentCollectionItemResource[] | number[];
type TypeDataArray =  LibraryServiceResource[] | SubscriptionResource[] | ProductResource[] | DatabankResource[] | DocumentCollectionResource[] | SubscriptionResource[];

export interface Result {
  success: boolean,
  message: JSX.Element
}

export interface TypeList {
  id?: number;
  sx?: any;
  path?: string;
  name?: string;
  accessType?: string;
  lists?: TypeListArray;
  listType?: ListType,
  active?: boolean;
  appType?: string;
  allowedAccessType?: boolean;
  parameters?: string | number;
}

export interface SelectedAllParams {
	selectedAll?: boolean;
	selectedItems?: number[];
	listType: ListType;
	objData: {
		subscriptions?: SubscriptionResource[];
		databanks?: DatabankResource[];
    databankGroupAccess?: DatabankItemResource[];
		collections?: DocumentCollectionResource[];
		products?: ProductResource[];
		databankLibrary?: DatabankResource[];
		libraryServices?: LibraryServiceResource[];
		libraries?: LibraryResource[];
    collectionGroupAccess?: DocumentCollectionResource[];
    productGroupAccess?: ProductItemResource[];
	};
};

// #region ListInfo
export const ListInfos = () => (function() {
    let _done: number = 0;
    let _selectedAll: boolean;
    let _selectedItems: number[];
    let _selectedLibraryItems: LibraryItemResource[];

    //#region selected all
    const selectDatabank = function(databanks: DatabankResource[], fn: (value: number[]) => void) : void {
      if(_selectedAll){
        new Promise( (resolve) => {
          databanks.forEach((databank: DatabankResource) => {
            const { id } = databank;
            !_selectedItems.includes(id) ? _selectedItems.push(id) : '';
            _done++;
            _done == databanks.length - 1 ? resolve(_selectedItems) : '';
          });
        }).then((results: number[]) => fn(results));
      }else{
        _selectedItems.splice(0, _selectedItems.length);
      }
    };
    const selectDatabankGroupAccess = function(databankGroupAccess: DatabankItemResource[], fn: (value: number[]) => void) : void {
      if(_selectedAll){
        new Promise( (resolve) => {
          databankGroupAccess.forEach((databank: DatabankItemResource) => {
            const { id } = databank;
            !_selectedItems.includes(id) ? _selectedItems.push(id) : '';
            _done++;
            _done == databankGroupAccess.length - 1 ? resolve(_selectedItems) : '';
          });
        }).then((results: number[]) => fn(results));
      }else{
        _selectedItems.splice(0, _selectedItems.length);
      }
    };
    const selectDocCollection = function(collections: DocumentCollectionResource[], fn: (value: number[]) => void) : void {
      if(_selectedAll){
        new Promise( (resolve) => {
          collections.forEach((collection: DocumentCollectionResource) => {
            const { id } = collection;
            !_selectedItems.includes(id) ? _selectedItems.push(id) : '';
            _done++;
            _done == collections.length - 1 ? resolve(_selectedItems) : '';
          });
        }).then((results: number[]) => fn(results));
      }else{
        _selectedItems.splice(0, _selectedItems.length);
      }
    };
    const selectSubscription = function(subscriptions: SubscriptionResource[], fn: (value: number[]) => void) : void {
      if(_selectedAll){
        new Promise( (resolve) => {
          subscriptions.forEach((subscription: SubscriptionResource) => {
            const { id } = subscription;
            !_selectedItems.includes(id) ? _selectedItems.push(id) : '';
            _done++;
            _done == subscriptions.length - 1 ? resolve(_selectedItems) : '';
          });
        }).then((results: number[]) => fn(results));
      }else{
        _selectedItems.splice(0, _selectedItems.length);
      }
    };
    const selectProduct = function(products: ProductResource[], fn: (value: number[]) => void) : void {
      if(_selectedAll){
        new Promise( (resolve) => {
          products.forEach((product: ProductResource) => {
            const { id } = product;
            !_selectedItems.includes(id) ? _selectedItems.push(id) : '';
            _done++;
            _done == products.length - 1 ? resolve(_selectedItems) : '';
          });
        }).then((results: number[]) => fn(results));
      }else{
        _selectedItems.splice(0, _selectedItems.length);
      }
    };
    const selectProductGroupAccess = function(productGroupAccess: ProductItemResource[], fn: (value: number[]) => void) : void {
      if(_selectedAll){
        new Promise( (resolve) => {
          productGroupAccess.forEach((product: ProductItemResource) => {
            const { id } = product;
            !_selectedItems.includes(id) ? _selectedItems.push(id) : '';
            _done++;
            _done == productGroupAccess.length - 1 ? resolve(_selectedItems) : '';
          });
        }).then((results: number[]) => fn(results));
      }else{
        _selectedItems.splice(0, _selectedItems.length);
      }
    };
    const selectLibraryService = function(libraryServices: LibraryServiceResource[], fn: (value: number[]) => void) : void {
      if(_selectedAll){
        new Promise( (resolve) => {
          libraryServices.forEach((libraryService: LibraryServiceResource) => {
            const { id } = libraryService;
            !_selectedItems.includes(id) ? _selectedItems.push(id) : '';
            _done++;
            _done == libraryServices.length - 1 ? resolve(_selectedItems) : '';
          });
        }).then((results: number[]) => fn(results));
      }else{
        _selectedItems.splice(0, _selectedItems.length);
      }
    };
    const selectLibrary = function(libraries: LibraryResource[], fn: (value: number[]) => void) : void {
      if(_selectedAll){
        new Promise((resolve) => {
          libraries.forEach(({id}) => {
            !_selectedItems.includes(id) ? _selectedItems.push(id) : '';
            _done++;
            _done == libraries.length - 1 ? resolve(_selectedItems) : '';
          });
        }).then((results: number[]) => fn(results));
      }else{
        _selectedItems.splice(0, _selectedItems.length);
      }
    };
    //#endregion
    return {
      getMessageError: function(message: string) : JSX.Element {
        return (
          <ListItem>
            <Typography variant="body2" sx={{color: 'text.error'}}>{message}</Typography>
          </ListItem>
        )
      },
      isListValid: function(data: TypeDataArray | TypeListArray, text: string) : Result {
        if(!Array.isArray(data)){
          return {
            message: this.getMessageError('Données invalides'),
            success: false
          }
        }else if(data.length === 0){
          return {
            message: this.getMessageError(text),
            success: false
           }
        }
        return { success: true, message: null };
      },
      getList: function ({ id, path, name, sx, accessType, listType, active, appType} : TypeList) : JSX.Element {
        return (
          <ListItem 
            sx={sx}
            key={id}
            component={RouterLink} 
            to={path} 
            target="_blank"
          >
            <ListItemIcon>
              <BoxIcon />
            </ListItemIcon>
          {
            (listType === ListType.Collection || listType === ListType.CustomerCollection) ? (
              <CaijListItemText 
                name={name} 
                active={active} 
                allowedAccessType={true}
                accessType={DocCollectionModel.getAccessTypeByKey(accessType)}
                color="secondary" 
              />
            ) : (
              <CaijListItemText name={name} active={active} color="secondary" appType={appType}/>
            )
          }
          </ListItem>
        )
      },
      getRequiredList: function ({ id, path, name, accessType , allowedAccessType, sx, active, parameters } : TypeList) : JSX.Element {
        return (
          <ListItem 
            sx={sx} 
            key={id}
            component={RouterLink} 
            to={path} 
            target="_blank"
          >
            <ListItemIcon>
              <BoxIcon />
            </ListItemIcon>
              <CaijListItemText 
                name={name} 
                active={active}
                allowedAccessType={allowedAccessType}
                accessType={ProductModel.getAccessTypeByKey(accessType)}  
                color="secondary" 
              />
              { parameters && (
                  <Label color="success">
                    {parameters}
                  </Label>
                ) 
              } 
          </ListItem>
        )
      },
      renderExclusList: function(exclusList: JSX.Element | JSX.Element[], size: Size) : JSX.Element {
        const { md, xs, lg } = size;
        return (
          <Grid item md={md} xs={xs} lg={lg}>
            <MyCard>
              <CardHeaderListInfo title={EXCLUS_CARD_HEADER} />
              <Divider />
                <Box display="flex" flexDirection="column">
                  <List component="nav" aria-label="secondary">
                    { exclusList }
                  </List>
                </Box>
            </MyCard>
          </Grid>
         )
      },
      renderInclusList: function(inclusList: JSX.Element | JSX.Element[], size: Size) : JSX.Element {
        const { md, xs, lg } = size;
        return (
          <Grid item md={md} xs={xs} lg={lg}>
            <MyCard>
              <CardHeaderListInfo title={INCLUS_CARD_HEADER} />
              <Divider />
                <Box display="flex" flexDirection="column">
                  <List component="nav" aria-label="secondary">
                    { inclusList }
                  </List>
                </Box>
            </MyCard>
          </Grid>
        );
      },
      renderBothList: function(exclusList: JSX.Element | JSX.Element[], inclusList: JSX.Element | JSX.Element[]) : JSX.Element {
        const size: Size = { md: 6,xs: 12,lg: 6 };
        return (
          <>
          {
            this.renderExclusList(exclusList, size)
          }
          {
            this.renderInclusList(inclusList, size)
          }
          </>
        )
      },
      renderCheckListTitle: function(listType: ListType): string {
        switch(listType){
          case ListType.CollectionGroupAccess:
          case ListType.Collection:
            return CARD_HEADER_TITLE_COLLECTION; 
          case ListType.DatabankGroupAcccess:
          case ListType.Databank:
            return CARD_HEADER_TITLE_REMOTE_ACCESS_DATABANK;
          case ListType.DatabankLibrary:
            return CARD_HEADER_TITLE_DATABANK;
          case ListType.Subscription:
          case ListType.CollectionSubscription:
            return CARD_HEADER_TITLE_SUBSCRIPTION2;
        }
      },
      renderRequiredParameterTitle: function (listType: ListType): string {
        switch(listType){
          case ListType.ProductGroupAccess:
          case ListType.Product:
            return CARD_HEADER_TITLE_PRODUCT_SERVICE; 
          case ListType.LibraryService: 
            return CARD_HEADER_TITLE_PRODUCT_SERVICE;
          case ListType.Subscription:
            return CARD_HEADER_TITLE_SUBSCRIPTION;
          case ListType.LibraryServiceLibrary:
            return CARD_HEADER_TITLE_LIBRARY
        }
      },
      compareSelectedLists: function(params: SelectedAllParams) : boolean {
        const { listType, selectedItems, objData } = params;
        switch(listType){
          case ListType.Databank:
            return !(selectedItems.length === objData?.databanks?.length);
          case ListType.DatabankGroupAcccess:
            return !(selectedItems.length === objData?.databankGroupAccess?.length);
          case ListType.DatabankLibrary:
            return !(selectedItems.length === objData?.databankLibrary?.length);
          case ListType.Collection:
            return !(selectedItems.length === objData?.collections?.length);
          case ListType.CollectionGroupAccess:
            return !(selectedItems.length === objData?.collectionGroupAccess?.length);
          case ListType.CollectionSubscription:
          case ListType.Subscription: 
            return !(selectedItems.length === objData?.subscriptions?.length);
          case ListType.Product:
            return !(selectedItems.length === objData?.products?.length);
          case ListType.ProductGroupAccess:
            return !(selectedItems.length === objData?.productGroupAccess?.length);
          case ListType.LibraryService:
            return !(selectedItems.length === objData?.libraryServices?.length);
          case ListType.Library:
          case ListType.LibraryServiceLibrary:
            return !(selectedItems?.length === objData?.libraries?.length);
        }
      },
      selectAll: function(params: SelectedAllParams, fn: (value: number[] | LibraryItemResource[]) => void) : void {
        const { listType, selectedAll, selectedItems, objData } = params;
        _selectedAll = selectedAll;
        _selectedItems = selectedItems;
        switch(listType){
          case ListType.Databank: 
            selectDatabank(objData.databanks, fn);
            break;
          case ListType.DatabankGroupAcccess:
            selectDatabankGroupAccess(objData.databankGroupAccess, fn);
            break;
          case ListType.DatabankLibrary:
            selectDatabank(objData.databankLibrary, fn);
            break;
          case ListType.Collection:
            selectDocCollection(objData.collections, fn);
            break;
          case ListType.CollectionGroupAccess:
            selectDocCollection(objData.collectionGroupAccess, fn);
            break;
          case ListType.CollectionSubscription:
          case ListType.Subscription:
            selectSubscription(objData.subscriptions, fn);
            break;
          case ListType.Product:
            selectProduct(objData.products, fn);
            break;
          case ListType.ProductGroupAccess:
            selectProductGroupAccess(objData.productGroupAccess, fn);
            break;
          case ListType.LibraryService:
            selectLibraryService(objData.libraryServices, fn);
            break;
          case ListType.LibraryServiceLibrary:
          case ListType.Library:
            selectLibrary(objData.libraries, fn);
            break;
        }
      }
    }
})();
//#endregion

export const getEmptyListSearch = (numCols: number, classes?: ClassNameMap<'removeBottomLineTable'>): JSX.Element => (
  <>
    <TableRow className={classes ? classes.removeBottomLineTable : ''}>
      <TableCell colSpan={numCols} align='center'>
          <Typography sx={{color:'text.error'}} variant="body2">Aucune donnée trouvée</Typography>
      </TableCell>
    </TableRow>
  </>
);

export const setEditHash = (text: string, navigate: NavigateFunction) => {
  const hash = location.hash;
  if(text){
    let value = `#${text}`;
    if(hash){
      value += `-${hash.substring(1, hash.length)}`;
    }
    navigate(value, { replace: true });
  }
}

export const setHash = (text: string, navigate: NavigateFunction, subscriptionPath?: string) => {
  if(text){
    if(subscriptionPath){
      navigate(`${subscriptionPath}#${text}`, { replace: true });
    }else{
      navigate(`#${text}`, { replace: true });
    }
  }
}

export const renderLibraryTypeMenuItem = (isAuthorize: boolean, libraryTypes: LibraryTypeResource[]): JSX.Element[] => {
  if(isAuthorize && Array.isArray(libraryTypes)) {
    return libraryTypes.map((libraryType: LibraryTypeResource) => (
      <option value={libraryType.id} key={libraryType.id}>
        {libraryType.nameFr}
      </option>
    ));
  }
}

export const getMessageError = (msg: string, withCollapse?: boolean, open?: boolean): JSX.Element => {
  if(withCollapse) {
    return (
      <Collapse in={open} timeout="auto" unmountOnExit>
        <ListItem>
          <Typography variant="body2" sx={{color:"text.error"}}>{ msg }</Typography>
        </ListItem>
      </Collapse>
    )
  } else {
    return (
      <ListItem>
        <Typography variant="body2" sx={{color:"text.error"}}>{ msg }</Typography>
      </ListItem>
    )
  }
}

export const useToggle = (initialVal = false): [boolean, () => void] => {
   const [toggle, setState] = useState(initialVal);
   const setToggle = () => {
     setState(!toggle);
   };
   return [toggle, setToggle];
}

export const printErrorMessageEmail = (customer: CustomerResource, emailError: string, emailTouched: boolean): JSX.Element => {
  if(emailError && emailTouched){
      if(emailError.indexOf('existe déjà') !== -1) {
        return (
          <span style={{ marginLeft: '0.5em', fontSize: '1em', color: 'text.error' }}>
               Cette adresse courriel est déjà utlisée par{' '}
              <a href={CustomerModel.getInstance().Path.getDetail(customer.id)} style={{fontSize: '0.88em', textDecoration:'none', color: '#F44336', fontStyle:'italic'}} target='blank'>
                {`${customer.firstname} ${customer.lastname}`}
              </a>
          </span>
        )
      } else{
        return (
          <span style={{ marginLeft: '0.5em', fontSize: '1em', color: 'text.error' }}>
            {emailError}
          </span>
        )
      }
   }
};

export const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});















