import React from 'react';
import type { FC, ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import { TextField, InputAdornment, SvgIcon } from '@mui/material';
import { Home as HomeIcon } from 'react-feather';
import { InputProps2, Margin, Size, Variant } from 'src/types/input';

interface CaijInputAddressProps {
  value?: string;
  label?: string;
  name?: string
  title?: string;
  error?: any;
  sx?: object;
  size?: Size;
  variant?: Variant;
  margin?: Margin;
  disabled?: boolean;
  helperText?: any;
  InputLabelProps?: any;
  inputProps?: InputProps2;
  required?: boolean;
  onHandleBlur?: (e: any) => void;
  onHandleChange?: (event: ChangeEvent<any>, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => void;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
}

const CaijInputAddress: FC<CaijInputAddressProps> = ({
  value,
  variant,
  onHandleBlur,
  onHandleChange,
  setFieldValue,
  ...rest
} : CaijInputAddressProps) => (
  <>
    <TextField
      {...rest}
      type="address"
      autoComplete="none"
      fullWidth
      onBlur={onHandleBlur}
      onChange={(event) => onHandleChange(event, setFieldValue)}
      value={value || ''}
      variant={variant}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SvgIcon
              fontSize="small"
              color="action"
            >
              <HomeIcon />
            </SvgIcon>
          </InputAdornment>
        )
      }}
    />
  </>
);

CaijInputAddress.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  title:  PropTypes.string,
  size: PropTypes.oneOf(['medium', 'small']),
  variant: PropTypes.oneOf(['outlined', 'filled']),
  margin: PropTypes.oneOf(['normal', 'dense', 'none']),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  sx: PropTypes.object,
  error: PropTypes.any,
  helperText: PropTypes.any,
  InputLabelProps: PropTypes.any,
  inputProps: PropTypes.object,
  onHandleBlur: PropTypes.func,
  onHandleChange: PropTypes.func,
  setFieldValue: PropTypes.func
};

CaijInputAddress.defaultProps = {
  value: '',
  label: '',
  required: false,
  disabled: false,
  variant: "outlined",
  margin: "normal",
  size: "medium",
  helperText: ' ',
  sx:{ marginBottom: 0}
}

export default CaijInputAddress;
