import { ContentPublishersResource } from 'src/common/types';
import * as actionTypes from '../../actions/ActionTypes';

type FetchContentPublishersAction = {
    type: 'FETCH_CONTENT_PUBLISHERS';
    payload: {
      contentPublishers: ContentPublishersResource[];
      isLoading?: boolean;
      isEmptyList?: boolean;
      isAuthorize?: boolean;
    }
};

type FetchContentPublisherAction = {
    type: 'FETCH_CONTENT_PUBLISHER';
    payload: {
      contentPublisher: ContentPublishersResource;
      isLoading?: boolean;
    }
};

type FetchDetailsContentPublisherAction = {
  type: 'FETCH_DETAILS_CONTENT_PUBLISHERS';
  payload: {
    contentPublisher: ContentPublishersResource
  }
};

type deleteContentPublisherAction = {
  type: 'DELETE_CONTENT_PUBLISHERS';
  payload: {
    code: string
  }
};

type Action = FetchContentPublishersAction |
              FetchContentPublisherAction |
              FetchDetailsContentPublisherAction |
              deleteContentPublisherAction;

interface ContentPublishersReturnState {
  contentPublishers?: ContentPublishersResource[];
  contentPublisher?: ContentPublishersResource;
  isLoading?: boolean;
  isEmptyList?: boolean;
  isAuthorize?: boolean;
}

interface ContentPublishersState {
  contentPublishers: ContentPublishersResource[];
  contentPublisher: ContentPublishersResource;
  isLoading: boolean;
  isEmptyList: boolean;
  isAuthorize: boolean;
}

const reducer = (state: ContentPublishersState, action: Action): ContentPublishersReturnState => {
  switch (action.type) {
    case actionTypes.FETCH_CONTENT_PUBLISHERS: {
      const { contentPublishers, isEmptyList, isLoading, isAuthorize } = action.payload;
      return {
        ...state,
        contentPublishers,
        isLoading,
        isEmptyList,
        isAuthorize
      };
    }
    case actionTypes.FETCH_CONTENT_PUBLISHER: {
      const { contentPublisher, isLoading } = action.payload;
      return {
        ...state,
        contentPublisher,
        isLoading
      };
    }
    case actionTypes.FETCH_DETAILS_CONTENT_PUBLISHERS: {
      const { contentPublisher } = action.payload;
      return {
        ...state,
        contentPublisher
      };
    }
    case actionTypes.DELETE_CONTENT_PUBLISHERS: {
      return {
        ...state,
        contentPublishers: [...state.contentPublishers].filter(({code}) => code !== action.payload.code)
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
