import React, { useState } from 'react';
import type { FC } from 'react';
import {
  Box,
  Button,
  TableBody,
  TableCell,
  TableRow,
  TableHead
} from '@mui/material';
import {
  EmptyList,
  labelConfig,
  ITEMS_PER_PAGE,
  PaginationModel,
  SymphonyModel,
  AccessModel,
  getOpacity,
} from 'src/common';
import type {
  Submitting,
  WfLibraryDto,
  WfProfileDto,
  WfTypeDto,
} from 'src/common/types';
import MyCard from 'src/components/card/MyCard';
import CaijTable from 'src/components/table/CaijTable';
import LoadingScreenCard from 'src/components/loading/LoadingScreenCard';
import CaijTablePagination from 'src/components/pagination/CaijTablePagination';
import { CaijTableCell, CaijTableCellActive } from 'src/components/table/CaijTableCell';

interface SymphonyListProps {
  model: SymphonyModel;
  lists: WfLibraryDto[] | WfProfileDto[];
  isLoading: boolean;
  isEmptyList: boolean;
  onActivate: (value: WfTypeDto) => void;
  onDeActivate: (value: string) => void;
  submitting: Submitting;
}

const SymphonyList: FC<SymphonyListProps> = ({
  model,
  lists,
  isLoading,
  isEmptyList,
  onActivate,
  onDeActivate,
  submitting,
}) => {
  const access = new AccessModel(model.ResourceCode);
  const disabledAccess = (access.UserUser && !access.Gestion);
  const [page, setPage] = useState<number>(0);
  const pagination = new PaginationModel();
  
  const renderTableCell = (row: WfTypeDto, isSubmitting: boolean) => (
    <>
      <CaijTableCell opacity={getOpacity(row.activated)} cellWidth="45%">{row.name}</CaijTableCell>
      <CaijTableCellActive cellWidth="45%" active={row.activated} />
      <CaijTableCell cellWidth="10%">
        {
          row.activated ? (
            <Box display="flex" flexDirection="column">
              <Button variant='outlined' disabled={isSubmitting || disabledAccess} onClick={() => onDeActivate(row.name)}>Désactiver</Button>
            </Box>
          ) 
          : (
            <Box display="flex" flexDirection="column">
              <Button sx={{ width: '8.5em' }} variant='contained' color='primary' disabled={isSubmitting || disabledAccess} onClick={() => onActivate(row)}>Activer</Button>
            </Box>
          )
        }
      </CaijTableCell>
    </>
  )

  const renderContent = (): JSX.Element[] | JSX.Element => {
    if (isLoading && isEmptyList) {
      return <EmptyList />
    }else if (isLoading && !isEmptyList && lists) {
      return pagination.applyPagination(lists, page, ITEMS_PER_PAGE)
            .map((row: WfTypeDto, index: number) => {
              const isSubmitting = submitting.isSubmitting && submitting.name === row.name;
              return (
                <TableRow hover tabIndex={-1} key={row.id}>
                  {renderTableCell(row, isSubmitting)}
                </TableRow>
              )
          });
    }
  };

  return (
    <>
      {
        !isLoading ? <LoadingScreenCard /> : (
          <>
            <MyCard>
              <CaijTable>
                <TableHead>
                  <TableRow>
                    <TableCell>{labelConfig.nameFr}</TableCell>
                    <TableCell>{labelConfig.status}</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  { renderContent() }
                </TableBody>
              </CaijTable>
              <CaijTablePagination
                showTablePaginationLight 
                len={lists.length}
                page={page}
                setPage={(l) => setPage(l)}
              />
            </MyCard>
          </>
        )
      }
    </>
  );
};

export default SymphonyList;
