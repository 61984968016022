import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@mui/material';
import type { LibraryResource } from 'src/common/types';
import { labelConfig, LibraryModel, tableRow } from 'src/common';
import { tableCell2 } from 'src/styles/library';
import MyCard from 'src/components/card/MyCard';
import CardHeaderDetail from 'src/components/card/CardHeaderDetail';

export interface AddressFrInfoProps {
  model: LibraryModel;
  library: LibraryResource;
}

const AddressFrInfo: FC<AddressFrInfoProps> = ({
  model,
  library
}) => {
  const { addressFr } = library;
  const { AddressLine1, AddressLine2, AddressCity, AddressState, AddressZip, AddressCountry } = model;
  return (
    <MyCard>
      <CardHeaderDetail title="Adresse" />
      <Divider />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell sx={tableCell2}>
              { AddressLine1.Label }
            </TableCell>
            <TableCell>
              <Typography
                variant="body2"
                sx={{color:"text.secondary"}}
              >
                { addressFr.line1}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={tableCell2}>
              { AddressLine2.Label }
            </TableCell>
            <TableCell>
              <Typography
                variant="body2"
                sx={{color:"text.secondary"}}
              >
                { addressFr.line2}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={tableCell2}>
              { AddressCity.Label }
            </TableCell>
            <TableCell>
              <Typography
                variant="body2"
                sx={{color:"text.secondary"}}
              >
                {addressFr.city}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={tableCell2}>
              { AddressState.Label }
            </TableCell>
            <TableCell>
              <Typography
                variant="body2"
                sx={{color:"text.secondary"}}
              >
                { addressFr.state }
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={tableCell2}>
              { AddressZip.Label }
            </TableCell>
            <TableCell>
              <Typography
                variant="body2"
                sx={{color:"text.secondary"}}
              >
                { addressFr.zip }
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow sx={tableRow}>
            <TableCell sx={tableCell2}>
              { AddressCountry.Label }
            </TableCell>
            <TableCell>
              <Typography
                variant="body2"
                sx={{color:"text.secondary"}}
              >
                {addressFr.country}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </MyCard>
  );
};

AddressFrInfo.propTypes = {
  library: PropTypes.object.isRequired
};

export default AddressFrInfo;
