import React from 'react';
import type { FC, ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import type { ContentPublishersResource,} from 'src/common/types';
import { Box, Grid } from '@mui/material';
import { 
  labelConfig, 
  CaijInput, 
  FormikErrors, 
  FormikTouched, 
  CaijImage,
  CaijInputLink, 
  ContentPublishersModel 
} from 'src/common';
import UploadImage from 'src/components/image/UploadImage';

interface ContentPublisherFormProps {
  model: ContentPublishersModel;
  contentPublisher: ContentPublishersResource;
  isCreate: boolean;
  errors: FormikErrors<ContentPublishersResource>;
  touched: FormikTouched<ContentPublishersResource>;
  onHandleBlur: (e: ChangeEvent<HTMLInputElement>) => void;
  onHandleChange: (event: ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => void;
  onHandleChangeImageUrl:(name: string,imageUrl: string, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const ContentPublisherForm: FC<ContentPublisherFormProps> = ({
  model,
  contentPublisher,
  isCreate,
  errors,
  touched,
  onHandleBlur,
  onHandleChange,
  onHandleChangeImageUrl,
  setFieldValue,
}) => {
  const { nameFr, nameEn, code, logoUrlFr, homeUrlFr, contentUrlFr, homeUrlEn, contentUrlEn, logoUrlEn } = contentPublisher;
  const { Code, HomeUrlFr, HomeUrlEn, NameFr, NameEn, ContentUrlFr, ContentUrlEn, LogoUrlFr, LogoUrlEn } = model;
  return (
    <>
      <Grid item md={12} xs={12} >
        <CaijInput
            disabled={!isCreate}
            required
            name={Code.Name}
            id={Code.Name}
            value={code}
            helperText={touched.code && errors.code}
            error={Boolean(touched.code && errors.code)}
            label={Code.Label}
            InputLabelProps={{ shrink: true, required: true }}
            inputAttr={{maxLength: Code.MaxLength, 'data-testid': 'code' }}
            onHandleBlur={onHandleBlur}
            onHandleChange={onHandleChange}
            setFieldValue={setFieldValue}
        />
    </Grid>
    <Grid item md={6} xs={12}>
        <Box mb={2}>
          <CaijInput
              required
              name={NameFr.Name}
              id={NameFr.Name}
              margin="none"
              value={nameFr}
              helperText={touched.nameFr && errors.nameFr}
              error={Boolean(touched.nameFr && errors.nameFr)}
              label={NameFr.Label}
              InputLabelProps={{ shrink: true, required: true }}
              inputAttr={{ maxLength: NameFr.MaxLength, 'data-testid': 'nameFr'}}
              onHandleBlur={onHandleBlur}
              onHandleChange={onHandleChange}
              setFieldValue={setFieldValue}
          />
        </Box>
        { logoUrlFr && (
            <Box mb={2}>
              <CaijImage id='logoUrlFr' src={logoUrlFr} alt={logoUrlFr} />
            </Box>
          )
        }
        <Box mb={3}>
          <UploadImage
            imgLabel={LogoUrlFr.Label}
            maxLength={LogoUrlFr.MaxLength}
            resourceCode={model.ResourceCode} 
            btnText={logoUrlFr ? labelConfig.logoEditBtnFr : labelConfig.logoBtnFr}
            imgName={LogoUrlFr.Name}
            onHandleChangeImageUrl={onHandleChangeImageUrl}
            setFieldValue={setFieldValue}
          />
        </Box>
        <CaijInputLink
            name={HomeUrlFr.Name}
            id={HomeUrlFr.Name}
            value={homeUrlFr}
            label={HomeUrlFr.Label}
            helperText={touched.homeUrlFr && errors.homeUrlFr}
            error={Boolean(touched.homeUrlFr && errors.homeUrlFr)}
            InputLabelProps={{ shrink: true }}
            inputProps={{ maxLength: HomeUrlFr.MaxLength, 'data-testid': 'homeUrlFr' }}
            onHandleBlur={onHandleBlur}
            onHandleChange={onHandleChange}
            setFieldValue={setFieldValue}
        />
        <CaijInputLink
            name={ContentUrlFr.Name}
            id={ContentUrlFr.Name}
            value={contentUrlFr}
            label={ContentUrlFr.Label}
            helperText={touched.contentUrlFr && errors.contentUrlFr}
            error={Boolean(touched.contentUrlFr && errors.contentUrlFr)}
            InputLabelProps={{ shrink: true }}
            inputProps={{ maxLength: ContentUrlFr.MaxLength, 'data-testid': 'contentUrlFr' }}
            onHandleBlur={onHandleBlur}
            onHandleChange={onHandleChange}
            setFieldValue={setFieldValue}
        />
    </Grid>
    <Grid item md={6} xs={12}>
        <Box mb={2}>
          <CaijInput
              name={NameEn.Name}
              id={NameEn.Name}
              margin="none"
              required
              value={nameEn}
              label={NameEn.Label}
              helperText={touched.nameEn && errors.nameEn}
              error={Boolean(touched.nameEn && errors.nameEn)}
              InputLabelProps={{ shrink: true, required: true }}
              inputAttr={{ maxLength: NameEn.MaxLength, 'data-testid': 'nameEn' }}
              onHandleBlur={onHandleBlur}
              onHandleChange={onHandleChange}
              setFieldValue={setFieldValue}
          />
        </Box>
        { logoUrlEn && (
            <Box mb={2}>
              <CaijImage id='logoUrlEn' src={logoUrlEn} alt={logoUrlEn} />
            </Box>
          )
        }
        <Box mb={3}>
          <UploadImage
            imgLabel={LogoUrlEn.Label}
            maxLength={LogoUrlFr.MaxLength}
            resourceCode={model.ResourceCode} 
            btnText={logoUrlEn ? labelConfig.logoEditBtnEn : labelConfig.logoBtnEn}
            imgName={LogoUrlEn.Name}
            onHandleChangeImageUrl={onHandleChangeImageUrl}
            setFieldValue={setFieldValue}
          />
        </Box>
        <CaijInputLink
            name={HomeUrlEn.Name}
            id={HomeUrlEn.Name}
            value={homeUrlEn}
            label={HomeUrlEn.Label}
            helperText={touched.homeUrlEn && errors.homeUrlEn}
            error={Boolean(touched.homeUrlEn && errors.homeUrlEn)}
            InputLabelProps={{ shrink: true }}
            inputProps={{ maxLength: HomeUrlEn.MaxLength, 'data-testid': 'homeUrlEn' }}
            onHandleBlur={onHandleBlur}
            onHandleChange={onHandleChange}
            setFieldValue={setFieldValue}
        />
        <CaijInputLink
            name={ContentUrlEn.Name}
            id={ContentUrlEn.Name}
            value={contentUrlEn}
            label={ContentUrlEn.Label}
            helperText={touched.contentUrlEn && errors.contentUrlEn}
            error={Boolean(touched.contentUrlEn && errors.contentUrlEn)}
            InputLabelProps={{ shrink: true }}
            inputProps={{ maxLength: ContentUrlEn.MaxLength, 'data-testid': 'contentUrlEn' }}
            onHandleBlur={onHandleBlur}
            onHandleChange={onHandleChange}
            setFieldValue={setFieldValue}
        />
    </Grid>
    </>
  );
};

ContentPublisherForm.propTypes = {
  contentPublisher: PropTypes.object.isRequired,
  isCreate: PropTypes.bool,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  onHandleBlur: PropTypes.func.isRequired,
  onHandleChangeImageUrl: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  onHandleChange: PropTypes.func.isRequired
};

export default ContentPublisherForm;
