export const FETCH_ADDRESSES = 'FETCH_ADDRESSES';
export const FETCH_ACCESSES = 'FETCH_ACCESSES';
export const FETCH_ACCESS = 'FETCH_ACCESS';
export const FETCH_DETAILS_ACCESS = 'FETCH_DETAILS_ACCESS';
export const FETCH_CONTENT_MOST_VIEWEDS = 'FETCH_CONTENT_MOST_VIEWEDS';
export const FETCH_CONTENT_MOST_VIEWED = 'FETCH_CONTENT_MOST_VIEWED';
export const DELETE_CONTENT_MOST_VIEWED = 'DELETE_CONTENT_MOST_VIEWED';
export const FETCH_DETAILS_CONTENT_MOST_VIEWED = 'FETCH_DETAILS_CONTENT_MOST_VIEWED';
export const FETCH_CONTENT_PUBLISHERS = 'FETCH_CONTENT_PUBLISHERS';
export const FETCH_CONTENT_PUBLISHER = 'FETCH_CONTENT_PUBLISHER';
export const FETCH_DETAILS_CONTENT_PUBLISHERS = 'FETCH_DETAILS_CONTENT_PUBLISHERS';
export const DELETE_CONTENT_PUBLISHERS = 'DELETE_CONTENT_PUBLISHERS';
export const FETCH_CONTENT_PUBLICATIONS = 'FETCH_CONTENT_PUBLICATIONS';
export const FETCH_CONTENT_PUBLICATION = 'FETCH_CONTENT_PUBLICATION';
export const FETCH_DETAILS_CONTENT_PUBLICATIONS = 'FETCH_DETAILS_CONTENT_PUBLICATIONS';
export const DELETE_CONTENT_PUBLICATION = 'DELETE_CONTENT_PUBLICATION';
export const FETCH_DOCUMENT_TYPES = 'FETCH_DOCUMENT_TYPES';
export const FETCH_DOCUMENT_TYPE = 'FETCH_DOCUMENT_TYPE';
export const DELETE_DOCUMENT_TYPE = 'DELETE_DOCUMENT_TYPE';
export const FETCH_DETAILS_DOCUMENT_TYPE = 'FETCH_DETAILS_DOCUMENT_TYPE';
export const FETCH_CUSTOMERS = 'FETCH_CUSTOMERS';
export const FETCH_CUSTOMER = 'FETCH_CUSTOMER';
export const FETCH_CUSTOMER_ADDRESSES = 'FETCH_CUSTOMER_ADDRESSES';
export const FETCH_CUSTOMERS_ENDORSED = 'FETCH_CUSTOMERS_ENDORSED';
export const FETCH_CUSTOMER_ENDORSED = 'FETCH_CUSTOMER_ENDORSED';
export const FETCH_CUSTOMERS_BY_EMAIL = 'FETCH_CUSTOMERS_BY_EMAIL';
export const FETCH_CUSTOMER_HISTORY_LIST = 'FETCH_CUSTOMER_HISTORY_LIST';
export const FETCH_CUSTOMER_CARD_TRANSACTIONS = 'FETCH_CUSTOMER_CARD_TRANSACTIONS';
export const FETCH_CUSTOMER_LIBRARY_TRANSACTIONS = 'FETCH_CUSTOMER_LIBRARY_TRANSACTIONS';
export const FETCH_CUSTOMER_REPERAGES = 'FETCH_CUSTOMER_REPERAGES';
export const FETCH_CARD_REQUEST_PENDING = 'FETCH_CARD_REQUEST_PENDING';
export const FETCH_DETAILS_LIBRARY_SERVICE = 'FETCH_DETAILS_LIBRARY_SERVICE';
export const FETCH_DETAILS_LIBRARY_TYPE = 'FETCH_DETAILS_LIBRARY_TYPE';
export const FETCH_DATABANKS = 'FETCH_DATABANKS';
export const FETCH_DATABANK = 'FETCH_DATABANK';
export const FETCH_DETAILS_DATABANK = 'FETCH_DETAILS_DATABANK';
export const DELETE_DATABANK = 'DELETE_DATABANK';
export const FETCH_DOCUMENT_COLLECTIONS = 'FETCH_DOCUMENT_COLLECTIONS';
export const FETCH_DOCUMENT_COLLECTION = 'FETCH_DOCUMENT_COLLECTION';
export const DELETE_DOCUMENT_COLLECTION = 'DELETE_DOCUMENT_COLLECTION';
export const FETCH_EMPLOYEES = 'FETCH_EMPLOYEES';
export const FETCH_EMPLOYEE = 'FETCH_EMPLOYEE';
export const FETCH_DETAILS_EMPLOYEE = 'FETCH_DETAILS_EMPLOYEE';
export const DELETE_EMPLOYEE = 'DELETE_EMPLOYEE';
export const FETCH_LAW_PRATICE_FIELDS = 'FETCH_LAW_PRATICE_FIELDS';
export const FETCH_LAW_PRATICE_FIELD = 'FETCH_LAW_PRATICE_FIELD';
export const FETCH_DETAILS_LAW_PRATICE_FIELD = 'FETCH_DETAILS_LAW_PRATICE_FIELD';
export const DELETE_LAW_PRATICE_FIELD = 'DELETE_LAW_PRATICE_FIELD';
export const FETCH_LIBRARIES = 'FETCH_LIBRARIES';
export const FETCH_LIBRARY = 'FETCH_LIBRARY';
export const DELETE_LIBRARY = 'DELETE_LIBRARY';
export const FETCH_DETAILS_LIBRARY = 'FETCH_DETAILS_LIBRARY';
export const FETCH_LIBRARY_SERVICES = 'FETCH_LIBRARY_SERVICES';
export const FETCH_LIBRARY_SERVICE = 'FETCH_LIBRARY_SERVICE';
export const DELETE_LIBRARY_SERVICE = 'DELETE_LIBRARY_SERVICE';
export const FETCH_LIBRARY_TYPES = 'FETCH_LIBRARY_TYPES';
export const FETCH_LIBRARY_TYPE = 'FETCH_LIBRARY_TYPE';
export const DELETE_LIBRARY_TYPE = 'DELETE_LIBRARY_TYPE';
export const FETCH_LIBRARIES_SYMPHONY = 'FETCH_LIBRARIES_SYMPHONY';
export const FETCH_PROFILES_SYMPHONY = 'FETCH_PROFILES_SYMPHONY';
export const FETCH_RESOURCES = 'FETCH_RESOURCES';
export const FETCH_RESOURCE = 'FETCH_RESOURCE';
export const FETCH_DETAILS_RESOURCE = 'FETCH_DETAILS_RESOURCE';
export const FETCH_RESOURCE_GROUPS = 'FETCH_RESOURCE_GROUPS';
export const FETCH_RESOURCE_GROUP = 'FETCH_RESOURCE_GROUP';
export const FETCH_DETAILS_RESOURCE_GROUP = 'FETCH_DETAILS_RESOURCE_GROUP';
export const FETCH_SUBSCRIPTIONS = 'FETCH_SUBSCRIPTIONS';
export const FETCH_SUBSCRIPTION = 'FETCH_SUBSCRIPTION';
export const FETCH_DETAILS_SUBSCRIPTION = 'FETCH_DETAILS_SUBSCRIPTION';
export const FETCH_MESSAGE_PLACEHOLDERS = 'FETCH_MESSAGE_PLACEHOLDERS';
export const FETCH_MESSAGE_PLACEHOLDER = 'FETCH_MESSAGE_PLACEHOLDER';
export const FETCH_DETAILS_MESSAGE_PLACEHOLDER = 'FETCH_DETAILS_MESSAGE_PLACEHOLDER';
export const FETCH_MESSAGES = 'FETCH_MESSAGES';
export const FETCH_MESSAGE = 'FETCH_MESSAGE';
export const FETCH_DETAILS_MESSAGE = 'FETCH_DETAILS_MESSAGE';
export const FETCH_PRODUCTS = 'FETCH_PRODUCTS';
export const FETCH_PRODUCT = 'FETCH_PRODUCT';

export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const FETCH_REPERAGES = 'FETCH_REPERAGES';
export const FETCH_REPERAGE = 'FETCH_REPERAGE';
export const LOADING_REPERAGE = 'LOADING_REPERAGE';
export const FETCH_TRANSLATIONS = 'FETCH_TRANSLATIONS';
export const FETCH_TRANSLATION = 'FETCH_TRANSLATION';
export const FETCH_TRIBUNAUX = 'FETCH_TRIBUNAUX';
export const FETCH_TRIBUNAL = 'FETCH_TRIBUNAL';
export const DELETE_TRIBUNAL = 'DELETE_TRIBUNAL';
export const FETCH_TRIBUNAL_CODES = 'FETCH_TRIBUNAL_CODES';
export const FETCH_TRIBUNAL_TYPE = 'FETCH_TRIBUNAL_TYPE';
export const FETCH_TRIBUNAL_TYPES = 'FETCH_TRIBUNAL_TYPES';
export const DELETE_TRIBUNAL_TYPE = 'DELETE_TRIBUNAL_TYPE';
export const FETCH_TRIBUNAL_CODES_NOT_ASSIGNED = 'FETCH_TRIBUNAL_CODES_NOT_ASSIGNED';
export const FETCH_PROXYS= 'FETCH_PROXYS';
export const FETCH_GROUP_LIST = 'FETCH_GROUP_LIST';
export const FETCH_GROUP= 'FETCH_GROUP';
export const DELETE_GROUP= 'DELETE_GROUP';
export const ADD_GROUP= 'ADD_GROUP';
export const UPDATE_GROUP= 'UPDATE_GROUP';
export const FETCH_RESET_PASSWORD = 'FETCH_RESET_PASSWORD';
export const FETCH_CUSTOMER_CARD_TRANSACTION_BY_ID = 'FETCH_CUSTOMER_CARD_TRANSACTION_BY_ID';
export const FETCH_CUSTOMER_INVOICES_BY_ID = 'FETCH_CUSTOMER_INVOICES_BY_ID';
export const FETCH_EMAIL_TEMPLATES = 'FETCH_EMAIL_TEMPLATES';
export const FETCH_EMAIL_TEMPLATE = 'FETCH_EMAIL_TEMPLATE';
export const FETCH_EMAIL_STATUS = 'FETCH_EMAIL_STATUS';
export const DELETE_EMAIL_TEMPLATE = 'DELETE_EMAIL_TEMPLATE';
export const FETCH_TAGS = 'FETCH_TAGS';
export const FETCH_LAWS = 'FETCH_LAWS';
export const FETCH_LAW = 'FETCH_LAW';
export const FETCH_LAW_DOMAIN_FIELDS = 'FETCH_LAW_DOMAIN_FIELDS';
export const FETCH_LAW_DOMAIN_FIELD = 'FETCH_LAW_DOMAIN_FIELD';
export const FETCH_DETAILS_LAW_DOMAIN_FIELD = 'FETCH_DETAILS_LAW_DOMAIN_FIELD';
export const FETCH_DOOR_CARD_PROFILES = 'FETCH_DOOR_CARD_PROFILES';
export const FETCH_API_KEYS = 'FETCH_API_KEYS';
export const FETCH_API_KEY = 'FETCH_API_KEY';
export const DELETE_API_KEY = 'DELETE_API_KEY';
export const FETCH_DOCUMENTS = 'FETCH_DOCUMENTS';
export const FETCH_DOCUMENT = 'FETCH_DOCUMENT';
export const FETCH_TRIBUNALS = 'FETCH_TRIBUNALS';
export const FETCH_CHART = 'FETCH_CHART';
export const FETCH_CUSTOMER_STATS = 'FETCH_CUSTOMER_STATS';
export const FETCH_ACTIVE_CUSTOMER_BY_SUBSCRIPTION = 'FETCH_ACTIVE_CUSTOMER_BY_SUBSCRIPTION';
export const FETCH_JUGES_A_CLASSER = 'FETCH_JUGES_A_CLASSER';
export const FETCH_PENDING_APPROVAL = 'FETCH_PENDING_APPROVAL';
export const FETCH_UXPERTISES = 'FETCH_UXPERTISES';
export const FETCH_HTML_CONTENT = "FETCH_HTML_CONTENT";
export const FETCH_HTML_CONTENT_LOCK = "FETCH_HTML_CONTENT_LOCK";
export const FETCH_SPECIAL_FILES = "FETCH_SPECIAL_FILES"
export const DELETE_SPECIAL_FILE = "DELETE_SPECIAL_FILE";
export const FETCH_COVEO_SOURCES = "FETCH_COVEO_SOURCES";
export const DELETE_COVEO_SOURCE = "DELETE_COVEO_SOURCE";
export const FETCH_COVEO_TASK_HISTORIES = "FETCH_COVEO_TASK_HISTORIES";
export const FETCH_COVEO_LOGS = "FETCH_COVEO_LOGS";