import React, { useEffect, useState } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FORMAT_YEAR_PICKER } from 'src/common';
import { Box } from '@mui/material';
import { CaijLocalizationProvider } from './LocalizationProvider';

interface CaijYearPickerProps {
  name: string;
  value?: number;
  label?: string;
  required?: boolean;
  inputProps?: {'data-testid'?: string, 'aria-label'?:string};
  helperText?: ReactNode;
  onHandleChange: (date: number) => void;
}

const CaijYearPicker: FC<CaijYearPickerProps> = ({
  value,
  label,
  required,
  inputProps,
  helperText,
  onHandleChange,
  ...rest
}) => {
  const [cleared, setCleared] = useState<boolean>(true);
  const [year, setYear] = useState<(Date | null)>(null);

  useEffect(() => {
    const d = new Date();
    if(value){
      d.setFullYear(value);
      setYear(d);
    }
  },[]);

  return (
    <Box display="flex" flexDirection="column">
      <CaijLocalizationProvider>
        <DatePicker
          {...rest}
          label={label}
          value={year}
          format={FORMAT_YEAR_PICKER}
          openTo='year'
          views={["year"]}
          sx={{width: '200px'}}
          slotProps={{
            field: { clearable: cleared, onClear: () => setCleared(false) },
            textField: {
              helperText: helperText,
              inputProps: inputProps,
              required: required,
              InputLabelProps: {shrink: true}
            },
          }}
          onChange={(e) => { 
              if(e) {
                onHandleChange(e.getFullYear() as number);
                setCleared(true);
              }
            }
          }
        />
      </CaijLocalizationProvider>
    </Box>
  );
}

CaijYearPicker.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.number,
  label: PropTypes.string,
  required: PropTypes.bool,
  inputProps: PropTypes.object
};

export default CaijYearPicker;
