import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import type {
  ContentPublishersResource,
  DocumentResource,
  DocumentResourceForEdit
} from 'src/common/types';
import {Box} from '@mui/material';
import {
  Formik,
  handleChange,
  Mode,
  CaijButtonSubmit,
  CaijButtonReset,
  btnSubmit,
  DocumentModel
} from 'src/common';
import printMessage from 'src/views/errors/MessageError';
import Authorize from 'src/components/Authorize';
import { Root } from 'src/components/styled';
import DocumentForm from 'src/components/content/document/Forms';
import validateSchema from '../../Schema';

export interface DetailsEditFormProps {
  model: DocumentModel;
  document: DocumentResource;
  onHandleRefreshDocument: () => Promise<void>;
  onSwitchMode: (isCancel: boolean) => void;
  onSubmit?: (value: DocumentResource) => void;
}

const DetailsEditForm: FC<DetailsEditFormProps> = ({
  model,
  document,
  onHandleRefreshDocument,
  onSwitchMode,
  onSubmit
}) => {

  const switchMode = (isCancel: boolean) => {
    onSwitchMode(isCancel);
  };

  const handleSubmit = async (values: ContentPublishersResource) => {
    if(onSubmit) {
      onSubmit(values);
      return;
    }
    const model = new DocumentModel();
    let submitData = values as DocumentResourceForEdit;
    if (submitData && submitData.documentId) {
      const response = await model.updateDocument(submitData);
      if (!model.error) {
        printMessage({
          status: response.status,
          message: response.message
        });
        await onHandleRefreshDocument();
      }
    }
  };

  return (
    <Formik
      initialValues={{...document}}
      validationSchema={validateSchema(model)}
      validateOnChange={false}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleSubmit,
        setFieldValue,
        values,
        touched,
        isSubmitting
      }) => (
        <form onSubmit={handleSubmit}>
          <Root>
            <DocumentForm 
              model={model}
              document={values} 
              errors={errors}
              touched={touched}
              onHandleBlur={handleBlur}
              onHandleChange={handleChange}
              setFieldValue={setFieldValue}
            />
          </Root>
          <Box mt={2}>
            <Authorize
              resourceCode={model.ResourceCode}
              mode={Mode.edit}
            >
              <CaijButtonSubmit disabled={isSubmitting} sx={btnSubmit} />
            </Authorize>
            <CaijButtonReset disabled={isSubmitting} onSwitchMode={switchMode} />
          </Box>
        </form>
      )}
    </Formik>
  );
};

DetailsEditForm.propTypes = {
  document: PropTypes.object.isRequired,
  onHandleRefreshDocument: PropTypes.func.isRequired,
  onSwitchMode: PropTypes.func.isRequired,
  onSubmit: PropTypes.func
};

DetailsEditForm.defaultProps = {
  onSubmit: null
};

export default DetailsEditForm;
