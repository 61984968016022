import { DocumentModel } from 'src/common';
import * as Yup from 'yup';

const validateSchema = (model: DocumentModel) => {
  const { Titre, Collection, DocumentStore, DocumentStoreInfo, Identifier, Juridiction, Filepath, OtherLangDocId, Tribunal, Publisher, DocumentType, Published, Lang} = model;
  return Yup.object().shape({
    title: Titre.required(true),
    collection: Collection.required(true),
    documentStore: DocumentStore.required(true),
    documentStoreInfo:  DocumentStoreInfo.nullable(true),
    filepath: Filepath.nullable(true),
    identifier: Identifier.required(true),
    tribunal: Tribunal.nullable(true),
    juridiction: Juridiction.nullable(true),
    otherLangDocId: OtherLangDocId.nullable(true),
    publisher: Publisher.required(false),
    documentType: DocumentType.required(true),
	  published: Published.required(),
    lang: Lang.required(true)
  });
}
export default validateSchema;