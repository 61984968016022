import PropTypes from 'prop-types';
import { Box, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Divider, SvgIcon, Typography } from "@mui/material";
import { Editor } from "@tinymce/tinymce-react";
import { FC, useRef, useState } from "react";
import { Authorize, CaijButton, CommunauteJuridiqueModel, EmployeeRole, Mode, NoContent, Success, TINY_MCE_SCRIPT_CDN, btnSubmit, initTinyMCE } from "src/common";
import LoadingButton from "@mui/lab/LoadingButton";
import { List, Save, Upload } from "react-feather";
import CaijButtonCopy from "src/components/buttons/CaijButtonCopy";
import { EditableDocumentModel } from "src/model/EditableDocument";
import { DocumentLockResource, DocumentVersionResource, PublishDocumentResource } from "src/common/types";
import printMessage from "src/views/errors/MessageError";
import LoadingScreen from "src/components/loading/LoadingScreen";
import SpecialFilesModel from 'src/model/content/SpecialFiles';
import useAuth from 'src/hooks/useAuth';
import VersionHistoryDialog from './VersionHistory';

export interface HtmlContentEditorProps {
    pageModel: CommunauteJuridiqueModel | SpecialFilesModel;
    documentModel: EditableDocumentModel;
    documentLock?: DocumentLockResource;
    initialContent: string;
    onChange?: (field: string, value: string, shouldValidate?: boolean) => void;
    onPublish?: (docContent: string) => void;
    onSave?: (docContent: string) => void;
    lockDocument?: () => void;
    removeDocumentLock?: () => void;
    docId?: string;
    name?: string;
    label?: string;
    inForm?: boolean;
    redirectPath?: string;
    errors?: string;
}

const HtmlContentEditor: FC<HtmlContentEditorProps> = ({
    pageModel,
    documentModel,
    documentLock,
    initialContent,
    onChange,
    onPublish,
    onSave,
    lockDocument,
    removeDocumentLock,
    docId,
    name,
    label,
    inForm,
    redirectPath,
    errors,
}) => {
    const editorRef = useRef(null);
    const [touched, setTouched] = useState<boolean>(false);
    const [content, setContent] = useState<string>(initialContent);
    const [saving, setSaving] = useState<boolean>(false);
    const [isAuth, setIsAuth] = useState<boolean>();
    const [showVersionHistory, setShowVersionHistory] = useState<boolean>(false);
    const [loadingVersion, setLoadingVersion] = useState<boolean>(false)
    const [selectedVersion, setSelectedVersion] = useState<DocumentVersionResource>(null);
    const [tempSelectedVersion, setTempSelectedVersion] = useState<DocumentVersionResource>(null);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const authContext = useAuth();
    const employee = authContext.user?.employee;
    
    const handleDirty = async () => {
        if (loadingVersion) {
            setLoadingVersion(false);
            editorRef.current.setDirty(false);
            return;
        }
        setTouched(true);
        if (!inForm && !documentLock && lockDocument) {
            lockDocument();
        }
    }

    const handleSave = async (docContent: string) => {
        setSaving(true);
        if (onSave) {
            onSave(docContent);
            return;
        }
        const response = await documentModel.saveNewDocumentVersion(docId, docContent);
        if (response.status === Success) {
            const { status, message } = response;
            editorRef.current.setDirty(false);
            setTouched(false);
            setSaving(false);
            Object.keys(localStorage).forEach(key => {
                if (key.includes("tinymce-autosave-") && key.includes(docId)) {
                    localStorage.removeItem(key);
                }
            });
            printMessage({
                status,
                message
            });
            removeDocumentLock();
            setSelectedVersion(null);
        } else {
            setSaving(false);
            pageModel.resetError();
        }
    }

    const handlePublish = async (docContent: string) => {
        setSaving(true);
        if (onPublish) {
            onPublish(docContent);
            return;
        }
        const submitData: PublishDocumentResource = {docContent, created: selectedVersion?.created};
        const response = await documentModel.publishDocumentContent(docId, submitData);
        if (!pageModel.error) {
            await pageModel.redirect(redirectPath, {
                status: response.status,
                message: response.message
            });
        } else {
            setSaving(false);
            pageModel.resetError();
        }
    }

    const loadSelectedVersion = async (version: DocumentVersionResource) => {
        setLoadingVersion(true);
        const versionContent = await documentModel.getContent(docId, version.filepath);
        if (!pageModel.error) {
            if (versionContent === content) {
                setLoadingVersion(false);
            }
            editorRef.current.setDirty(false);
            setTouched(false);
            setShowVersionHistory(false);
            setShowConfirmDialog(false);
            setSelectedVersion(version);
            setContent(versionContent);
        } else {
            if (pageModel.error.status === NoContent) {
                printMessage({ message: `La version sélectionnée n'est pas disponible.` })
            }
            if (showConfirmDialog) {
                setShowConfirmDialog(false);
                setLoadingVersion(false);
                setShowVersionHistory(true);
            }
            setLoadingVersion(false);
            pageModel.resetError();
        }
    }

    const handleCancelSelectVersion = () => {
        setSelectedVersion(null);
        setShowConfirmDialog(false);
    }

    const handleSelectVersion = (version: DocumentVersionResource) => {
        if (touched) {
            setTempSelectedVersion(version);
            setShowVersionHistory(false);
            setShowConfirmDialog(true);
            return;
        }
        loadSelectedVersion(version);
    }

    const handleUnlockDocument = () => {
        setSaving(true);
        removeDocumentLock();
        setSaving(false);
    }

    const handleChange = (docContent: string) => {
        setContent(docContent);
        if (onChange) {
            onChange(name, docContent);
            return;
        }
    }

    return (
        <Box>
            {label && <Typography mb={2}>{label}</Typography>}
            {!inForm &&
                <>
                    <VersionHistoryDialog
                        open={showVersionHistory}
                        onClose={() => setShowVersionHistory(false)}
                        docId={docId}
                        handleSelectVersion={handleSelectVersion}
                        model={documentModel}
                        selectedVersion={selectedVersion}
                        loadingVersion={loadingVersion}
                    />
                    <Dialog
                        open={showConfirmDialog}
                        onClose={() => handleCancelSelectVersion}
                    >
                        <DialogTitle>Charger une autre version du document</DialogTitle>
                        <Divider />
                        <DialogContent>
                            {loadingVersion ? <LoadingScreen /> :
                                <Box mb={2}>
                                    <Typography variant='body2' sx={{ color: 'text.secondary' }}>
                                        Vous avez fait des modifications que vous n'avez pas sauvegardées. La sélection d’une autre version de ce document annulera vos modifications. Êtes-vous sûr de vouloir charger cette version?
                                    </Typography>
                                </Box>
                            }
                        </DialogContent>
                        <Divider />
                        <DialogActions>
                            <CaijButton
                                type="default"
                                color="info"
                                variant="outlined"
                                onHandleClick={() => loadSelectedVersion(tempSelectedVersion)}
                                disabled={loadingVersion}
                            >Charger</CaijButton>
                            <CaijButton
                                type="cancel"
                                color="secondary"
                                onHandleClick={() => handleCancelSelectVersion()}
                                disabled={loadingVersion}
                                variant="outlined"
                            >Annuler</CaijButton>
                        </DialogActions>
                    </Dialog>
                </>
            }
            <Collapse orientation="vertical" in={true} timeout="auto">
                <Editor
                    tinymceScriptSrc={TINY_MCE_SCRIPT_CDN}
                    onInit={(evt, editor) => editorRef.current = editor}
                    onDirty={handleDirty}
                    disabled={saving || loadingVersion}
                    initialValue={initialContent}
                    value={content}
                    onEditorChange={handleChange}
                    init={initTinyMCE}
                />
                <Box mt={1}>
                    <Typography color="text.error" variant='body2'>{touched && errors}</Typography>
                </Box>
            </Collapse>
            {!inForm &&
                <Box mt={3}>
                    <Authorize
                        resourceCode={pageModel.ResourceCode}
                        mode={Mode.edit}
                        onIsAuth={(l) => setIsAuth(l)}
                    >
                        <LoadingButton
                            onClick={() => handlePublish(content)}
                            startIcon={<SvgIcon fontSize='small'><Upload /></SvgIcon>}
                            loading={(content === null && initialContent !== "") || saving}
                            loadingPosition="start"
                            variant="contained"
                            sx={btnSubmit}
                            disabled={!isAuth || touched || loadingVersion}
                        >Publier</LoadingButton>
                        <LoadingButton
                            onClick={() => handleSave(content)}
                            startIcon={<SvgIcon fontSize='small'><Save /></SvgIcon>}
                            loading={(content === null && initialContent !== "") || saving}
                            loadingPosition="start"
                            variant="contained"
                            sx={btnSubmit}
                            disabled={!isAuth || !touched || loadingVersion}
                        >Sauvegarder</LoadingButton>
                    </Authorize> 
                    <CaijButtonCopy
                        value={content}
                        sx={btnSubmit}
                        disabled={(content === null && initialContent !== "") || saving}
                    />
                    <LoadingButton
                        variant="contained"
                        loadingPosition="start"
                        loading={loadingVersion}
                        color="info"
                        sx={btnSubmit}
                        disabled={saving}
                        startIcon={<SvgIcon fontSize='small'><List /></SvgIcon>}
                        onClick={() => setShowVersionHistory(true)}
                    >Historique</LoadingButton>
                    {  documentLock && employee.role === EmployeeRole.Admin && (
                            <CaijButton
                                sx={btnSubmit}
                                onHandleClick={handleUnlockDocument}
                                disabled={saving || !documentLock}
                            >Déverrouiller</CaijButton>
                        )
                    }
                </Box>
            }
        </Box>
    )
}

HtmlContentEditor.propTypes = {
    documentLock: PropTypes.object,
    initialContent: PropTypes.string,
    onPublish: PropTypes.func,
    onSave: PropTypes.func,
    docId: PropTypes.string,
    redirectPath: PropTypes.string
}

export default HtmlContentEditor;