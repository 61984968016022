import React, { useEffect, useReducer } from 'react';
import type { FC } from 'react';
import { Box, Container } from '@mui/material';
import {
  useLocation,
  useParams,
  fetchContentPublisher,
  _contentPublisher,
  AccessModel,
  root
} from 'src/common';
import Page from 'src/components/Page';
import ContentPublishersModel from 'src/model/content/ContentPublishers';
import ContentPublishersEditForm from './ContentPublishersEditForm';
import { ContentPublisherProvider } from 'src/contexts/ContentPublisherContext';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import GenericHeader from 'src/components/header/GenericHeader';

const ContentPublishersEditView: FC = () => {
  const model = ContentPublishersModel.getInstance();
  const access = new AccessModel(model.ResourceCode);
  const location = useLocation();
  const [state, dispatch] = useReducer(_contentPublisher, { isLoading: false, contentPublisher: {} });
  const { code } = useParams();
 
  useEffect(() => {
    const initialise = async () : Promise<void> => {
      if(access.canEdit(code)){
        const model = new ContentPublishersModel();
        const pathName = location.pathname;
        if (code) {
          model.PathName = pathName;
          const contentPublisher = await model.getContentPublisherByCode(code);
          if (!model.error) {
            dispatch(fetchContentPublisher(contentPublisher,true));
          }
        }else{
          model.PathName = pathName;
          dispatch(fetchContentPublisher({},true));
        }
      }
    };
    initialise();
  }, [code, location]);

  const { contentPublisher, isLoading } = state;

  if (!isLoading) return <LoadingScreen />

  model.Title = contentPublisher.nameFr;

  return (
    <Page
      sx={root}
      title={model.getEditPageTitle(code)}
    >
      <Container maxWidth={false}>
        <GenericHeader id={code} model={model} headerTitle={code ? model.Title : model.BtnAddText} />
        <Box mt={3}>
          <ContentPublisherProvider>
            <ContentPublishersEditForm 
              model={model}
              contentPublisher={contentPublisher} 
            />
          </ContentPublisherProvider>
        </Box>
      </Container>
    </Page>
  );
};

export default ContentPublishersEditView;
