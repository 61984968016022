import React from 'react';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import type { SubscriptionResource } from 'src/common/types';
import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Link
} from '@mui/material';
import { labelConfig, tableRow, link, SubscriptionModel } from 'src/common';
import { tableCell } from 'src/styles/subscription';
import MyCard from 'src/components/card/MyCard';
import CardHeaderDetail from 'src/components/card/CardHeaderDetail';

export interface ContactInfoProps {
  model: SubscriptionModel;
  subscription: SubscriptionResource;
}

const width = {
  width: '145px'
};

const ContactInfo: FC<ContactInfoProps> = ({
  model,
  subscription
}) => {
  const { ContactName, ContactEmail, ContactPhone, AddressLine1, AddressLine2,
    AddressCity, AddressState, AddressZip, AddressCountry
  } = model;
  return (
    <MyCard>
      <CardHeaderDetail title="Personne contact" />
      <Divider />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell sx={{...tableCell, width}}>
              { ContactName.Label }
            </TableCell>
            <TableCell>
              <Typography
                variant="body2"
                sx={{color:"text.secondary"}}
              >
                { subscription.contact.name }
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{...tableCell, width}}>
              { ContactEmail.Label }
            </TableCell>
            <TableCell>
              <Link
                underline="none"
                href={`mailto=${subscription.contact.email}`}
                sx={{...link, color:'text.secondary'}}   
              >
                {subscription.contact.email}
              </Link>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{...tableCell, width}}>
              {ContactPhone.Label}
            </TableCell>
            <TableCell>
              <Typography
                variant="body2"
                sx={{color:"text.secondary"}}
              >
                {subscription.contact.phone}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{...tableCell, width}}>
              { AddressLine1.Label }
            </TableCell>
            <TableCell>
              <Typography
                variant="body2"
                sx={{color:"text.secondary"}}
              >
                { subscription.contact.address.line1 }
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{...tableCell, width}}>
              { AddressLine2.Label }
            </TableCell>
            <TableCell>
              <Typography
                variant="body2"
                sx={{color:"text.secondary"}}
              >
                { subscription.contact.address ? subscription.contact.address.line2 : '' }
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{...tableCell, width}}>
              { AddressCity.Label }
            </TableCell>
            <TableCell>
              <Typography
                variant="body2"
                sx={{color:"text.secondary"}}
              >
                {subscription.contact.address.city}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{...tableCell, width}}>
              { AddressState.Label }
            </TableCell>
            <TableCell>
              <Typography
                variant="body2"
                sx={{color:"text.secondary"}}
              >
                { subscription.contact.address.state }
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{...tableCell, width}}>
              { AddressZip.Label }
            </TableCell>
            <TableCell>
              <Typography
                variant="body2"
                sx={{color:"text.secondary"}}
              >
                { subscription.contact.address.zip }
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow sx={tableRow}>
            <TableCell sx={{...tableCell, width}}>
              { AddressCountry.Label }
            </TableCell>
            <TableCell>
              <Typography
                variant="body2"
                sx={{color:"text.secondary"}}
              >
                {subscription.contact.address.country}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </MyCard>
  );
};

ContactInfo.propTypes = {
  subscription: PropTypes.object.isRequired
};

export default ContactInfo;
