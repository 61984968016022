import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import type {
  ContentMostViewedResourceForEdit,
  ContentMostViewedResource
} from 'src/common/types';
import {Box,CardContent} from '@mui/material';
import {
  Formik,
  handleChange,
  handleChangeCheckBox,
  Mode,
  CaijButtonSubmit,
  CaijButtonReset,
  btnSubmit
} from 'src/common';
import printMessage from 'src/views/errors/MessageError';
import ContentMostViewedModel from 'src/model/content/ContentMostViewed';
import Authorize from 'src/components/Authorize';
import ContentMostViewedForm from 'src/components/content/contentMostViewed/Forms';
import MyCard from 'src/components/card/MyCard';
import { Root } from 'src/components/styled';
import validateSchema from '../../Schema';

export interface DetailsEditFormProps {
  model: ContentMostViewedModel;
  contentMostViewed: ContentMostViewedResource;
  onHandleRefreshContentMostViewed: (value: ContentMostViewedResource) => void;
  onSwitchMode: (isCancel: boolean) => void;
  onSubmit?: (values: ContentMostViewedResource) => void;
}

const DetailsEditForm: FC<DetailsEditFormProps> = ({
  model,
  contentMostViewed,
  onHandleRefreshContentMostViewed,
  onSwitchMode,
  onSubmit
}) => {
  const switchMode = (isCancel: boolean) => {
    onSwitchMode(isCancel);
  };

  const handleSubmit = async (values: ContentMostViewedResource) => {
    if(onSubmit) {
      onSubmit(values);
      return;
    }
    const model = new ContentMostViewedModel();
    const submitData = values as ContentMostViewedResourceForEdit;
    if (submitData && submitData.id >= 0) {
      const response = await model.updateContentMostViewed(submitData);
      if (!model.error) {
        printMessage({
          status: response.status,
          message: response.message
        });
        const newContentMostViewed = await model.getContentMostViewedById(submitData.id.toString());
        onHandleRefreshContentMostViewed(newContentMostViewed);
      }  
    }
  };

  return (
    <Formik
      initialValues={{...contentMostViewed}}
      validationSchema={validateSchema(model)}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleSubmit,
        setFieldValue,
        values,
        touched,
        isSubmitting
      }) => (
        <form onSubmit={handleSubmit}>
          <MyCard>
            <CardContent>
                <Root>
                  <ContentMostViewedForm
                    model={model}
                    contentMostViewed={values}
                    errors={errors}
                    touched={touched}
                    onHandleBlur={handleBlur}
                    onHandleChange={handleChange}
                    onHandleChangeCheckBox={handleChangeCheckBox}
                    setFieldValue={setFieldValue}
                />
               </Root>
              <Box mt={2}>
                <Authorize
                  resourceCode={model.ResourceCode}
                  mode={Mode.edit}
                >
                  <CaijButtonSubmit disabled={isSubmitting} sx={btnSubmit} />
                </Authorize>
                <CaijButtonReset disabled={isSubmitting} onSwitchMode={switchMode} />
              </Box>
            </CardContent>
          </MyCard>
        </form>
      )}
    </Formik>
  );
};

DetailsEditForm.propTypes = {
  contentMostViewed: PropTypes.object.isRequired,
  onHandleRefreshContentMostViewed: PropTypes.func.isRequired,
  onSwitchMode: PropTypes.func.isRequired,
  onSubmit: PropTypes.func
};

DetailsEditForm.defaultProps = {
  onSubmit: null
};

export default DetailsEditForm;
