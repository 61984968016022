import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import type { ContentPublishersResource } from 'src/common/types';
import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Box,
  Link
} from '@mui/material';
import { ContentPublishersModel, link, tableRow } from 'src/common';
import CaijImage from 'src/components/image/CaijImage';
import { tableCell } from 'src/styles/contentPublishers';
import MyCard from 'src/components/card/MyCard';
import CardHeaderDetail from 'src/components/card/CardHeaderDetail';

export interface ContentPublishersEnInfoProps {
  model: ContentPublishersModel;
  contentPublisher: ContentPublishersResource;
}

const ContentPublishersEnInfo: FC<ContentPublishersEnInfoProps> = ({
  model,
  contentPublisher
}) => {
  const {
    nameEn, logoUrlEn, homeUrlEn, contentUrlEn
  } = contentPublisher;
  const { NameEn, HomeUrlEn, ContentUrlEn, LogoUrlEn} = model;
  return (
    <MyCard>
      <CardHeaderDetail title="Content publisher" />
      <Divider />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell sx={tableCell}>
              { NameEn.Label }
            </TableCell>
            <TableCell>
              <Typography
                variant="body2"
                sx={{color:"text.secondary"}}
              >
                {nameEn}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={tableCell}>
              {LogoUrlEn.Label}
            </TableCell>
            <TableCell>
              <Box>
                { logoUrlEn && (<CaijImage id="logoUrlEn" src={logoUrlEn} alt={logoUrlEn} />)}
              </Box>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={tableCell}>
              {HomeUrlEn.Label}
            </TableCell>
            <TableCell>
              <Typography
                variant="body2"
                sx={{color:"text.secondary"}}
              >
                {
                  homeUrlEn && (
                    <Link
                      underline="none"
                      href={homeUrlEn}
                      sx={{ ...link, color: 'text.secondary' }}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {homeUrlEn}
                    </Link>
                  )
                }
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow sx={tableRow}>
            <TableCell sx={tableCell}>
              {ContentUrlEn.Label}
            </TableCell>
            <TableCell>
              <Typography
                variant="body2"
                sx={{color:"text.secondary"}}
              >
                {
                  contentUrlEn && (
                    <Link
                      underline="none"
                      href={contentUrlEn}
                      sx={{ ...link, color: 'text.secondary' }}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {contentUrlEn}
                    </Link>
                  )
                }
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </MyCard>
  );
};

ContentPublishersEnInfo.propTypes = {
  contentPublisher: PropTypes.object.isRequired
};

export default ContentPublishersEnInfo;
