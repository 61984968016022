import { DocLanguage, MSG_NO_CONTENT_ERROR, NoContent, Success } from 'src/common';
import type {
	DocumentOptionResource,
  DocumentResource, 
	DocumentResourceForEdit, 
	Error,
	GetDocumentListAsyncOptionalParams
} from 'src/common/types';
import AppModel from '../App';
import axios from 'src/utils/axios';
import { PagedList } from 'src/types/pagination';
import { FormModel } from '../Form';
import { $enum } from 'ts-enum-util';

export default class DocumentModel extends AppModel {

	private static _instance: DocumentModel;
	readonly Searchable = new FormModel('searchable','Indexer pour la recherche');
	readonly Visible = new FormModel('visible', 'Active');
	readonly Collection = new FormModel('collection', 'Collection', 45);
	readonly DocumentType = new FormModel('documentType', 'Type de document', 50);
	readonly DocumentStore = new FormModel('documentStore', 'Magasin de document', 45);
	readonly DocumentStoreInfo = new FormModel('documentStoreInfo', 'Informations sur la banque de documents', 100);
	readonly Filepath = new FormModel('filepath', 'Chemin du fichier', 1024);
	readonly Titre = new FormModel('title', 'Titre', 600);
  readonly Identifier = new FormModel('identifier', 'Identifiant', 100);
	readonly Tribunal = new FormModel('tribunal', 'Tribunal', 20);
	readonly Juridiction = new FormModel('juridiction', 'Juridiction', 20);
	readonly Lang = new FormModel('lang','Langue du document',2);
  readonly OtherLangDocId = new FormModel('otherLangDocId', 'Autre langue du document ID', 200);
  readonly LawId = new FormModel('lawId','Lois annotée');
  readonly Publisher = new FormModel('publisher','Éditeur de contenu', 250);
	readonly Published = new FormModel('published','Date de publication');
	readonly RssDate = new FormModel('rssDate','Date RSS');
	readonly Metadatas = new FormModel('metadatas','Metadatas');

	constructor() {
		super('/content/document');
		this.initialize();
	}

	private initialize(){
    this._resourceCode = 'DOCUMENT';
		this._headerTitle = 'Liste des documents';
		this.Path.PathName = '/contents/documents';
	}

	get Section(){
    return {
      resourceCode: this._resourceCode, 
      title: 'Documents',
      href: this.Path.Home,
      visible: true
    }
  }

	get DocumentId(){
		return "Document ID";
	}

	static getDocLanguageByKey(key: string){
		return $enum(DocLanguage).getValueOrDefault(key);
	}

	static getInstance(): DocumentModel {
    if (!DocumentModel._instance) {
      DocumentModel._instance = new DocumentModel();
    }
    return DocumentModel._instance;
  }


	getHeadCells(status: string){ 
    return [
      {
        width: "20%", label: this.DocumentId
      },
      {
        width: "50%", label: this.Titre.Label
      },
      {
        width: "20%", label: this.Collection.Label
      },
      {
        width: "5%", label: status
      },
      {
        width: '5%', align: 'right' as 'right'
      }
    ];
  };

	async getDocuments(params: GetDocumentListAsyncOptionalParams): Promise<PagedList<DocumentOptionResource>> {
		let pagedList: PagedList<DocumentOptionResource>;
		await axios.get<PagedList<DocumentOptionResource>>(`${this.route}/documents`, { params }).then(
			async (response) => {
				const { status, data } = response;
        if (status === Success) {
          pagedList = data;
        }
			},
			async (error) => {
				this.error = error;
				await this.handleError(this.error);
			},
		);
		return pagedList;
	}

	async updateDocument(submitData: DocumentResourceForEdit): Promise<Error> {
		let result: Error;
		await axios
			.put<DocumentResourceForEdit, Error>(`${this.route}/info?doc=${encodeURIComponent(submitData.documentId)}`, submitData)
			.then(
				async (response) => {
					const { status } = response;
					try {
						if (status === Success) {
							result = response;
							result.message = 'Document modifié.';
						} else if (status === NoContent) {
							throw new Error(MSG_NO_CONTENT_ERROR);
						}
					} catch (ex) {
						this.error = { status, message: ex.message };
						await this.handleError(this.error);
					}
				},
				async (error) => {
					this.error = error;
					await this.handleError(this.error);
				},
			);
		return result;
	}

	async getDocumentById(documentId: string): Promise<DocumentResource> {
		let document: DocumentResource;
		await axios.get<DocumentResource>(`${this.route}/info?doc=${encodeURIComponent(documentId)}`).then(
			async (response) => {
				const { status, data } = response;
				try {
					if (status === Success) {
						document = data;
					} else if (status === NoContent) {
						throw new Error(MSG_NO_CONTENT_ERROR);
					}
				} catch (ex) {
					this.error = { status, message: ex.message };
					await this.handleError(this.error);
				}
			},
			async (error) => {
				this.error = error;
				await this.handleError(this.error);
			},
		);
		return document;
	}

	async triggerDownload(documentId: string): Promise<boolean> {
    let isSuccess = false;
		await axios.post(`${this.route}/download?doc=${encodeURIComponent(documentId)}`).then(
			async (response) => {
				isSuccess = response && response.status === Success;
			},
			async (error) => {
				this.error = error;
				await this.handleError(this.error);
			},
		);
		return isSuccess;
	}
}
