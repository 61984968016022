import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Link,
  Typography
} from '@mui/material';
import { labelConfig, tableRow, link, ContentMostViewedModel} from 'src/common';
import type { ContentMostViewedResource } from 'src/common/types';
import Label from 'src/components/Label';
import MyCard from 'src/components/card/MyCard';
import { tableCell } from 'src/styles/contentMostViewed';
import CardHeaderDetail from 'src/components/card/CardHeaderDetail';

export interface ContentMostViewedInfoProps {
  model: ContentMostViewedModel;
  contentMostViewed: ContentMostViewedResource;
}

const ContentMostViewedInfo: FC<ContentMostViewedInfoProps> = ({
  model,
  contentMostViewed
}) => {
  const { title, url, secured, rank } = contentMostViewed;
  const { Titre, Url, Secured, Rank } = model;
  return (
    <MyCard>
      <CardHeaderDetail title="Contenu le plus vu" />
      <Divider />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell sx={tableCell}>
              { Titre.Label }
            </TableCell>
            <TableCell>
              <Typography
                variant="body2"
                sx={{color:"text.secondary"}}
              >
                { title }
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={tableCell}>
              { Url.Label }
            </TableCell>
            <TableCell>
              <Link
                underline="none"
                sx={{...link, color:"text.secondary"}}
                href={url}
                rel="noopener noreferrer"
                target="_blank"
              >
                {url}
              </Link>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={tableCell}>
              { Secured.Label }
            </TableCell>
            <TableCell>
              <Label color={secured ? 'success' : 'error'}>
                {secured ? labelConfig.yes : labelConfig.no}
              </Label>
            </TableCell>
          </TableRow>
          <TableRow sx={tableRow}>
            <TableCell sx={tableCell}>
              { Rank.Label }
            </TableCell>
            <TableCell>
              <Typography
                variant="body2"
                sx={{color:"text.secondary"}}
              >
                {rank }
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </MyCard>
  );
};

ContentMostViewedInfo.propTypes = {
  contentMostViewed: PropTypes.object.isRequired
};

export default ContentMostViewedInfo;
