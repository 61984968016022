import React, { useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Box, CardContent } from '@mui/material';
import {
  Formik,
  handleChange,
  handleChangeImageUrl,
  Mode,
  CaijButtonSubmit,
  CaijButtonReset,
  btnSubmit,
  handleChangeDateChange
} from 'src/common';
import type {
  ContentPublicationsResource,
  ContentPublicationsResourceForCreate,
  ContentPublicationsResourceForEdit,
  CreateResponse,
  Error
} from 'src/common/types';
import Authorize from 'src/components/Authorize';
import MyCard from 'src/components/card/MyCard';
import ContentPublicationsModel from 'src/model/content/ContentPublications';
import ContentPublicationForm from 'src/components/content/contentPublication/Forms';
import { Root } from 'src/components/styled';
import validateSchema from '../Schema';

export interface ContentPublicationsEditFormProps {
  model: ContentPublicationsModel;
  contentPublication: ContentPublicationsResource;
  onSubmit?: (values: ContentPublicationsResource) => void;
}

async function redirect(model: ContentPublicationsModel, response: Error | CreateResponse) : Promise<void>{
  await model.redirect(model.Path.Home,{
    status: response.status,
    message: response.message
  });
};

const ContentPublicationsEditForm: FC<ContentPublicationsEditFormProps> = ({
  model,
  contentPublication,
  onSubmit
}) => {
  const [isAuth, setIsAuth] = useState<boolean>();
  const [isCreate] = useState<boolean>(!contentPublication.code);

  const handleSubmit = async (values:ContentPublicationsResource) => {
    if (onSubmit) {
		  onSubmit(values);
      return;
    }
    const model = new ContentPublicationsModel();
    const newCover = values.cover;
    let submitData = values as ContentPublicationsResourceForEdit;
    submitData.cover = newCover;
    if (!isCreate) {
      const response = await model.updateContentPublication(submitData);
      if (!model.error) {
        await redirect(model, response);
      }
    } else {
      submitData = values as ContentPublicationsResourceForCreate;
      submitData.cover = newCover;
      const response = await model.insertContentPublication(submitData);
      if (!model.error) {
        await redirect(model, response);
      }
    }
  }

  return (
    <Formik
      initialValues={{...ContentPublicationsModel.getInitialValues(contentPublication)}}
      validationSchema={validateSchema(model)}
      onSubmit={handleSubmit}
    >
      {({
        errors, handleBlur, handleSubmit, setFieldValue, values, touched, isSubmitting
      }) => (
        <form onSubmit={handleSubmit}>
          <MyCard>
            <CardContent>
              <Box display="flex" flexDirection="column" alignItems="flex-start">
                <Root>
                  <ContentPublicationForm
                    model={model} 
                    contentPublication={values} 
                    isCreate={isCreate}
                    errors={errors}
                    touched={touched}
                    onHandleBlur={handleBlur}
                    onHandleChange={handleChange}
                    onHandleChangeImageUrl={handleChangeImageUrl}
                    setFieldValue={setFieldValue}
                  />
                </Root>
              </Box>
              <Box>
                <Authorize
                  resourceCode={model.ResourceCode}
                  mode={contentPublication.code ? Mode.edit : Mode.add}
                  onIsAuth={(l) => setIsAuth(l)}
                >
                  <CaijButtonSubmit disabled={!isAuth || isSubmitting} sx={btnSubmit} />
                </Authorize>
                <CaijButtonReset disabled={isSubmitting} pathName={model.Path.Home} />
              </Box>
            </CardContent>
          </MyCard>
        </form>
      )}
    </Formik>
  );
};

ContentPublicationsEditForm.propTypes = {
  contentPublication: PropTypes.object.isRequired,
  onSubmit: PropTypes.func
};

ContentPublicationsEditForm.defaultProps = {
  onSubmit: null
}

export default ContentPublicationsEditForm;
