import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import type { ContentPublishersResource } from 'src/common/types';
import ContentPublishersFrInfo from './ContentPublishersFrInfo';
import ContentPublishersEnInfo from './ContentPublishersEnInfo';
import { ContentPublishersModel } from 'src/common';

interface DetailsProps {
  model: ContentPublishersModel;
  contentPublisher: ContentPublishersResource;
}

const Details: FC<DetailsProps> = ({
  model,
  contentPublisher
}) => (
  <Grid
    container
    spacing={3}
  >
    <Grid
      item
      lg={6}
      md={6}
      xl={6}
      xs={12}
    >
      <ContentPublishersFrInfo model={model} contentPublisher={contentPublisher} />
    </Grid>
    <Grid
      item
      lg={6}
      md={6}
      xl={6}
      xs={12}
    >
      <ContentPublishersEnInfo model={model} contentPublisher={contentPublisher} />
    </Grid>
  </Grid>
);

Details.propTypes = {
  contentPublisher: PropTypes.object.isRequired
};

export default Details;
