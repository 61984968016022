import React, { useEffect } from 'react';
import type { FC, ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import type { ContentPublicationsResource } from 'src/common/types';
import { Box, TextField } from '@mui/material';
import { 
  labelConfig, 
  CaijInput, 
  FormikErrors, 
  FormikTouched, 
  CaijImage,
  CaijTextarea, 
  CaijSwitch, 
  handleChangeSwitch, 
  CaijDatePicker,
  ContentPublicationsModel
} from 'src/common';
import UploadImage from 'src/components/image/UploadImage';
import {$enum} from "ts-enum-util";
import CaijYearPicker from 'src/components/date/CaijYearPicker';
import useContentPublication from 'src/hooks/useContentPublication';

interface ContentPublicationFormProps {
  model: ContentPublicationsModel,
  contentPublication: ContentPublicationsResource;
  isCreate: boolean;
  errors: FormikErrors<ContentPublicationsResource>;
  touched: FormikTouched<ContentPublicationsResource>;
  onHandleBlur: (e: ChangeEvent<any>) => void;
  onHandleChange: (event: ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => void;
  onHandleChangeImageUrl:(name: string,imageUrl: string, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

export enum Language {
  FR = 'Français',
  EN = 'English'
};

const ContentPublicationForm: FC<ContentPublicationFormProps> = ({
  model,
  contentPublication,
  isCreate,
  errors,
  touched,
  onHandleBlur,
  onHandleChange,
  onHandleChangeImageUrl,
  setFieldValue,
}) => {
  const { getCollections, getContentTypes, getContentPublishers } = useContentPublication();
  const { code, title, collection, cover, lang, authors, year, callnumber, 
         publisher, toc, visible, searchable, published, documentType
        } = contentPublication;
  const { Code, Authors, Titre, Collection, DocumentType, Callnumber, Publisher, Toc, Published, Cover} = model;

  useEffect(() => {
    if(!visible){
      setFieldValue('searchable', false);
    }
  },[visible])
 
  return (
    <>
      <CaijInput
        disabled={!isCreate}
        required
        name={Code.Name}
        id={Code.Name}
        value={code}
        helperText={touched.code && errors.code}
        error={Boolean(touched.code && errors.code)}
        label={Code.Label}
        InputLabelProps={{ shrink: true, required: true }}
        inputAttr={{maxLength: Code.MaxLength, 'data-testid': Code.Name }}
        onHandleBlur={onHandleBlur}
        onHandleChange={onHandleChange}
        setFieldValue={setFieldValue}
      />
      <CaijInput
        required
        name={Titre.Name}
        id={Titre.Name}
        value={title}
        helperText={touched.title && errors.title}
        error={Boolean(touched.title && errors.title)}
        label={Titre.Label}
        InputLabelProps={{ shrink: true, required: true }}
        inputAttr={{ maxLength: Titre.MaxLength, 'data-testid': Titre.Name}}
        onHandleBlur={onHandleBlur}
        onHandleChange={onHandleChange}
        setFieldValue={setFieldValue}
      />
     <Box>
        <CaijSwitch
          name='visible'
          label="Visible sur l'espace CAIJ"
          checked={visible}
          value={visible}
          sx={{mb:2}}
          inputProps={{ 'aria-label': 'visible', 'data-testid': 'visible' }}
          onHandleChangeSwitch={handleChangeSwitch}
          setFieldValue={setFieldValue}
        />
      </Box>
      <Box>
        <CaijSwitch
          name='searchable'
          label={labelConfig.searchable}
          disabled={!visible}
          checked={searchable}
          value={searchable}
          sx={{mb:2}}
          inputProps={{ 'aria-label': 'searchable', 'data-testid': 'searchable' }}
          onHandleChangeSwitch={handleChangeSwitch}
          setFieldValue={setFieldValue}
        />
      </Box>
      <Box mb={1}>
        <CaijInput
          name={Authors.Name}
          id={Authors.Name}
          value={authors}
          label={Authors.Label}
          helperText={touched.authors && errors.authors}
          error={Boolean(touched.authors && errors.authors)}
          InputLabelProps={{ shrink: true }}
          inputAttr={{ maxLength: Authors.MaxLength, 'data-testid': Authors.Name }}
          onHandleBlur={onHandleBlur}
          onHandleChange={onHandleChange}
          setFieldValue={setFieldValue}
        />
      </Box>
      { cover && (
          <Box mb={3}>
            <CaijImage id='cover' src={cover} alt={cover} />
          </Box>
        )
      }
      <Box mb={3}>
        <UploadImage
          imgLabel={Cover.Label}
          maxLength={Cover.MaxLength}
          resourceCode={model.ResourceCode}
          btnText={cover ? labelConfig.coverEditBtn : labelConfig.coverBtn}
          imgName={Cover.Name}
          onHandleChangeImageUrl={onHandleChangeImageUrl}
          setFieldValue={setFieldValue}
        />
      </Box>
      <TextField
        required
        label={labelConfig.Langue}
        id='lang'
        name='lang'
        onChange={(e:ChangeEvent<HTMLInputElement>) => {
          setFieldValue('lang',e.target.value);
        }}
        select
        SelectProps={{ native: true }}
        value={lang}
        variant='outlined'
        InputLabelProps={{ shrink: true, required: true }}
        inputProps={{ 'aria-label': 'lang', 'data-testid': 'lang' }}
        sx={{ marginBottom: 3, marginTop: 2, width: '50%' }}
      >
        {
          $enum(Language).map((value, key) => <option value={key} key={key}>{value}</option>)
        }
      </TextField>
      <TextField
        label={Collection.Label}
        id={Collection.Name}
        name={Collection.Name}
        onChange={(e: ChangeEvent<HTMLInputElement>) => onHandleChange(e,setFieldValue)}
        select
        SelectProps={{ native: true }}
        value={collection || ''}
        variant='outlined'
        InputLabelProps={{ shrink: true }}
        inputProps={{ 'aria-label': 'collection', 'data-testid': Collection.Name }}
        sx={{ mt: 2, mb: 3, width: '100%' }}
      >
        <option value=''>Sélectionner une collection</option>
        { getCollections() }
      </TextField>
      <TextField
        required
        label={DocumentType.Label}
        id={DocumentType.Name}
        name={DocumentType.Name}
        onChange={(e: ChangeEvent<HTMLInputElement>) => onHandleChange(e,setFieldValue)}
        onBlur={(e) => onHandleBlur(e)}
        select
        SelectProps={{ native: true }}
        value={documentType || ''}
        variant='outlined'
        InputLabelProps={{ shrink: true, required: true }}
        inputProps={{ 'aria-label': 'documentType', 'data-testid': DocumentType.Name }}
        helperText={touched.documentType && errors.documentType}
        error={Boolean(touched.documentType && errors.documentType)}
        sx={{ mt: 2, mb: 3, width: '100%' }}
      >
        <option value='' disabled>Sélectionner un type de contenu</option>
        { getContentTypes() }
      </TextField>
      <TextField
        required
        label={Publisher.Label}
        id={Publisher.Name}
        name={Publisher.Name}
        onChange={(e: ChangeEvent<HTMLInputElement>) => onHandleChange(e,setFieldValue)}
        onBlur={(e) => onHandleBlur(e)}
        select
        SelectProps={{ native: true }}
        value={publisher || ''}
        helperText={touched.publisher && errors.publisher}
        error={Boolean(touched.publisher && errors.publisher)}
        variant='outlined'
        InputLabelProps={{ shrink: true, required: true }}
        inputProps={{ 'aria-label': 'publisher', 'data-testid': Publisher.Name}}
        sx={{ mt: 2, mb:3, width: '100%' }}
      >
        <option value='' disabled>Sélectionner un éditeur</option>
        { getContentPublishers() }
      </TextField>
      <Box sx={{mt:2, mb:3, width:"110px"}}>
        <CaijYearPicker
          name='year'
          required
          label={labelConfig.year}
          value={year}
          inputProps={{"data-testid": 'year', 'aria-label': 'year'}}
          onHandleChange={(l) => setFieldValue('year', l)}
        />
      </Box>
      <Box mt={5}>
        <CaijDatePicker 
          name={Published.Name}
          required
          value={String(published)}
          label={Published.Label}
          inputProps={{"data-testid": Published.Name, 'aria-label': Published.Name}}
          helperText={touched.published && errors.published}
          onHandleChange={(l) => setFieldValue(Published.Name,l)}
        />
      </Box>
      <CaijInput
        name={Callnumber.Name}
        id={Callnumber.Name}
        value={callnumber}
        label={Callnumber.Label}
        helperText={touched.callnumber && errors.callnumber}
        error={Boolean(touched.callnumber && errors.callnumber)}
        InputLabelProps={{ shrink: true }}
        inputAttr={{ maxLength: Callnumber.MaxLength, 'data-testid': Callnumber.Name }}
        onHandleBlur={onHandleBlur}
        onHandleChange={onHandleChange}
        setFieldValue={setFieldValue}
      />
      <CaijTextarea
        required
        name={Toc.Name}
        rows='4'
        label={Toc.Label}
        value={toc}
        helperText={touched.toc && errors.toc}
        error={Boolean(touched.toc && errors.toc)}
        inputProps={{'aria-label': 'toc', 'data-testid': Toc.Name }}
        InputLabelProps={{ shrink: true, required: true }}
        onHandleBlur={onHandleBlur}
        onHandleChange={onHandleChange}
        setFieldValue={setFieldValue}
      />
    </>
  );
};

ContentPublicationForm.propTypes = {
  contentPublication: PropTypes.object.isRequired,
  isCreate: PropTypes.bool,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  onHandleBlur: PropTypes.func.isRequired,
  onHandleChangeImageUrl: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  onHandleChange: PropTypes.func.isRequired
};

export default ContentPublicationForm;


