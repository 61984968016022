import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import type {
  ContentPublishersResourceForEdit,
  ContentPublishersResource
} from 'src/common/types';
import {
  Box,
  CardContent,
  Grid
} from '@mui/material';
import {
  Formik,
  handleChange,
  handleChangeImageUrl,
  Mode,
  CaijButtonSubmit,
  CaijButtonReset,
  btnSubmit
} from 'src/common';
import ContentPublishersModel from 'src/model/content/ContentPublishers';
import printMessage from 'src/views/errors/MessageError';
import Authorize from 'src/components/Authorize';
import ContentPublisherForm from 'src/components/content/contentPublisher/Forms';
import MyCard from 'src/components/card/MyCard';
import validateSchema from '../../Schema';

export interface DetailsEditFormProps {
  model: ContentPublishersModel;
  contentPublisher: ContentPublishersResource;
  onHandleRefreshContentPublisher: (value: ContentPublishersResource) => void;
  onSwitchMode: (isCancel: boolean) => void;
  onSubmit?: (value: ContentPublishersResource) => void;
}

const DetailsEditForm: FC<DetailsEditFormProps> = ({
  model,
  contentPublisher,
  onHandleRefreshContentPublisher,
  onSwitchMode,
  onSubmit
}) => {
  const switchMode = (isCancel: boolean) => {
    onSwitchMode(isCancel);
  };

  const handleSubmit = async (values: ContentPublishersResource) => {
    if(onSubmit) {
      onSubmit(values);
      return;
    }
    const model = new ContentPublishersModel();
    const newLogoUrlFr = values.logoUrlFr;
    const newLogoUrlEn = values.logoUrlEn;
    const submitData = values as ContentPublishersResourceForEdit;
    submitData.logoUrlFr = newLogoUrlFr;
    submitData.logoUrlEn = newLogoUrlEn;
    if (submitData && submitData.code) {
      const response = await model.updateContentPublisher(submitData);
      if (!model.error) {
        printMessage({
          status: response.status,
          message: response.message
        });
        const newContentPublisher = await model.getContentPublisherByCode(submitData.code);
        onHandleRefreshContentPublisher(newContentPublisher);
      } 
    }
  };

  return (
    <Formik
      initialValues={{...contentPublisher}}
      validationSchema={validateSchema(model, contentPublisher)}
      validateOnChange={false}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleSubmit,
        setFieldValue,
        values,
        touched,
        isSubmitting
      }) => (
        <form onSubmit={handleSubmit}>
          <MyCard>
            <CardContent>
              <Grid
                container
                spacing={2}
              >
                <ContentPublisherForm 
                    model={model}
                    contentPublisher={values} 
                    isCreate={false}
                    errors={errors}
                    touched={touched}
                    onHandleBlur={handleBlur}
                    onHandleChange={handleChange}
                    onHandleChangeImageUrl={handleChangeImageUrl}
                    setFieldValue={setFieldValue}
                 />
              </Grid>
              <Box mt={2}>
                <Authorize
                  resourceCode={model.ResourceCode}
                  mode={Mode.edit}
                >
                  <CaijButtonSubmit disabled={isSubmitting} sx={btnSubmit} />
                </Authorize>
                <CaijButtonReset disabled={isSubmitting} onSwitchMode={switchMode} />
              </Box>
            </CardContent>
          </MyCard>
        </form>
      )}
    </Formik>
  );
};

DetailsEditForm.propTypes = {
  contentPublisher: PropTypes.object.isRequired,
  onHandleRefreshContentPublisher: PropTypes.func.isRequired,
  onSwitchMode: PropTypes.func.isRequired,
  onSubmit: PropTypes.func
};

DetailsEditForm.defaultProps = {
  onSubmit: null
};

export default DetailsEditForm;
