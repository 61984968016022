import { ContentPublishersModel, ErrorType } from 'src/common';
import { ContentPublishersResource } from 'src/common/types';
import useContentPublisher from 'src/hooks/useContentPublisher';
import * as Yup from 'yup';

const validateSchema = (model: ContentPublishersModel, contentPublisher: ContentPublishersResource) => {
  const { verifyContentPublisherCode } = useContentPublisher();
  const { Code, NameFr, NameEn, HomeUrlFr, HomeUrlEn, ContentUrlFr, ContentUrlEn, LogoUrlFr, LogoUrlEn, } = model;
  return Yup.object().shape({
    code: Code.required(true)
    .test(Code.Label, ContentPublishersModel.formatError(ErrorType.exist, Code.Label), async (value: string) => {
      if (value) {
        return await verifyContentPublisherCode(value, contentPublisher.code);
      }
      return true;
    }),
    nameFr: NameFr.required(true),
    nameEn: NameEn.required(true),
    homeUrlFr: HomeUrlFr.nullable(true), 
    homeUrlEn: HomeUrlEn.nullable(true), 
    contentUrlFr: ContentUrlFr.nullable(true), 
    contentUrlEn:  ContentUrlEn.nullable(true), 
    logoUrlFr: LogoUrlFr.nullable()
      .test(LogoUrlFr.Label, ContentPublishersModel.formatError(ErrorType.max, LogoUrlFr.Label, LogoUrlFr.MaxLength), (logoUrlFr: string) => {
        return ContentPublishersModel.verifyImageUrlLength(logoUrlFr, LogoUrlFr.MaxLength);
    }),
    logoUrlEn: LogoUrlEn.nullable()
      .test(LogoUrlEn.Label, ContentPublishersModel.formatError(ErrorType.max, LogoUrlEn.Label, LogoUrlEn.MaxLength), (logoUrlEn: string) => {
        return ContentPublishersModel.verifyImageUrlLength(logoUrlEn, LogoUrlEn.MaxLength);
      }),
    });
};

export default validateSchema;