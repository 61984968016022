import PropTypes from 'prop-types';
import { FC, ReactNode, useCallback } from 'react';
import { createContext } from 'react';
import { usePublication } from 'src/functions/hooks/publication';

export interface ContentPublicationContextValue {
  getCollections: () => JSX.Element[];
  getContentTypes: () => JSX.Element[];
  getContentPublishers: () => JSX.Element[];
}

interface ContentPublicationProviderProps {
  children: ReactNode;
}

const ContentPublicationContext = createContext<ContentPublicationContextValue>({
  getCollections: () : JSX.Element[] => [],
  getContentTypes: () : JSX.Element[] => [],
  getContentPublishers: () : JSX.Element[] => []
});

export const ContentPublicationProvider: FC<ContentPublicationProviderProps> = ({ children }) => {
  
  const { sContentPublisher, sCollection, sDocumentType } = usePublication();
  
  const getCollections = useCallback(() : JSX.Element[] => {
    const { isLoading, isAuthorize, docCollections } = sCollection;
    if(isLoading && isAuthorize && docCollections) 
       return docCollections.map(({id, code, nameFr}) => (
        <option key={id} value={code}>
          {nameFr}
        </option>
      ))              
  },[sCollection]);

  const getContentTypes = useCallback(() : JSX.Element[] => {
    const { isLoading, isAuthorize, documentTypes } = sDocumentType;
    if(isLoading && isAuthorize && documentTypes) 
      return documentTypes.map(({code, nameFr}) => (
        <option key={code} value={code}>
          {nameFr}
        </option>
      ))       
  },[sDocumentType]);

  const getContentPublishers = useCallback(() : JSX.Element[] => {
    const { isLoading, isAuthorize, contentPublishers } = sContentPublisher;
    if(isLoading && isAuthorize && contentPublishers) 
      return contentPublishers.map(({code, nameFr}) => (
        <option key={code} value={code}>
          {nameFr}
        </option>         
      ))       
  },[sContentPublisher]);

  return (
    <ContentPublicationContext.Provider
      value={{
        getCollections,
        getContentTypes,
        getContentPublishers
      }}
    >
      {children}
    </ContentPublicationContext.Provider>
  );
};

ContentPublicationProvider.propTypes = {
  children: PropTypes.any.isRequired
};

export default ContentPublicationContext;
