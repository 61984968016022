import {
  Success,
  NoContent,
  MSG_NO_CONTENT_ERROR,
  Created
} from 'src/common';
import type {
  ContentMostViewedResource,
  Error,
  ContentMostViewedResourceForCreate,
  ContentMostViewedResourceForEdit,
  CreateResponse
} from 'src/common/types';
import AppModel from '../App';
import axios from 'src/utils/axios';
import { BtnOk } from 'src/components/dialog/CaijDialog';
import { FormModel } from '../Form';
import printMessage from 'src/views/errors/MessageError';

type TypeResourceEdit = ContentMostViewedResourceForCreate | ContentMostViewedResourceForEdit;

export default class ContentMostViewedModel extends AppModel
{
  private static _instance: ContentMostViewedModel;
  readonly Titre = new FormModel('title','Titre',500);
  readonly Url = new FormModel('url','Url',2000);
  readonly Secured = new FormModel('secured', 'Sécurisé');
  readonly Rank = new FormModel('rank','Rang',11);

  constructor(){
    super('/content/mostviewed');
    this.initialize();
  }

  private initialize(){
    this._resourceCode = 'CONTENT_MOST_VIEWED';
    this._headerTitle = 'Liste des contenus le plus vu';
    this._btnAddText = 'Ajouter un contenu le plus vu';
    this.Path.PathName = '/contents/most-viewed';

    //Dialog
    this.Dialog.Header = 'Supprimer le contenu le plus vu';
    this.Dialog.BtnText = BtnOk.DELETED;
    this.Dialog.Name = 'le contenu le plus vu';
  }

  get Section(){
    return {
      resourceCode: this._resourceCode, 
      title: 'Contenus le plus vu',
      href: this.Path.Home,
      visible: true
    }
  }

  getHeadCells(){
    return [
      {
        id: this.Titre.Name, width: '35%', numeric: false, disablePadding: false, label: this.Titre.Label
      },
      {
        id: this.Url.Name, width: '50%', numeric: false, disablePadding: false, label: this.Url.Label
      },
      {
        id: '', width: '5%', numeric: false, disablePadding: false, label: this.Secured.Label
      },
      {
        id: this.Rank.Name, width: '5%', numeric: false, disablePadding: false, label: this.Rank.Label
      },
      {
        id: '', width: '5%'
      }
    ];
  }

  static getInstance(): ContentMostViewedModel {
    if (!ContentMostViewedModel._instance) {
      ContentMostViewedModel._instance = new ContentMostViewedModel();
    }
    return ContentMostViewedModel._instance;
  }

  async getContentMostVieweds(): Promise<ContentMostViewedResource[]> {
    let contentMostVieweds: ContentMostViewedResource[];
    await axios.get<ContentMostViewedResource[]>(this.route).then(async (response) => {
      const { status, data } = response;
      try {
        if (status === Success) {
          contentMostVieweds = data;
        } else if (status === NoContent) {
          throw new Error(MSG_NO_CONTENT_ERROR);
        }
      } catch (ex) {
        this.error = { status, message: ex.message };
        await this.handleError(this.error);
      }
    },
    async (error) => {
      this.error = error;
      await this.handleError(this.error);
    });
    return contentMostVieweds;
  }

  async getContentMostViewedById(id: string): Promise<ContentMostViewedResource> {
    let contentMostViewed: ContentMostViewedResource;
    await axios.get<ContentMostViewedResource>(`${this.route}/${+id}`).then(async (response) => {
      const { status, data } = response;
      try {
        if (status === Success) {
          contentMostViewed = data;
        } else if (status === NoContent) {
          throw new Error(MSG_NO_CONTENT_ERROR);
        }
      } catch (ex) {
        this.error = { status, message: ex.message };
        await this.handleError(this.error);
      }
    },
    async (error) => {
      this.error = error;
      await this.handleError(this.error);
    });
    return contentMostViewed;
  }

  async updateContentMostViewed(submitData: TypeResourceEdit): Promise<Error> {
    let result: Error;
    await axios.put<ContentMostViewedResourceForEdit,Error>(`${this.route}/${submitData.id}`,submitData).then(async (response) => {
      const { status } = response;
      try {
        if (status === Success) {
          result = response;
          result.message = 'Contenu le plus vu modifié.';
        } else if (status === NoContent) {
          throw new Error(MSG_NO_CONTENT_ERROR);
        }
      } catch (ex) {
        this.error = { status, message: ex.message };
        await this.handleError(this.error);
      }
    }, async (error) => {
      this.error = error;
      await this.handleError(this.error);
    });
    return result;
  }

  async insertContentMostViewed(submitData: TypeResourceEdit): Promise<CreateResponse> {
    let result: CreateResponse;
    await axios.post<ContentMostViewedResourceForCreate, CreateResponse>(this.route,submitData).then((response) => {
      const { status } = response;
      if (status === Created) {
        result = response;
        result.message = 'Contenu le plus vu créé.'
      }
    }, async (error) => {
      this.error = error;
      await this.handleError(this.error);
    });
    return result;
  }

  async delete(id: string|number, allowedRedirect: boolean = false): Promise<boolean> {
    let success: boolean = false;
    await axios.delete<any, Error>(`${this.route}/${id}`).then((response) => {
      const { status } = response;
      if (status === Success) {
        printMessage({
          status: status, 
          message: 'Contenu le plus vu supprimé.'
        });
        if(allowedRedirect)
          this.redirect(this.Path.Home);
        success = true;
      }
    }, async (error) => {
      this.error = error;
      await this.handleError(this.error);
    });
    return success;
  }
}
