import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import type { ContentPublishersResource } from 'src/common/types';
import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Box,
  Link
} from '@mui/material';
import { ContentPublishersModel, link, tableRow } from 'src/common';
import CaijImage from 'src/components/image/CaijImage';
import { tableCell } from 'src/styles/contentPublishers';
import MyCard from 'src/components/card/MyCard';
import CardHeaderDetail from 'src/components/card/CardHeaderDetail';

export interface ContentPublishersFrInfoProps {
  model: ContentPublishersModel;
  contentPublisher: ContentPublishersResource;
}

const ContentPublishersFrInfo: FC<ContentPublishersFrInfoProps> = ({
  model,
  contentPublisher
}) => {
  const { nameFr, logoUrlFr, homeUrlFr, contentUrlFr } = contentPublisher;
  const { NameFr, HomeUrlFr, ContentUrlFr, LogoUrlFr} = model;
  return (
    <MyCard>
      <CardHeaderDetail title="Éditeur de contenu" />
      <Divider />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell sx={tableCell}>
              { NameFr.Label }
            </TableCell>
            <TableCell>
              <Typography
                variant="body2"
                sx={{color:"text.secondary"}}
              >
                {nameFr}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={tableCell}>
              {LogoUrlFr.Label}
            </TableCell>
            <TableCell>
              <Box>
                { logoUrlFr && (<CaijImage id="logoUrlFr" src={logoUrlFr} alt={logoUrlFr} />) }
              </Box>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={tableCell}>
              {HomeUrlFr.Label}
            </TableCell>
            <TableCell>
              <Typography
                variant="body2"
                sx={{color:"text.secondary"}}
              >
                {
                  homeUrlFr && (
                    <Link
                      underline="none"
                      href={homeUrlFr}
                      sx={{ ...link, color: 'text.secondary' }}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {homeUrlFr}
                    </Link>
                  )
                }
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow sx={tableRow}>
            <TableCell sx={tableCell}>
              {ContentUrlFr.Label}
            </TableCell>
            <TableCell>
              <Typography
                variant="body2"
                sx={{color:"text.secondary"}}
              >
                {
                  contentUrlFr && (
                    <Link
                      underline="none"
                      href={contentUrlFr}
                      sx={{ ...link, color: 'text.secondary' }}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {contentUrlFr}
                    </Link>
                  )
                }
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </MyCard>
  );
};

ContentPublishersFrInfo.propTypes = {
  contentPublisher: PropTypes.object.isRequired
};

export default ContentPublishersFrInfo;
