import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import type { ContentPublicationsResource } from 'src/common/types';
import {Box,SvgIcon} from '@mui/material';
import {labelConfig, Label} from 'src/common';
import { Eye as EyeIcon } from 'react-feather';
import CaijTypography from 'src/components/typography';
import CaijLabel from 'src/components/label/CaijLabel';

export interface VisibilityInfoProps {
  content: ContentPublicationsResource;
}

const VisibilityInfo: FC<VisibilityInfoProps> = ({content}) => {
  const { visible, searchable } = content;
  return (
    <Box sx={{mb:'2em'}} display='flex'>
      <SvgIcon fontSize='small'>
        <EyeIcon />
      </SvgIcon>
      <Box display='flex' marginLeft='0.5em' flexDirection='column'>
        <Box display='flex' alignItems='center'>
          <CaijTypography sx={{ fontWeight: 'bold' }}>Visibilité</CaijTypography>
        </Box>
        <CaijLabel label={labelConfig.active}>
          <Label color={visible ? 'success' : 'error'}>
          { visible ? labelConfig.yes : labelConfig.no}
          </Label>
        </CaijLabel>
        <CaijLabel label={labelConfig.searchable}>
          <Label color={searchable ? 'success' : 'error'}>
            { searchable ? labelConfig.yes : labelConfig.no}
          </Label>
        </CaijLabel>
      </Box>
    </Box>
  );
};

VisibilityInfo.propTypes = {
  content: PropTypes.object.isRequired
};

export default VisibilityInfo;