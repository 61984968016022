import React from 'react';
import type { FC } from 'react';
import { Typography } from '@mui/material';
import CaijCard from '../card/CaijCard';
import DraftEditor from './DraftEditor';

interface CaijDraftEditorProps {
  title: string;
  dataTestId: string;
  name: string;
  value: string;
  allowedValidate?: boolean;
  allowedBackgroundColor?: boolean;
  errors?: string;
  touched?: boolean;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
}

const CaijDraftEditor: FC< CaijDraftEditorProps> = ({
  title,
  dataTestId,
  value,
  name,
  allowedValidate,
  allowedBackgroundColor,
  errors,
  touched,
  setFieldValue
} :  CaijDraftEditorProps) => {
  if(allowedValidate){
    return (
      <CaijCard title={title} sx={{mb:4}} allowedBackgroundColor={allowedBackgroundColor}>
        <DraftEditor
          dataTestId={dataTestId}
          value={value}
          className='demo-wrapper'
          onEditorChange={l => {setFieldValue(name, (l === '\n' || !l) ? '' : l, true)}}
        />  
        <Typography color="text.error" variant='body2'>{touched && errors}</Typography>
      </CaijCard>
    )
  }
  return (
    <CaijCard title={title} sx={{mb:4}} allowedBackgroundColor={allowedBackgroundColor}>
      <DraftEditor
        dataTestId={dataTestId}
        value={value}
        className='demo-wrapper'
        onEditorChange={l => {setFieldValue(name, l, true)}}
      />
    </CaijCard>
  )
};

CaijDraftEditor.defaultProps = {
  allowedBackgroundColor: true
}

export default  CaijDraftEditor;
