import React, { useEffect, useReducer } from 'react';
import type { FC } from 'react';
import { Box, Container } from '@mui/material';
import {
  useLocation,
  useParams,
  fetchContentMostViewed,
  _contentMostViewed,
  AccessModel,
  useNavigate,
  root
} from 'src/common';
import Page from 'src/components/Page';
import ContentMostViewedModel from 'src/model/content/ContentMostViewed';
import ContentMostViewedEditForm from './ContentMostViewedEditForm';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import GenericHeader from 'src/components/header/GenericHeader';

const ContentMostViewdEditView: FC = () => {
  const model = ContentMostViewedModel.getInstance();
  const access = new AccessModel(model.ResourceCode);
  const navigate = useNavigate();
  const location = useLocation();
  const [state, dispatch] = useReducer(_contentMostViewed, { isLoading: false, contentMostViewed: {} });
  const { id } = useParams();

  useEffect(() => {
    const initialise = async () => {
      if(access.canEdit(id)){
        const model = new ContentMostViewedModel();
        const pathName = location.pathname;
        if(id && isNaN(+id)){
          navigate(model.Path.Home);
        }else if (id) {
          model.PathName = pathName;
          const contentMostViewed = await model.getContentMostViewedById(id);
          if (!model.error) {
            dispatch(fetchContentMostViewed(contentMostViewed, true));
          }
        } else{
          model.PathName = pathName;
          dispatch(fetchContentMostViewed({}, true));
        }
      }
    };
    initialise();
  }, [id, location]);

  const { contentMostViewed, isLoading } = state;

  if (!isLoading) return <LoadingScreen />

  model.Title = contentMostViewed.title;

  return (
    <Page
      sx={root}
      title={model.getEditPageTitle(id)}
    >
      <Container maxWidth={false}>
        <GenericHeader id={id} model={model} headerTitle={id ? model.Title : model.BtnAddText} />
        <Box mt={3}>
          <ContentMostViewedEditForm model={model} contentMostViewed={contentMostViewed} />
        </Box>
      </Container>
    </Page>
  );
};

export default ContentMostViewdEditView;
