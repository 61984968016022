import React, { useState } from 'react';
import type { FC } from 'react';
import type { LawHomePageDto, LawResource } from 'src/common/types';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography
} from '@mui/material';
import {
  Language,
  dialog,
  CaijButton,
  Transition,
  TINY_MCE_SCRIPT_CDN,
  initTinyMCE
} from 'src/common';
import { publishVersion } from 'src/styles/law';
import CaijAppBar from 'src/components/AppBar';
import { getPublishVersion } from 'src/utils/law';
import { Editor } from "@tinymce/tinymce-react";

interface LawHomePageProps {
  open: boolean;
  law: LawResource;
  lawHomePage: LawHomePageDto;
  language: Language;
  publish: (value: LawHomePageDto, language: Language) => void,
  handleClose: () => void;
}

const LawHomePage: FC<LawHomePageProps> = ({
  open,
  law,
  lawHomePage,
  language,
  publish,
  handleClose
}) => {
  const [value, setValue] = useState<LawHomePageDto | null>(null);
  const setPublish = () => publish(value, language);
  return (
    <Dialog fullScreen open={open || false} disableEnforceFocus={true} TransitionComponent={Transition} onClose={handleClose}>
      <CaijAppBar title={`Page d'accueil - ${language === Language.FR ? law.titleFr : law.titleEn}`} extra={language} handleClose={handleClose} />
      <DialogContent sx={dialog} dividers>
        <Editor
          tinymceScriptSrc={TINY_MCE_SCRIPT_CDN}
          initialValue={lawHomePage?.page}
          onEditorChange={(page) => {
            if(page){
              setValue((prevState: LawHomePageDto) => {
                return {
                  ...prevState,
                  page,
                  lang: language,
                  lawId: law.id
                }
              });
            }
          }}
          init={initTinyMCE}
        />
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "space-between", backgroundColor: 'background.paper' }}>
        <Typography variant="body2" sx={publishVersion}>{getPublishVersion(law.lawHomePages.find(({lang}) => lang === language))}</Typography>
        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <CaijButton variant="contained" onHandleClick={() => setPublish()}>Publier</CaijButton>&nbsp;&nbsp;
          <Button variant="outlined" onClick={handleClose}>Annuler</Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default LawHomePage;
