import React, { useCallback, useState, useEffect, useReducer } from 'react';
import type { FC, ChangeEvent } from 'react';
import type {
  ContentPublishersResource
} from 'src/common/types';
import {
  Box,
  Container,
  Divider,
  Tab,
  Tabs
} from '@mui/material';
import {
  useParams,
  fetchDetailsContentPublisher,
  _contentPublisher,
  AccessModel,
  root
} from 'src/common';
import Page from 'src/components/Page';
import ContentPublishersModel from 'src/model/content/ContentPublishers';
import DetailsEditForm from './Forms/DetailsEditForm';
import Details from './Details';
import { ContentPublisherProvider } from 'src/contexts/ContentPublisherContext';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import GenericHeader from 'src/components/header/GenericHeader';

const ContentPublishersDetailsView: FC = () => {
  const model = ContentPublishersModel.getInstance();
  const access = new AccessModel(model.ResourceCode);
  const [state, dispatch] = useReducer(_contentPublisher, { contentPublisher: {} });
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editMode, setEditMode] = useState({
    details: false,
    subscription: false,
  });
  const [currentTab, setCurrentTab] = useState<string>('details');
  const { code } = useParams();

  const tabs = [
    { value: 'details', label: 'Détails' }
  ];

  const getContentPublishersByCode = useCallback(async () : Promise<void> => {
    if (access.canRead()) {
      const model = new ContentPublishersModel();
      model.PathName = location.pathname;
      const contentPublisher = await model.getContentPublisherByCode(code);
      if (!model.error) {
        dispatch(fetchDetailsContentPublisher(contentPublisher));
      }
    }
  }, [code, location]);

  useEffect(() => {
    (async () => {
      await getContentPublishersByCode();
    })();
  }, []);

  const setEditModeContentPublisher = (isEdit: boolean) => {
    setEditMode({
      ...editMode,
      [currentTab]: isEdit
    });
    setIsEditing(isEdit);
  };

  const switchMode = (isEdit: boolean) => {
    setEditModeContentPublisher(isEdit);
  };

  const handleTabsChange = (event: ChangeEvent<any>, value: string): void => {
    setEditModeContentPublisher(false);
    setCurrentTab(value);
  };

  const handleRefreshContentPublisher = (contentPublisher: ContentPublishersResource) => {
    dispatch(fetchDetailsContentPublisher(contentPublisher));
    setEditModeContentPublisher(false);
  };

  const editContentPublisher = () => {
    setEditModeContentPublisher(true);
  };

  const { contentPublisher } = state;

  if (Object.keys(contentPublisher).length === 0) {
    return <LoadingScreen />;
  }

  model.Title = `${contentPublisher.nameFr} - ${contentPublisher.code}`;

  const renderContent = () => {
    switch (currentTab) {
      case 'details':
        if (editMode.details) {
          return (
            <ContentPublisherProvider>
              <DetailsEditForm
                model={model}
                contentPublisher={contentPublisher}
                onHandleRefreshContentPublisher={handleRefreshContentPublisher}
                onSwitchMode={(l) => switchMode(l)}
              />
            </ContentPublisherProvider>
          );
        }
        return (
          <Details contentPublisher={contentPublisher} model={model} />
        );
      default:
        return '';
    }
  };

  return (
    <Page
      sx={root}
      title={model.DetailPageTitle}
    >
      <Container maxWidth={false}>
        <GenericHeader 
          id={code} 
          model={model} 
          headerTitle={contentPublisher.nameFr} 
          actions={['editDetail','delete']}
          editMode={editMode.details}
          titleBadge={{label: contentPublisher.code}}
          onEditDetail={() => editContentPublisher()}
        />
        <Box mt={2}>
          <Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            value={currentTab}
            variant="scrollable"
            textColor="secondary"
          >
            {tabs.map((tab) => (
              <Tab
                key={tab.value}
                label={tab.label}
                value={tab.value}
              />
            ))}
          </Tabs>
        </Box>
        <Divider />
        <Box mt={3}>
          { renderContent() }
        </Box>
      </Container>
    </Page>
  );
};

export default ContentPublishersDetailsView;
