import { useEffect, useState, useReducer, useCallback } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Link,
  List,
  ListItem,
  ListItemIcon,
  Typography
} from '@mui/material';
import {
  Forbidden,
  MSG_EMPTY_LIST,
  CaijCard,
  Unauthorized,
  ListType,
  ListInfos,
  Result,
  DatabankModel,
  _databank,
  fetchDatabanks,
  CaijCheckbox,
  MSG_FORBIDEN_ERROR,
  getProxy,
  DatabankAppType,
  DatabankAccessType
} from 'src/common';
import type {
  DocumentCollectionResource,
  DocumentCollectionItemResource,
  ProductItemResource,
  DatabankResource,
  DatabankItemResource,
  SubscriptionItemResource
} from 'src/common/types';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { useCheckLists } from './hook';
import CaijListItemText from '../CaijListItemText';
import AccessListItem from '../access/AccessListItem';
import { selectAllGroups, toogleSelectedItems } from 'src/utils/accessList';

export type TypeListArray = DocumentCollectionItemResource[] | ProductItemResource[] | DatabankItemResource[] | SubscriptionItemResource[] | number[];
type TypeListIncludedArray = DocumentCollectionItemResource[] | DatabankItemResource[];

interface CheckListProps {
  listIncluded?: TypeListIncludedArray; //For lists of group already included only
  listType : ListType;
  lists: TypeListArray;
  group?: number[];
  onHandleSelectedItems: (value: number[]) => void;
  onHandleSelectedGroupItems?: (value: number[]) => void;
}

const root = {
  width: '100%',
  backgroundColor: 'background.paper'
};

const initialDatabankState = {
  databanks: [],
  isLoading: false,
  isEmptyList: true,
  isAuthorize: false
};

function getBackgroundColor(listType: ListType){
  switch(listType){
    case ListType.Subscription:
      return false;
    default:
      return true;
  }
}

const CheckList : FC<CheckListProps> = ({
  listIncluded,
  listType,
  lists,
  group,
  onHandleSelectedItems,
  onHandleSelectedGroupItems
}) => {
  const isMountedRef = useIsMountedRef();
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<number[]>([]);
  const [stateDatabanks, dispatchDatabanks] = useReducer(_databank, initialDatabankState);
  const { sCollection, sSubscription, sDatabankLibrary } = useCheckLists(listType);
  const [selectedAll, setSelectedAll] = useState<boolean>();
  const list = ListInfos();

  const getDatabanks = useCallback(async () : Promise<void> => {
    if(isMountedRef.current) {
      const model = new DatabankModel();
      model.skipHandleError = true;
      const databanks = await model.getDatabanks();
      const { error } = model;
      if (error) {
        if(error.status === Forbidden || error.status === Unauthorized) {
          dispatchDatabanks(fetchDatabanks(databanks, true, false, false));
        }else {
          dispatchDatabanks(fetchDatabanks(databanks, true, true, true));
        }
      }else{
        dispatchDatabanks(fetchDatabanks(databanks, true, !Array.isArray(databanks) || databanks.length === 0, true));
      }
    }
  },[isMountedRef]);

  useEffect(() => {
    const initialise = async () => {
      if (isMountedRef.current && lists) {
        let Ids:number[] = [];
        switch(listType){
          case ListType.Collection:
            const collections = lists as DocumentCollectionItemResource[];
            Ids = collections.map(({id}) => id);
            break;
          case ListType.CollectionGroupAccess:
            if(listIncluded)
              Ids = [...(listIncluded as DocumentCollectionItemResource[])].map(({id}) => id);
            break;
          case ListType.Databank:
            Ids = [...(lists as DatabankItemResource[])].map(({id}) => id);
            break;
          case ListType.DatabankGroupAcccess:
            if(listIncluded){
              Ids = [...(listIncluded as DatabankItemResource[])].map(({id}) => id);
            }
            break;
          case ListType.DatabankLibrary:
            Ids = [...(lists as DatabankItemResource[])].map(({id}) => id);
            break;
          case ListType.CollectionSubscription:
            Ids = lists as number[];
            break;
          case ListType.Subscription:
            Ids = [...(lists as SubscriptionItemResource[])].map(({id}) => id);
            break;
         }
        setSelectedItems(Ids);
        onHandleSelectedItems(Ids);
        if(group){
          setSelectedGroups(group);
          onHandleSelectedGroupItems(group);
        }
        listType === ListType.Databank ? await getDatabanks() : '';
      }
      if(isMountedRef.current && Array.isArray(listIncluded)){
        await getDatabanks();
      }
      isMountedRef.current = false;
    };
    (async () => {
      await initialise();
    })();
  }, [isMountedRef]);

  useEffect(() => {
    setSelectedAll(
      list.compareSelectedLists({
        listType,
        selectedItems,
        objData: {
          subscriptions: sSubscription?.subscriptions,
          databanks: stateDatabanks?.databanks,
          collections: sCollection?.docCollections,
          databankLibrary: sDatabankLibrary?.databanks,
          databankGroupAccess: lists as DatabankItemResource[],
          collectionGroupAccess: lists as DocumentCollectionResource[]
        }
      })
    );
  },[sSubscription?.isLoading,sCollection?.isLoading,stateDatabanks?.isLoading,sDatabankLibrary?.isLoading]);

  const handleToggle = (value: number, allowedSelectedGroup: boolean = false) => {
    if(allowedSelectedGroup){
      setSelectedGroups(toogleSelectedItems(value, {selectedItems: selectedGroups}) as number[]);
    }else{
      const newChecked = toogleSelectedItems(value, {selectedItems}) as number[];
      setSelectedItems(newChecked);
      setSelectedAll(list.compareSelectedLists({
        listType,
        selectedItems: newChecked,
        objData: {
          subscriptions: sSubscription?.subscriptions,
          databanks: stateDatabanks?.databanks,
          collections: sCollection?.docCollections,
          databankLibrary: sDatabankLibrary?.databanks,
          databankGroupAccess: lists as DatabankItemResource[],
          collectionGroupAccess: lists as DocumentCollectionResource[]
        }
      }));
    }
  };

  const handleLeaveList = () => {
    onHandleSelectedItems(selectedItems);
    if(onHandleSelectedGroupItems)
      onHandleSelectedGroupItems(selectedGroups);
  };

  const renderSelectedAll = () : JSX.Element => (
    <Typography variant='body1' key='selectedAll' sx={{ml:2}}>
      <Link href='#' underline='none' onClick={async () => {
        list.selectAll({
          listType,
          selectedAll,
          selectedItems,
          objData: {
            subscriptions: sSubscription?.subscriptions,
            databanks: stateDatabanks?.databanks,
            collections: sCollection?.docCollections,
            databankLibrary: sDatabankLibrary?.databanks
          }
        }, (value: number[]) => setSelectedItems(value));
        if(group){
          if(selectedAll){
            const groups = await selectAllGroups(sSubscription.subscriptions, { selectedGroups }) as number[];
            setSelectedGroups(groups);
            onHandleSelectedGroupItems(groups);
          }else{
            setSelectedGroups([]);
            onHandleSelectedGroupItems([]);
          }
        }
        setSelectedAll(!selectedAll);
      }}>Tout {selectedAll ? 'sélectionner' : 'désélectionner' }</Link>
    </Typography>
  )

  const renderListItem = (id: number, name:string, active: boolean = true) => (
    <Box key={id}>
      <ListItem key={id} role={undefined} dense>
      <Box width='100%' display='flex' alignItems='center' justifyContent='flex-start'>
        <Box>
          <ListItemIcon>
            <CaijCheckbox
              edge="end"
              checked={selectedItems.indexOf(id) !== -1}
              disableRipple
              inputProps={{ "aria-labelledby": `checkbox-list-label-${id}`}}
              onHandleChangeCheckBox={() => handleToggle(id)} 
              onHandleBlurCheckBox={handleLeaveList}
            />
          </ListItemIcon>
        </Box>
        <Box>
          <CaijListItemText name={name} active={active} color="primary" />
        </Box>
      </Box>
      </ListItem>
    </Box>
  );

  const renderContent = (): JSX.Element[] | JSX.Element => {
    switch(listType){
      case ListType.Collection:
        const { isLoading: docCollectionIsLoading, docCollections, isAuthorize: docCollectionIsAuthorize} = sCollection;
        if(docCollectionIsLoading && !docCollectionIsAuthorize){
          return list.getMessageError(MSG_FORBIDEN_ERROR);
        }
        const rsCollection: Result = docCollectionIsLoading && list.isListValid(docCollections, MSG_EMPTY_LIST);
        if(!rsCollection.success){
            return rsCollection.message;
        }
        return [renderSelectedAll(),...docCollections.map(({id, nameFr, active}) => renderListItem(id, nameFr, active))];
      case ListType.CollectionGroupAccess: 
        const collectionGroupAccess = lists as DocumentCollectionItemResource[];
        const rsCollectionGroupAccess: Result = list.isListValid(collectionGroupAccess, MSG_EMPTY_LIST);
        if(!rsCollectionGroupAccess.success){
          return rsCollectionGroupAccess.message;
        }
        return (
          <>
            <Typography variant='body1' key='selectedAll' sx={{ml:2}}>
              <Link href='#' underline='none' onClick={() => {
                list.selectAll({
                  listType,
                  selectedAll,
                  selectedItems,
                  objData: { collectionGroupAccess }
                }, (value: number[]) => setSelectedItems(value));
                setSelectedAll(!selectedAll);
              }}>Tout {selectedAll ? 'sélectionner' : 'désélectionner' }</Link>
            </Typography>
            { collectionGroupAccess.map(({id, nameFr, active}) => renderListItem(id, nameFr, active)) }
          </>
        )
      case ListType.Databank://Access (Databank)
        const { isLoading : databankIsLoading, databanks, isAuthorize : databankIsAuthorize } = stateDatabanks;
        if(databankIsLoading && !databankIsAuthorize){
          return list.getMessageError(MSG_FORBIDEN_ERROR);
        }
        const rsDatabank: Result = databankIsLoading && list.isListValid(databanks, MSG_EMPTY_LIST);
        if(!rsDatabank.success){
          return rsDatabank.message;
        }
        //Databank - Remote Access only (Online and Anywhere)
        return [renderSelectedAll(),...databanks.filter(({appType, databankAccesses}) => appType === DatabankAppType.Online && databankAccesses.some(a => a.accessType  === DatabankModel.getAccessTypeByVal(DatabankAccessType.Remote)))
                                                .map((databank: DatabankResource) => {
                                                  const { id, nameFr, enabled } = databank;
                                                  return renderListItem(id, `${nameFr} ${getProxy(databank)}`, enabled); 
                                                })];
      case ListType.DatabankLibrary:
        const { isLoading : databankLibraryIsLoading, databanks : dbs, isAuthorize : databankLibraryIsAuthorize } = sDatabankLibrary;
        if(databankLibraryIsLoading && !databankLibraryIsAuthorize){
          return list.getMessageError(MSG_FORBIDEN_ERROR);
        }
        const rsDatabankLibrary: Result = databankLibraryIsLoading && list.isListValid(dbs, MSG_EMPTY_LIST);
        if(!rsDatabankLibrary.success){
            return rsDatabankLibrary.message;
        }
        return [renderSelectedAll(),...dbs.map((db: DatabankResource) => {
          const { id, nameFr, enabled } = db;
          return renderListItem(id, `${nameFr} ${getProxy(db)}`, enabled); 
        })];
      case ListType.DatabankGroupAcccess://Access group (Databank)
        const databankGroupAccess = [...(lists as DatabankItemResource[])];
        const rsDatabankGroupAccess: Result = list.isListValid(databankGroupAccess, MSG_EMPTY_LIST);
        if(!rsDatabankGroupAccess.success){
          return rsDatabankGroupAccess.message;
        }
        return (
          <>
            <Typography variant='body1' key='selectedAll' sx={{ml:2}}>
              <Link href='#' underline='none' onClick={() => {
                list.selectAll({
                  listType,
                  selectedAll,
                  selectedItems,
                  objData: { databankGroupAccess }
                }, (value: number[]) => setSelectedItems(value));
                setSelectedAll(!selectedAll);
              }}>Tout {selectedAll ? 'sélectionner' : 'désélectionner' }</Link>
            </Typography>
            { databankGroupAccess.map(({id, nameFr, enabled}) => renderListItem(id, nameFr, enabled)) }
          </>
        );
      case ListType.CollectionSubscription:
      case ListType.Subscription:
        const { isLoading : subscriptionIsLoading, subscriptions, isAuthorize : subscriptionIsAuthorize } = sSubscription;
        if(subscriptionIsLoading && !subscriptionIsAuthorize){
          return list.getMessageError(MSG_FORBIDEN_ERROR);
        }
        const rsSubscription: Result = subscriptionIsLoading && list.isListValid(subscriptions, MSG_EMPTY_LIST);
        if(!rsSubscription.success){
          return rsSubscription.message;
        }
        return [renderSelectedAll(),...subscriptions.map(({id, name, active, groups}, index: number) =>
                  <AccessListItem
                    key={index}
                    id={id} 
                    name={name} 
                    active={active} 
                    data={{selectedSubscription: selectedItems, selectedGroups}}
                    groups={groups}
                    handleToggle={(value, allowedSelectedGroup) => handleToggle(value, allowedSelectedGroup)}
                    handleLeaveList={() => handleLeaveList()}
                  />
        )];
    }
  };

  return (
    <CaijCard title={list.renderCheckListTitle(listType)} sx={{mb:4}} allowedBackgroundColor={getBackgroundColor(listType)}>
      <List sx={root}>
        { renderContent() }
      </List>
    </CaijCard>
  );
};

CheckList.propTypes = {
  listIncluded: PropTypes.array,
  listType: PropTypes.oneOf([
    ListType.Collection,
    ListType.Databank,
    ListType.DatabankLibrary,
    ListType.Subscription,
    ListType.CollectionSubscription,
    ListType.DatabankGroupAcccess,
    ListType.CollectionGroupAccess
  ]),
  lists: PropTypes.array,
  onHandleSelectedItems: PropTypes.func.isRequired
};

CheckList.defaultProps = {
  listIncluded: null
};

export default CheckList;
