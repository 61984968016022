import { ContentPublicationsModel, ErrorType, labelConfig } from 'src/common';
import * as Yup from 'yup';

const validateSchema = (model: ContentPublicationsModel) => {
  const { Code, Titre, DocumentType, Collection, Authors, Callnumber, Publisher, Cover, Toc } = model;
  return Yup.object().shape({
    code: Code.required(true),
    title: Titre.required(true),
    documentType: DocumentType.required(true),
    collection: Collection.nullable(true),
    authors: Authors.nullable(true),
    callnumber: Callnumber.nullable(true),
    published: Yup.string()
      .transform((curr, orig) => orig === null ? '' : curr)
      .required(ContentPublicationsModel.formatError(ErrorType.required, labelConfig.publishedDate)),
    publisher: Publisher.required(true),
    cover: Cover.nullable()
      .test(Cover.Label, ContentPublicationsModel.formatError(ErrorType.max, Cover.Label, Cover.MaxLength), (cover: string) => {
        return ContentPublicationsModel.verifyImageUrlLength(cover, Cover.MaxLength);
    }),
    toc: Toc.required()
  });
};

export default validateSchema;