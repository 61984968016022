import React from 'react';
import type { Dispatch, FC } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import {
  _subscription,
  SubscriptionModel,
  deleteGroup
} from 'src/common';
import Results from './Results';
import { SubscriptionGroupResource } from 'src/common/types';
import { Action } from 'src/store/reducers/SubscriptionReducer';
import { EditAccessGroup } from 'src/types/accessGroup';

export interface AccessGroupListViewProps {
  subscriptionId: number;
  onEditAccessGroup: (value: EditAccessGroup) => void;
  onEditSubscription: () => void;
  groupList: SubscriptionGroupResource[]; 
  isEmptyList: boolean;
  isLoading: boolean;
  dispatch: Dispatch<Action>;
}

const AccessGroupListView: FC<AccessGroupListViewProps> = ({
  subscriptionId,
  onEditAccessGroup,
  onEditSubscription,
  groupList,
  isEmptyList,
  isLoading,
  dispatch
}) => {

  const handleDelete: (id: number, groupId: number) => Promise<void> = async (id, groupId) => {
    if(id && groupId){
      const model = new SubscriptionModel();
      if(await model.deleteGroup(id, groupId)){
        dispatch(deleteGroup(groupId));
        onEditAccessGroup({isEdit: false});
      }
    }
  };

  if (!isLoading) {
    return null;
  }

  return (
    <Box mt={3}>
      <Results
        subscriptionId={subscriptionId}
        groupList={groupList}
        isEmptyList={isEmptyList}
        onEditAccessGroup={onEditAccessGroup}
        onEditSubscription={onEditSubscription}
        onDeleteGroup={(id, groupId) => handleDelete(id, groupId)}
      />
    </Box>
  );
};

AccessGroupListView.propTypes = {
  subscriptionId: PropTypes.number.isRequired,
  onEditAccessGroup: PropTypes.func.isRequired,
  onEditSubscription: PropTypes.func.isRequired
}

export default AccessGroupListView;
