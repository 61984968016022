import {
  Box,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { ChangeEvent, FC } from 'react';
import { useEffect, useState } from 'react';
import {
  CaijCard, 
  CaijCheckbox,
  CaijInputRank,
  labelConfig, 
  ListType,
  ListInfos,
  Result,
  MSG_EMPTY_LIST,
  MSG_FORBIDEN_ERROR,
  AccessPermissions,
  LibraryModel,
  LibraryServiceModel,
  CaijInput,
  ONLINE_TRAINING,
  LIBRARY_ACCESS
  } from 'src/common';
import type { 
  DoorCardProfileDto,
  LibraryResource,
  LibraryServiceLibraryDto, 
  LibraryServiceLibraryOptionsDto, 
  LibraryServiceResource, 
  ProductItemResource, 
  ProductResource,
  SubscriptionGroupProductsResource,
  SubscriptionProductResource,
  SubscriptionResource,
  UxpertiseDto
} from 'src/common/types';
import useDoorAccessProfile from 'src/hooks/useDoorAccessProfile';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import CaijListItemText from '../CaijListItemText';
import { useLists, useProducts } from './hook';
import useUxpertise from 'src/hooks/useUxpertise';
import QtyRequiredInput from './lbsInput';
import AccessListItem from '../access/AccessListItem';
import { selectAllGroups, toogleSelectedItems } from 'src/utils/accessList';

type TypeListArray = LibraryServiceLibraryDto[] | LibraryServiceLibraryOptionsDto[]| ProductItemResource[] | SubscriptionProductResource[];
type TypeData = ProductResource | LibraryServiceResource | SubscriptionResource;

interface RequiredParameterListProps {
  setIsListValid?: (value: boolean) => void;
  listIncluded?: ProductItemResource[];
  listType : ListType;
  lists: TypeListArray;
  isParametersRequired?: boolean;
  subscriptionGroupProducts?: SubscriptionGroupProductsResource[];
  onHandleItemsSelected: (value: number[]) => void;
  onHandleSelectedGroupItems?: (value: SubscriptionGroupProductsResource[]) => void;
  onHandleRequireParmeters: (map: Map<number, string>) => void;
}
  
const root ={
  width: '100%',
  backgroundColor: (theme: Theme) => theme.palette?.background.paper
};

const leftCol = {
  width: '40%'
};
  
const leftlbsCol = {
  width: '25%'
};
  
enum Operation {
  add,
  deleted
}

interface base {
  productId?: number;
  productCode?: string;
  value?: string;
  isAuthorize?: boolean;
  isEmptyList?: boolean;
  isLoading?: boolean;
  handleChange?: (value:string) => void;
}

interface Kantech extends base {
  doorCardProfiles?: DoorCardProfileDto[];
};

interface Uxpertise extends base {
  uxpertises?: UxpertiseDto[];
}

const RequiredParameterList: FC<RequiredParameterListProps> = ({
  setIsListValid,
  listIncluded,
  listType,
  lists,
  isParametersRequired,
  subscriptionGroupProducts,
  onHandleItemsSelected,
  onHandleSelectedGroupItems,
  onHandleRequireParmeters
}) => {
  const isMountedRef = useIsMountedRef();
  const [itemsSelected, setItemsSelected] = useState<number[]>([]);
  const [selectedSubscriptionGroupProducts, setSelectedSubscriptionGroupProducts] = useState<SubscriptionGroupProductsResource[]>([]);
  const [selectedMapRequireParametersValue, setSelectedMapRequireParametersValue] = useState<Map<number, string>>();
  const [ids, setIds] = useState<number[]>([]);
  const { sLibraryService, sProduct, sSubscription, sLibrary} = useLists(listType);
  const list = ListInfos();
  let kantech: Kantech;
  let uxpertise: Uxpertise;
  const [selectedAll, setSelectedAll] = useState<boolean>();
  const libraryModel = LibraryModel.getInstance();
  const libraryServiceModel = LibraryServiceModel.getInstance();
 
  if(listType === ListType.Product){
    const {products} = useProducts();
    //Kantech
    kantech = Object.assign({},useDoorAccessProfile());
    let product = products.find(({code}) => code === LIBRARY_ACCESS);
    kantech.productId = product?.id
    kantech.productCode = product?.code;
    //Uxpertise
    uxpertise = Object.assign({},useUxpertise());
    product = products.find(({code}) => code === ONLINE_TRAINING);
    uxpertise.productId = product?.id; 
    uxpertise.productCode = product?.code
  }
  
  useEffect(() => {
    const initialise = () => {
      const mapRequireParametersValue = new Map<number, string>();
      if (isMountedRef.current && lists) {
          let Ids:number[] = [];
          switch(listType){
            case ListType.LibraryServiceLibrary:
              const lbServiceLibrary = lists as LibraryServiceLibraryDto[];
              Ids = lbServiceLibrary.map(({libraryId}) => libraryId);
              lbServiceLibrary.forEach(({libraryId, parameters}) => mapRequireParametersValue.set(libraryId, parameters));
              break;
            case ListType.LibraryService:
              const lbServices = lists as LibraryServiceLibraryOptionsDto[];
              Ids = lbServices.map(({service}) => service?.id);
              lbServices.forEach(({service, parameters}) => mapRequireParametersValue.set(service?.id, parameters));
              break;
            case ListType.Product:
              const products = [...(lists as ProductItemResource[])].filter(({access}) => access === AccessPermissions.Private);
              Ids = products.map(({id}) => id);
              products.forEach(({id, parameter}) => mapRequireParametersValue.set(id, String(parameter)));
              break;
            case ListType.ProductGroupAccess:
              if(listIncluded){
                const productsGroupAccess = [...(listIncluded as ProductItemResource[])].filter(({access}) => access === AccessPermissions.Private);
                Ids = productsGroupAccess.map(({id}) => id);
                productsGroupAccess.forEach(({id, parameter}) => mapRequireParametersValue.set(id, String(parameter)));
              }
              break;
            case ListType.Subscription:
              const subscriptions = lists as SubscriptionProductResource[];
              Ids = subscriptions.map(({id}) => id);
              subscriptions.forEach(({id, parameter}) => mapRequireParametersValue.set(id, String(parameter)));
              break;
          }
          setItemsSelected(Ids);
          onHandleItemsSelected(Ids);
          setSelectedMapRequireParametersValue(mapRequireParametersValue);
          onHandleRequireParmeters(mapRequireParametersValue);
          if(subscriptionGroupProducts){
            setSelectedSubscriptionGroupProducts(subscriptionGroupProducts);
            onHandleSelectedGroupItems(subscriptionGroupProducts);
          }
      }
      if(isMountedRef.current && Array.isArray(listIncluded)){
        if(listType === ListType.Product){
          const products = listIncluded as ProductItemResource[];
          products.forEach((product:ProductItemResource) => {
            mapRequireParametersValue.set(product.id, String(product.parameter));
          });
        }
        setSelectedMapRequireParametersValue(mapRequireParametersValue);
      }
      isMountedRef.current = false;
    };
    initialise();
  }, [isMountedRef, listType, lists, listIncluded]);
 
  useEffect(() => {
    if((ListType.LibraryService || ListType.LibraryServiceLibrary) && setIsListValid){
      if(ids.length === 0){
        setIsListValid(true);
      }else{
        setIsListValid(false);
      }
    }
  },[ids]);

  useEffect(() => {
    setSelectedAll(
      list.compareSelectedLists({
        listType,
        selectedItems: itemsSelected,
        objData: {
          subscriptions: sSubscription?.subscriptions,
          products: sProduct?.products,
          libraryServices: sLibraryService?.libraryServices,
          libraries: sLibrary?.libraries,
          productGroupAccess: lists as ProductItemResource[]
        }
      })
    );
  },[itemsSelected, sProduct?.isLoading,sSubscription?.isLoading,sLibraryService?.isLoading,sLibrary?.libraries]);

  const handleToggle = (id: number, allowedSelectedGroup: boolean = false) => {
    if(allowedSelectedGroup){
      setSelectedSubscriptionGroupProducts(toogleSelectedItems(id, {selectedSubscriptionGroupProducts}) as SubscriptionGroupProductsResource[]);
    }else{
      const newChecked = toogleSelectedItems(id, {selectedItems: itemsSelected}) as number[];
      //only for subscription with products
      if(listType === ListType.Product){ 
        if(!newChecked.includes(kantech?.productId)){
          kantech?.handleChange('');
        }
        if(!newChecked.includes(uxpertise?.productId)){
          uxpertise?.handleChange('');
        }
      }
      setItemsSelected(newChecked);
      setSelectedAll(
        list.compareSelectedLists({
          listType,
          selectedItems: newChecked,
          objData: {
            subscriptions: sSubscription?.subscriptions,
            products: sProduct?.products,
            libraryServices: sLibraryService?.libraryServices,
            libraries: sLibrary?.libraries,
            productGroupAccess: lists as ProductItemResource[]
          }
        })
      );
      if(listType === ListType.LibraryService || listType === ListType.LibraryServiceLibrary){
        const newIds = ids.filter((value: number) => value != id);
        setIds(newIds);
      }
    }
  };

  const handleLeaveList = () => {
    onHandleRequireParmeters(selectedMapRequireParametersValue);
    onHandleItemsSelected(itemsSelected);
    if(onHandleSelectedGroupItems)
      onHandleSelectedGroupItems(selectedSubscriptionGroupProducts);
  };

  const handleLeaveInput = (event: ChangeEvent<HTMLInputElement>, groupId?: number) => {
    const { name, value } = event.target;
    if(groupId){
      const groups = [...selectedSubscriptionGroupProducts].map((group) => {
        group.groupId === groupId ? group.parameter = Number(value) : group;
        return group;
      });
      setSelectedSubscriptionGroupProducts(groups);
      onHandleSelectedGroupItems(groups);
    }else{
      if (selectedMapRequireParametersValue) {
        const map: Map<number, string> = selectedMapRequireParametersValue;
        map.set(Number(name), value);
        setSelectedMapRequireParametersValue(map);
      } else {
        const map = new Map<number, string>();
        map.set(Number(name), value);
        setSelectedMapRequireParametersValue(map);
      }
      onHandleRequireParmeters(selectedMapRequireParametersValue);
      onHandleItemsSelected(itemsSelected);
    }
  };

  const setIsListvalid = (id: number, operation: Operation) => {
    if(operation === Operation.add){
      if(!ids.includes(id)) setIds([...ids, id]);
    }else if(operation === Operation.deleted){
      const newIds = ids.filter((value: number) => value !== id);
      setIds(newIds);
    }
  }

  const renderSelectedAll = () : JSX.Element => {
    return (
      <Typography variant='body1' key='selectedAll' sx={{ml:2}}>
        <Link href='#' underline='none' onClick={async () => {
          list.selectAll({
            listType,
            selectedAll,
            selectedItems: itemsSelected,
            objData: {
              products: sProduct?.products,
              subscriptions: sSubscription?.subscriptions,
              libraryServices: sLibraryService?.libraryServices,
              libraries: sLibrary?.libraries
            }
          }, (value: number[]) => setItemsSelected(value));
          if(subscriptionGroupProducts){
            if(selectedAll){
              const groups = await selectAllGroups(sSubscription.subscriptions, { selectedSubscriptionGroupProducts }) as SubscriptionGroupProductsResource[];
              setSelectedSubscriptionGroupProducts(groups);
              onHandleSelectedGroupItems(groups)
            }else{
              setSelectedSubscriptionGroupProducts([]);
              onHandleSelectedGroupItems([]);
            }
          }
          setSelectedAll(!selectedAll);
        }}>Tout {selectedAll ? 'sélectionner' : 'désélectionner' }</Link>
      </Typography>
    )
  }

  const renderParameterRequired = (listType: ListType, requireParameterValue: string, data: TypeData) : JSX.Element => {
    switch(listType){
      case ListType.LibraryService:
        return <QtyRequiredInput 
                  id={(data as LibraryServiceResource).id} 
                  value={requireParameterValue} 
                  maxLength={libraryModel.RequiredParameter.MaxLength}
                  handleLeaveInput={(e) => handleLeaveInput(e)} 
                  addId={(l: number) => setIsListvalid(l, Operation.add)}
                  removeId={(l: number) => setIsListvalid(l, Operation.deleted)}
               />
      case ListType.ProductGroupAccess:
      case ListType.Product :
        const product = data as ProductResource;
        if(product.parametersRequired){
          return (
            <CaijInputRank
              isListRecord
              label={labelConfig.requiredParameter}
              name={product.id.toString()}
              value={requireParameterValue}
              onHandleBlur={(e) => handleLeaveInput(e)}
              inputProps={{ maxLength: libraryModel.RequiredParameter.MaxLength }}
            />
          )
        }
        break;
      case ListType.LibraryServiceLibrary:
        return isParametersRequired && (
          <QtyRequiredInput 
            id={(data as LibraryResource).id} 
            value={requireParameterValue} 
            required={itemsSelected.indexOf(data.id) !== -1}
            maxLength={libraryServiceModel.RequiredParameter.MaxLength}
            handleLeaveInput={(e) => handleLeaveInput(e)} 
            addId={(l: number) => setIsListvalid(l, Operation.add)}
            removeId={(l: number) => setIsListvalid(l, Operation.deleted)}
          />
        )
    } 
  }

  const renderProductDropdowns = (data: ProductResource, itemsSelected: number[]) => {
    if(data.code === kantech.productCode && itemsSelected.indexOf(kantech.productId) !== -1 ){
      return (
        <CaijInput
          label={labelConfig.doorCardProfile}
          name='doorCardProfile'
          select
          value={kantech.value}
          variant='outlined'
          fullWidth
          InputLabelProps={{ shrink: true }}
          onHandleChange={(e: ChangeEvent<HTMLInputElement>) => kantech.handleChange(e.target.value)}
        >
          <option value=''>Sélectionner un profil de cartes</option>
          {
            (kantech.isAuthorize && !kantech.isEmptyList) && kantech.doorCardProfiles.map(({id, name}) =>
              <option key={id} value={id}>
                {name}
              </option>
            )
          }
        </CaijInput>
      )
    }else if(data.code === uxpertise.productCode && itemsSelected.indexOf(uxpertise.productId) !== -1 ){ 
       return (
          <CaijInput
            label="Groupes Uxpertise"
            name='uxpertise'
            select
            value={uxpertise.value}
            variant='outlined'
            fullWidth
            InputLabelProps={{ shrink: true }}
            onHandleChange={(e: ChangeEvent<HTMLInputElement>) => uxpertise.handleChange(e.target.value)}
          >
            <option value=''>Sélectionner un groupe Uxpertise</option>
            {
              (uxpertise.isAuthorize && !uxpertise.isEmptyList) && uxpertise.uxpertises.map(({id, name}) =>
                <option key={id} value={id}>
                  {name}
                </option>
              )
            }
          </CaijInput>
       )
    }
  }

  const renderListItem = (active: boolean, name:string, requireParameterValue: string, data: TypeData) : JSX.Element => (
    <Box key={data.id}>
      <ListItem key={data.id} role={undefined} dense>
        <Box display="flex" flexDirection="column" width='100%'>
          <Box width='100%' display='flex' alignItems='center'>
            <Box display='flex' alignItems='center' justifyContent='center' sx={leftCol}>
              <ListItemIcon>
                <CaijCheckbox
                  edge="end"
                  checked={itemsSelected.indexOf(data.id) !== -1}
                  disableRipple
                  onHandleChangeCheckBox={() => handleToggle(data.id)} 
                  onHandleBlurCheckBox={handleLeaveList}
                />
              </ListItemIcon>
              <CaijListItemText name={name} active={active} color="primary"/>
            </Box>
            <Box flexGrow={1}>
              {renderParameterRequired(listType, requireParameterValue, data)}
            </Box>
          </Box>
          { listType === ListType.Product && renderProductDropdowns(data as ProductResource, itemsSelected)}
        </Box>
      </ListItem>
    </Box>
  );

  const renderQtyRequiredListItem = (id: number, name:string, requireParameters: boolean, requireParameterValue: string, data: TypeData) : JSX.Element => (
    <Box key={id}>
        <ListItem key={id} role={undefined} dense >
          <Box width='100%' display='flex' alignItems='center'>
            <Box display='flex' alignItems='center' justifyContent='center' sx={leftlbsCol}>
              <ListItemIcon>
                <CaijCheckbox
                  edge="end"
                  checked={itemsSelected.indexOf(data.id) !== -1}
                  disableRipple
                  inputProps={{ "aria-labelledby": `checkbox-list-label-${id}`}}
                  onHandleChangeCheckBox={() => handleToggle(data.id)} 
                  onHandleBlurCheckBox={handleLeaveList}
                />
              </ListItemIcon>
              <ListItemText primary={name} />
            </Box>
            <Box flexGrow={1}>
              {(itemsSelected.indexOf(data.id) !== -1 && requireParameters) && renderParameterRequired(listType, requireParameterValue, data)}
            </Box>
          </Box>
        </ListItem>
    </Box>
  );
  
  const renderProductGroupAccess = () : JSX.Element[] | JSX.Element => {
    const productGroupAccess = [...(lists as ProductItemResource[])].filter(({access}) => access === AccessPermissions.Private); 
    const rsProductGroupAccess: Result = list.isListValid(productGroupAccess, MSG_EMPTY_LIST);
    if(!rsProductGroupAccess.success){
      return rsProductGroupAccess.message;
    }
    return (
      <>
        <Typography variant='body1' key='selectedAll' sx={{ml:2}}>
          <Link href='#' underline='none' onClick={() => {
            list.selectAll({
              listType,
              selectedAll,
              selectedItems: itemsSelected,
              objData: { productGroupAccess }
            }, (value: number[]) => setItemsSelected(value));
            setSelectedAll(!selectedAll);
          }}>Tout {selectedAll ? 'sélectionner' : 'désélectionner' }</Link>
        </Typography>
        { productGroupAccess.map((product: ProductItemResource) => {
            const id = product.id;
            let requireParameterValue = '';
            const products = listIncluded as ProductItemResource[];
            const index = products && products.findIndex((p:ProductItemResource) => p.id === id)
            if(index && index !== -1){
              const parameter = products[index].parameter;
              requireParameterValue = parameter ? String(parameter) : '';
            }else{
              const parameter = product.parameter;
              requireParameterValue = parameter ? String(parameter) : '';
            }
            return renderListItem(product.active, product.nameFr, requireParameterValue, product);
          })
        }
      </>
    )
  }

  const renderProducts = (): JSX.Element[] | JSX.Element => {
    const { products, isAuthorize, isLoading } = sProduct;
    if(isLoading && !isAuthorize){
      return list.getMessageError(MSG_FORBIDEN_ERROR);
    }
    const rsProducts: Result = isLoading && list.isListValid(products, MSG_EMPTY_LIST);
    if(!rsProducts.success){
      return rsProducts.message;
    }
    return [renderSelectedAll(),...products.map((product: ProductResource) => {
      const id = product.id;
      let requireParameterValue = '';
      const products = lists as ProductItemResource[];
      const index = products.findIndex((p:ProductItemResource) => p.id === id);
      if(index !== -1){
        const parameter = products[index].parameter;
        requireParameterValue = parameter ? String(parameter) : '';
      }
      return renderListItem(product.active, product.nameFr, requireParameterValue, product);
     })];
  };

  const renderLibraryServices = (): JSX.Element[] | JSX.Element => {
    const { libraryServices, isAuthorize, isLoading } = sLibraryService;
    if(isLoading && !isAuthorize){
      return list.getMessageError(MSG_FORBIDEN_ERROR);
    }
    const rsLibraryServices: Result = isLoading && list.isListValid(libraryServices, MSG_EMPTY_LIST);
    if(!rsLibraryServices.success){
      return rsLibraryServices.message;
    }
    
    return [renderSelectedAll(),...libraryServices.map((libraryService: LibraryServiceResource) => {
      const id = libraryService.id;
      let requireParameterValue = '';
      const lbServices = lists as LibraryServiceLibraryOptionsDto[];
      const index = lbServices.findIndex(({service}) => service?.id === id);
      if(index !== -1){
        requireParameterValue = lbServices[index].parameters || '';
      }
      return renderQtyRequiredListItem(id, libraryService.nameFr, libraryService.requireParameters, requireParameterValue, libraryService);
    })];
  };

  const renderSubscriptions = (): JSX.Element[] | JSX.Element => {
    const { subscriptions, isLoading, isAuthorize } = sSubscription;
    if(isLoading && !isAuthorize){
      return list.getMessageError(MSG_FORBIDEN_ERROR);
    }
    const rsSubscriptions: Result = isLoading && list.isListValid(subscriptions, MSG_EMPTY_LIST);
    if(!rsSubscriptions.success){
      return rsSubscriptions.message;
    }
    return [renderSelectedAll(),...subscriptions.map(({id, name, active, groups}, idx: number) => {
      let requireParameterValue = '';
      const sbs = lists as SubscriptionProductResource[];
      const index = sbs.findIndex((sb:SubscriptionProductResource) => sb.id === id);
      if(index !== -1){
        const parameter = sbs[index].parameter;
        requireParameterValue = parameter ? String(parameter) : '';
      }
      return <AccessListItem
                key={idx}
                id={id} 
                name={name} 
                active={active}
                data={{selectedSubscription: itemsSelected, selectedSubscriptionGroupProducts}} 
                groups={groups}
                input={{
                  name: id.toString(),
                  value: requireParameterValue,
                  isRequire: isParametersRequired,
                  maxLength: libraryModel.RequiredParameter.MaxLength,
                  handleLeaveInput: (e, id) => handleLeaveInput(e, id)
                }}
                handleToggle={(l,b) => handleToggle(l,b)}
                handleLeaveList={() => handleLeaveList()}
              />
    })];
  };

  const renderLibraries = () => {
    const { libraries, isLoading, isAuthorize } = sLibrary;
    if(isLoading && !isAuthorize){
      return list.getMessageError(MSG_FORBIDEN_ERROR);
    }
    const rsLibraries: Result = isLoading && list.isListValid(libraries, MSG_EMPTY_LIST);
    if(!rsLibraries.success){
      return rsLibraries.message;
    }
    return [renderSelectedAll(),...libraries.map((library: LibraryResource) => {
      const id = library.id;
      let requireParameterValue = '';
      const libraryServices = lists as LibraryServiceLibraryDto[];
      const index = libraryServices.findIndex(({libraryId}) => libraryId === id);
      if(index !== -1){
        const parameter = libraryServices[index].parameters;
        requireParameterValue = parameter ? String(parameter) : '';
      }
      return renderListItem(library.enabled, library.nameFr, requireParameterValue, library);
    })];
  }
  
  const renderContent = (): JSX.Element[] | JSX.Element => {
    switch(listType){
      case ListType.ProductGroupAccess:
        return renderProductGroupAccess();
      case ListType.Product:
        return renderProducts();
      case ListType.LibraryService: 
        return renderLibraryServices();
      case ListType.LibraryServiceLibrary:
        return renderLibraries();
      case ListType.Subscription:
        return renderSubscriptions();
    }
  };

  return (
    <>
      <CaijCard title={list.renderRequiredParameterTitle(listType)} sx={{mb:4}}>
        <List sx={root}>
         { renderContent() }
        </List>
      </CaijCard>
    </>
  );
};
  
RequiredParameterList.propTypes = {
  setIsListValid: PropTypes.func,
  listIncluded: PropTypes.array,
  listType: PropTypes.oneOf([
      ListType.LibraryService, 
      ListType.Product,
      ListType.Subscription,
      ListType.ProductGroupAccess,
      ListType.LibraryServiceLibrary
      ]),
  lists: PropTypes.array,
  isParametersRequired: PropTypes.bool,
  onHandleItemsSelected: PropTypes.func.isRequired,
  onHandleRequireParmeters: PropTypes.func.isRequired,
};
 
RequiredParameterList.defaultProps = {
  isParametersRequired: false,
  listIncluded: null
};

export default RequiredParameterList;
  