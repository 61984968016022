import { ContentMostViewedModel } from 'src/common';
import * as Yup from 'yup';

const validateSchema = (model: ContentMostViewedModel) => {
  const { Titre, Url, Rank } = model;
  return Yup.object().shape({
    title: Titre.required(true),
    url: Url.required(true),
    rank: Rank.required(true)
  });
};

export default validateSchema;