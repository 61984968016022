import { ContentMostViewedResource } from 'src/common/types';
import * as actionTypes from '../../actions/ActionTypes';

type FetchContentMostViewedsAction = {
    type: 'FETCH_CONTENT_MOST_VIEWEDS';
    payload: {
      contentMostVieweds: ContentMostViewedResource[];
      isLoading?: boolean;
      isEmptyList?: boolean;
    }
};

type FetchContentMostViewedAction = {
  type: 'FETCH_CONTENT_MOST_VIEWED';
  payload: {
    contentMostViewed: ContentMostViewedResource;
    isLoading?: boolean;
  };
};

type FetchDetailsContentMostViewedAction = {
  type: 'FETCH_DETAILS_CONTENT_MOST_VIEWED';
  payload: {
    contentMostViewed: ContentMostViewedResource;
  }
};

type DeleteContentMostViewedAction = {
  type: 'DELETE_CONTENT_MOST_VIEWED';
  payload: {
    id: number;
  }
};

type Action = FetchContentMostViewedsAction |
              FetchContentMostViewedAction |
              FetchDetailsContentMostViewedAction |
              DeleteContentMostViewedAction;

interface ContentMostViewedReturnState {
  contentMostVieweds?: ContentMostViewedResource[];
  contentMostViewed?: ContentMostViewedResource;
  isLoading?: boolean;
  isEmptyList?: boolean;
}

interface ContentMostViewedState {
  contentMostVieweds: ContentMostViewedResource[];
  contentMostViewed: ContentMostViewedResource;
  isLoading: boolean;
  isEmptyList: boolean;
}

const reducer = (state: ContentMostViewedState, action: Action): ContentMostViewedReturnState => {
  switch (action.type) {
    case actionTypes.FETCH_CONTENT_MOST_VIEWEDS: {
      const { contentMostVieweds, isEmptyList, isLoading } = action.payload;
      return {
        ...state,
        contentMostVieweds,
        isLoading,
        isEmptyList
      };
    }
    case actionTypes.FETCH_CONTENT_MOST_VIEWED: {
      const { contentMostViewed, isLoading } = action.payload;
      return {
        ...state,
        contentMostViewed,
        isLoading
      };
    }
    case actionTypes.FETCH_DETAILS_CONTENT_MOST_VIEWED: {
      const { contentMostViewed } = action.payload;
      return {
        ...state,
        contentMostViewed
      };
    }
    case actionTypes.DELETE_CONTENT_MOST_VIEWED: {
      return {
        ...state,
        contentMostVieweds: [...state.contentMostVieweds].filter(({id}) => id !== action.payload.id)
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
