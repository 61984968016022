import React, { useEffect, useReducer } from 'react';
import type { FC } from 'react';
import {
  Box,
  Container
} from '@mui/material';
import {
  useLocation,
  useParams,
  fetchDocCollection,
  _docCollection,
  DocCollectionModel,
  AccessModel,
  useNavigate,
  root
} from 'src/common';
import Page from 'src/components/Page';
import DocCollectionEditForm from './DocCollectionEditForm';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import GenericHeader from 'src/components/header/GenericHeader';

const DocCollectionEditView: FC = () => {
  const model = DocCollectionModel.getInstance();
  const access = new AccessModel(model.ResourceCode);
  const navigate = useNavigate();
  const location = useLocation();
  const [state, dispatch] = useReducer(_docCollection, { docCollection: {}, isLoading: false});
  const { id } = useParams();

  useEffect(() => {
    const initialise = async () : Promise<void> => {
      if(access.canEdit(id)){
        const model = new DocCollectionModel();
        const pathName =  location.pathname;
        if(id && isNaN(+id)){
          navigate(model.Path.Home);
        }else if (id) {
          model.PathName = pathName;
          const docCollection = await model.getDocCollectionById(id);
          if (!model.error) {
            dispatch(fetchDocCollection(docCollection, null, true));
          }
        }else{
          model.PathName = pathName;
          dispatch(fetchDocCollection({ ...docCollection, subscriptions: []}, null, true));
        }
      } 
    };
    initialise();
  }, [id, location]);

  const { docCollection, isLoading } = state;

  if(!isLoading) return <LoadingScreen />;

  model.Title = docCollection.nameFr;

  return (
    <Page
      sx={root}
      title={model.getEditPageTitle(id)}
    >
      <Container maxWidth={false}>
      <GenericHeader id={id} model={model} headerTitle={id ? model.Title : model.BtnAddText} />
        <Box mt={3}>
          <DocCollectionEditForm model={model} collection={docCollection} />
        </Box>
      </Container>
    </Page>
  );
};

export default DocCollectionEditView;
