import React, { useCallback, useState, useEffect, useReducer } from 'react';
import type { FC, ChangeEvent } from 'react';
import type { Theme } from 'src/common/types';
import {
  Box,
  Container,
  Divider,
  Tab,
  Tabs
} from '@mui/material';
import {
  useParams,
  useLocation,
  _contentPublisher,
  AccessModel,
  _document,
  DocumentModel,
  fetchDocument,
  setHash,
  useNavigate,
  _editableDocument,
  root
} from 'src/common';
import Page from 'src/components/Page';
import Details from './Details';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import parse from 'html-react-parser';
import SpecialFilesModel from 'src/model/content/SpecialFiles';
import GenericHeader from 'src/components/header/GenericHeader';

const box = {
  color: (theme: Theme) => theme.palette.text.primary,
  backgroundColor: (theme: Theme) => theme.palette.background.paper,
  padding: '1em',
}
  
const container = {
  backgroundColor: (theme: Theme) => theme.palette.background.paper,
}

interface Tabs {
  value: string, label: string
}

const SpecialFileDetailsView: FC = () => {
  const model = SpecialFilesModel.getInstance();
  const access = new AccessModel(model.ResourceCode);
  const location = useLocation();
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(_document, { document: {} });
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<string>('details');
  const { documentId } = useParams();

  const tabs: Tabs[] = [
    { value: 'details', label: 'Détails' }
  ];

  const handleBrowserRefresh = () => {
    const hash = location.hash;
    if (hash) {
      const removedHashSymbol = hash.substring(1, hash.length);
      const hashVal = removedHashSymbol.split('-');
      const tabVal = hashVal.length === 1 ? hashVal[0] : hashVal[1];
      const index = tabs.findIndex((tab: Tabs) => tab.value === tabVal);
      if (index !== -1) {
        setCurrentTab(tabVal);
        if (removedHashSymbol.indexOf('edit') !== -1) {
          setIsEditing(true);
        }
      } else {
        setHash(tabs[0].value, navigate);
      }
    } else {
      setHash(tabs[0].value, navigate);
    }
  }

  const getDocumentById = useCallback(async (): Promise<void> => {
    if (access.canRead()) {
      const model = new DocumentModel();
      model.PathName = location.pathname;
      const contentPublisher = await model.getDocumentById(documentId);
      if (!model.error) {
        dispatch(fetchDocument(contentPublisher));
      }
    }
  }, [documentId, location]);

  useEffect(() => {
    (async () => {
      handleBrowserRefresh();
      await getDocumentById();
    })();
  }, []);

  useEffect(() => {
    setCurrentTab(location.hash.slice(1) || 'details');
  }, [location.hash])

  const setEditModeDocument = (isEdit: boolean) => {
    setIsEditing(isEdit);
  };

  const switchMode = (isEdit: boolean, tabValue: string) => {
    setHash(tabValue, navigate);
    setEditModeDocument(isEdit);
  };

  const handleTabsChange = (event: ChangeEvent<any>, tabValue: string): void => {
    if (currentTab === "edit-contenu" && !window.confirm("Si vous avez des modifications non enregistrées, elles seront perdues si vous quittez cette page. Êtes-vous sûr de vouloir quitter cette page?")) {
      return;
    }
    setHash(tabValue, navigate);
    setEditModeDocument(false);
  };

  const { document } = state;

  if (Object.keys(document).length === 0) {
    return <LoadingScreen />;
  }

  model.Title = parse(document.title).toString().trim();

  const renderContent = () => {
    switch (currentTab) {
      case 'details':
        return (
          <Details document={document} model={model} />
        );
      default:
        return '';
    }
  };

  return (
    <Page
      sx={root}
      title={model.DetailPageTitle}
    >
      <Container maxWidth={false}>
        <GenericHeader 
          id={documentId} 
          model={model} 
          headerTitle={model.Title} 
          actions={['edit']}
        />
        
        <Box mt={2} sx={container}>
          <Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            value={currentTab}
            variant="scrollable"
            textColor="secondary"
          >
            {tabs.map((tab) => (
              <Tab
                key={tab.value}
                label={tab.label}
                value={tab.value}
              />
            ))}
          </Tabs>
        </Box>
        <Divider />
        <Box sx={box}>
          {renderContent()}
        </Box>
      </Container>
    </Page>
  );
};

export default SpecialFileDetailsView;