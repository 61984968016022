import { useCallback, useEffect, useReducer, useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  ListItem,
  ListItemIcon,
  List,
  Typography,
  Link
} from '@mui/material';
import {
  fetchLibraries, 
  Forbidden,
  getMessageError,
  MSG_EMPTY_LIST,
  MSG_FORBIDEN_ERROR,
  CaijCheckbox,
  LibraryModel,
  _library,
  CaijCard,
  Unauthorized,
  ListInfos,
  ListType
} from 'src/common';
import type {
  DatabankResource
} from 'src/common/types';
import { FETCH_LIBRARIES } from 'src/store/actions/ActionTypes';
import { useLibraryTypes } from 'src/functions/hooks/libraryType';
import CaijListItemText from 'src/components/CaijListItemText';

interface LibrariesProps {
  databank: DatabankResource;
  viewOnly?: boolean;
  onHandleSelectedItems?: (value: number[]) => void;
}

const initialState = {
  libraries: [],
  isLoading: false,
  isEmptyList: true,
  isAuthorize: true
};

function getBackgroundColor(viewOnly: boolean){
  return viewOnly ? true : false;
}

const Libraries: FC<LibrariesProps> = ({
  databank,
  viewOnly,
  onHandleSelectedItems
}) => {
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [state, dispatch] = useReducer(_library, initialState);
  const [selectedAll, setSelectedAll] = useState<boolean>();
  const { libraryTypes, isLoading: libraryTypeIsLoading } = useLibraryTypes();
  const list = ListInfos();

  const getLibraries = useCallback(async (isLoading: boolean) : Promise<void> =>  {
    const model = new LibraryModel();
    model.skipHandleError = true;
    let libraries = await model.getLibraries();
    const { error } = model;
    if (error) {
      if(error.status === Forbidden || error.status === Unauthorized) {
        dispatch(fetchLibraries(libraries, true, false, false));
      }else {
        dispatch(fetchLibraries(libraries, true, true, true));
      }
    }else{ 
      libraries = libraries.filter(({libraryType}) => libraryType.id !== (isLoading && libraryTypes.find(({databanksVisible}) => databanksVisible)?.id));
      dispatch(fetchLibraries(libraries, true, !Array.isArray(libraries) || libraries.length === 0, true));
      setSelectedAll(
        list.compareSelectedLists({
          listType: ListType.Library,
          selectedItems: databank?.libraries?.map(({id}) => id),
          objData: { libraries }
        })
      );
    }
  },[libraryTypes]);
  
  useEffect(() => {
    setSelectedItems(databank?.libraries?.map(({id}) => id));
  },[]);

  useEffect(() => {
    (async () => {
      await getLibraries(libraryTypeIsLoading);
    })();
    return () => { dispatch({type: FETCH_LIBRARIES, payload: initialState}) }
  },[libraryTypeIsLoading]);

  useEffect(() => {
    if(onHandleSelectedItems){
      onHandleSelectedItems(selectedItems);
    }
  },[selectedItems?.length]);

  const { libraries, isEmptyList, isLoading, isAuthorize } = state;

  const handleToggle = (value: number) => {
    const currentIndex = selectedItems.indexOf(value);
    const newChecked = [...selectedItems];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setSelectedItems(newChecked);
    setSelectedAll(
      list.compareSelectedLists({
        listType: ListType.Library,
        selectedItems: newChecked,
        objData: { libraries }
      })
    );
  };
  
  const handleLeaveList = () => {
    onHandleSelectedItems(selectedItems);
  };

  const renderContent = (): JSX.Element[] | JSX.Element => {
    if (isLoading && isEmptyList) {
      return getMessageError(MSG_EMPTY_LIST, true);
    } else if(isLoading && !isAuthorize) {
      return getMessageError(MSG_FORBIDEN_ERROR, true);
    }else if (isLoading && libraries && Array.isArray(libraries)) {
      return libraries.map(({id, nameFr, enabled}) => {
        return (
          <ListItem key={id}>
            <ListItemIcon>
              <CaijCheckbox
                name={nameFr}
                edge="end"
                disabled={viewOnly}
                checked={selectedItems?.indexOf(id) !== -1}
                disableRipple
                onHandleChangeCheckBox={() => handleToggle(id)}
                onHandleBlurCheckBox={handleLeaveList}
              />
            </ListItemIcon>
            <CaijListItemText name={nameFr} active={enabled} color="primary" />
          </ListItem>
        )
      })
    }
  };

  return (
    <CaijCard title="Bibliothèques d'accès" allowedBackgroundColor={getBackgroundColor(viewOnly)}>
        <List component="nav" aria-labelledby="databank">
        { !viewOnly && isLoading && !isEmptyList && isAuthorize && libraries && (
            <ListItem>
              <Typography variant='body1'>
                <Link href='#' underline='none' onClick={() => {
                    list.selectAll({
                      listType: ListType.Library,
                      selectedAll,
                      selectedItems: selectedItems,
                      objData: { libraries: state.libraries }
                    }, (values: number[]) => setSelectedItems(values));
                    setSelectedAll(!selectedAll);
                 }}>Tout {selectedAll ? 'sélectionner' : 'désélectionner'}</Link>
              </Typography>
            </ListItem>
          )
        }
        {renderContent()}
        </List>
    </CaijCard>
  );
};

Libraries.propTypes = {
  databank: PropTypes.object.isRequired,
  viewOnly: PropTypes.bool
};

Libraries.defaultProps = {
  viewOnly: false
};

export default Libraries;
