import React from 'react';
import type { FC, ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import type { ContentMostViewedResource } from 'src/common/types';
import { Grid } from '@mui/material';
import { 
  CaijInput, 
  CaijInputRank,
  FormikErrors, 
  FormikTouched, 
  CaijInputLink, 
  CaijCheckbox,
  ContentMostViewedModel, 
} from 'src/common';

interface ContentMostViewedFormProps {
  model: ContentMostViewedModel;
  contentMostViewed: ContentMostViewedResource;
  errors: FormikErrors<ContentMostViewedResource>;
  touched: FormikTouched<ContentMostViewedResource>;
  onHandleBlur: (e: ChangeEvent<HTMLInputElement>) => void;
  onHandleChange: (event: ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => void;
  onHandleChangeCheckBox: (event: ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const ContentMostViewedForm: FC<ContentMostViewedFormProps> = ({
  model,
  contentMostViewed,
  errors,
  touched,
  onHandleBlur,
  onHandleChange,
  onHandleChangeCheckBox,
  setFieldValue,
}) => {
  const { title, url, secured, rank} = contentMostViewed;
  const { Titre, Url, Rank, Secured } = model;
 
  return (
    <>
      <Grid item md={12} xs={12}>
        <CaijInput
            required
            name={Titre.Name}
            id={Titre.Name}
            value={title}
            helperText={touched.title && errors.title}
            error={Boolean(touched.title && errors.title)}
            label={Titre.Label}
            InputLabelProps={{ shrink: true, required: true }}
            inputAttr={{maxLength: Titre.MaxLength, 'data-testid': 'title'}}
            onHandleBlur={onHandleBlur}
            onHandleChange={onHandleChange}
            setFieldValue={setFieldValue} 
        />
        <CaijInputLink
            name={Url.Name}
            id={Url.Name}
            required
            value={url}
            label={Url.Label}
            helperText={touched.url && errors.url}
            error={Boolean(touched.url && errors.url)}
            InputLabelProps={{ shrink: true}}
            inputProps={{ maxLength: Url.MaxLength, 'data-testid': 'url'}}
            onHandleBlur={onHandleBlur}
            onHandleChange={onHandleChange}
            setFieldValue={setFieldValue}
        />
        <CaijCheckbox
            name={Secured.Name}
            id={Secured.Name}
            checked={secured}
            value={secured}
            label={Secured.Label}
            onHandleChangeCheckBox={onHandleChangeCheckBox}
            inputProps={{'data-testid': 'secured'}}
            setFieldValue={setFieldValue}
            sx={{mb: 2}}
        />
        <CaijInputRank
            name={Rank.Name}
            id={Rank.Name}
            required
            value={String(rank)}
            label={Rank.Label}
            helperText={touched.rank && errors.rank}
            error={Boolean(touched.rank && errors.rank)}
            InputLabelProps={{ shrink: true, required: true }}
            inputProps={{maxLength: Rank.MaxLength, 'data-testid': 'rank'}}
            onHandleBlur={onHandleBlur}
            onHandleChange={onHandleChange}
            setFieldValue={setFieldValue}
        />
      </Grid>
    </>
  );
};

ContentMostViewedForm.propTypes = {
  contentMostViewed: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  onHandleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  onHandleChangeCheckBox: PropTypes.func.isRequired,
  onHandleChange: PropTypes.func.isRequired
};

export default ContentMostViewedForm;
