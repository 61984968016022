import { FC, ChangeEvent, useState } from 'react';
import PropTypes from 'prop-types';
import { CaijInputAddress, FormikErrors, FormikTouched, CaijInput, input, LibraryModel } from 'src/common';
import type { AddressDto, LibraryResource } from 'src/common/types';

interface ContactAddressFormProps {
  model: LibraryModel;
  contactAddress?: AddressDto;
  errors: FormikErrors<LibraryResource>;
  touched: FormikTouched<LibraryResource>;
  handleBlur: (e: ChangeEvent<HTMLInputElement>) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const ContactAddressForm: FC<ContactAddressFormProps> = ({
  model,
  contactAddress,
  errors,
  touched,
  handleBlur,
  setFieldValue
}) => {
  const [line1, setLine1] = useState<string | undefined>(contactAddress.line1 || undefined);
  const [line2, setLine2] = useState<string | undefined>(contactAddress.line2 || undefined);
  const [city, setCity] = useState<string | undefined>(contactAddress.city || undefined);
  const [state, setState] = useState<string | undefined>(contactAddress.state || undefined);
  const [country, setCountry] = useState<string | undefined>(contactAddress.country || undefined);
  const [zip, setZip] = useState<string | undefined>(contactAddress.zip || undefined);
  const { AddressLine1, AddressLine2, AddressCity, AddressState, AddressZip, AddressCountry } = model;
  
  return (
      <>
        <CaijInputAddress
          name="addressFr.line1"
          label={AddressLine1.Label}
          value={line1}
          InputLabelProps={{ shrink: true }}
          sx={input}
          inputProps={{maxLength: AddressLine1.MaxLength, 'data-testid' : 'line1Fr'}}
          error={Boolean(touched?.addressFr?.line1 && errors?.addressFr?.line1)}
          helperText={touched?.addressFr?.line1 && errors?.addressFr?.line1}
          onHandleChange={(e:ChangeEvent<HTMLInputElement>) => setLine1(e.target.value)}
          onHandleBlur={(e:ChangeEvent<HTMLInputElement>) => {
            contactAddress.line1 = line1;
            handleBlur(e);
          }}
          setFieldValue={setFieldValue}
        />
        <CaijInputAddress
          name="addressFr.line2"
          label={AddressLine2.Label}
          value={line2}
          InputLabelProps={{ shrink: true }}
          sx={input}
          error={Boolean(touched?.addressFr?.line2 && errors?.addressFr?.line2)}
          helperText={touched?.addressFr?.line2 && errors?.addressFr?.line2}
          inputProps={{maxLength: AddressLine2.MaxLength, 'data-testid' : 'line2Fr'}}
          onHandleChange={(e:ChangeEvent<HTMLInputElement>) => setLine2(e.target.value)}
          onHandleBlur={(e:ChangeEvent<HTMLInputElement>) => {
            contactAddress.line2 = line2;
            handleBlur(e);
          }}
          setFieldValue={setFieldValue}
        />
        <CaijInput
          name="addressFr.city"
          value={city}
          label={AddressCity.Label}
          sx={input}
          error={Boolean(touched?.addressFr?.city && errors?.addressFr?.city)}
          helperText={touched?.addressFr?.city && errors?.addressFr?.city}
          inputAttr={{maxLength: AddressCity.MaxLength, 'data-testid': 'cityFr', autoComplete: 'none'}}
          InputLabelProps={{ shrink: true }}
          onHandleChange={(e:ChangeEvent<HTMLInputElement>) => setCity(e.target.value)}
          onHandleBlur={(e:ChangeEvent<HTMLInputElement>) => {
            contactAddress.city = city;
            handleBlur(e);
          }}
          setFieldValue={setFieldValue}
        />
        <CaijInput
          name="addressFr.state"
          label={AddressState.Label}
          value={state}
          sx={input}
          error={Boolean(touched?.addressFr?.state && errors?.addressFr?.state)}
          helperText={touched?.addressFr?.state && errors?.addressFr?.state}
          inputAttr={{ maxLength: AddressState.MaxLength, 'data-testid': 'stateFr',autoComplete: 'none'}}
          InputLabelProps={{ shrink: true }}
          onHandleChange={(e:ChangeEvent<HTMLInputElement>) => setState(e.target.value)}
          onHandleBlur={(e:ChangeEvent<HTMLInputElement>) => {
            contactAddress.state = state;
            handleBlur(e);
          }}
          setFieldValue={setFieldValue}
        />
        <CaijInput
          name="addressFr.zip"
          label={AddressZip.Label}
          value={zip}
          sx={input}
          error={Boolean(touched?.addressFr?.zip && errors?.addressFr?.zip)}
          helperText={touched?.addressFr?.zip && errors?.addressFr?.zip}
          inputAttr={{maxLength: AddressZip.MaxLength,'data-testid' : 'zipFr', autoComplete: 'none'}}
          InputLabelProps={{ shrink: true }}
          onHandleChange={(e:ChangeEvent<HTMLInputElement>) => setZip(e.target.value)}
          onHandleBlur={(e:ChangeEvent<HTMLInputElement>) => {
            contactAddress.zip = zip;
            handleBlur(e);
          }}
          setFieldValue={setFieldValue}
        />
        <CaijInput
          name="addressFr.country"
          label={AddressCountry.Label}
          value={country}
          sx={input}
          error={Boolean(touched?.addressFr?.country && errors?.addressFr?.country)}
          helperText={touched?.addressFr?.country && errors?.addressFr?.country}
          inputAttr={{maxLength: AddressCountry.MaxLength, 'data-testid': 'countryFr', autoComplete: 'none' }}
          InputLabelProps={{ shrink: true }}
          onHandleChange={(e:ChangeEvent<HTMLInputElement>) => setCountry(e.target.value)}
          onHandleBlur={(e:ChangeEvent<HTMLInputElement>) => {
            contactAddress.country = country;
            handleBlur(e);
          }}
          setFieldValue={setFieldValue}
        />
      </>
    );
  }
  
ContactAddressForm.propTypes = {
  contactAddress: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired
};

export default ContactAddressForm;
