import React, { useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { TableBody } from '@mui/material';
import {
  ITEMS_PER_PAGE,
  EmptyList,
  EnhancedTableHead,
  PaginationModel,
  ContentPublishersModel
} from 'src/common';
import type { ContentPublishersResource } from 'src/common/types';
import CaijTable from 'src/components/table/CaijTable';
import MyCard from 'src/components/card/MyCard';
import CaijDialogs from 'src/components/dialog';
import CaijTablePagination from 'src/components/pagination/CaijTablePagination';
import { CaijTableCell } from 'src/components/table/CaijTableCell';
import CaijTableCellAction from 'src/components/table/CaijTableCellAction';
import { CaijTableRowClickable } from 'src/components/table/CaijTableRowClickable';
import useSort from 'src/functions/hooks/sort';

export interface ResultsProps {
  model: ContentPublishersModel;
  contentPublishers: ContentPublishersResource[];
  onDeleteContentPublisher: (code: string) => Promise<void>;
  isEmptyList: boolean;
}

const Results: FC<ResultsProps> = ({
  model,
  contentPublishers,
  onDeleteContentPublisher,
  isEmptyList
}) => {
  const pagination = new PaginationModel();
  const path = model.Path;
  const [page, setPage] = useState<number>(0);
  const [selectedRow, setSelectedRow] = useState([]);
  const { order, orderBy, sort, handleRequestSort } = useSort();

  return isEmptyList ? <EmptyList /> : (
    <MyCard>
      <CaijTable>
        <EnhancedTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          headCells={model.getHeadCells()}
        />
        <TableBody>
          { sort<ContentPublishersResource>(contentPublishers ,page).map((row: ContentPublishersResource, index: number) => (
              <CaijTableRowClickable key={index} path={path.getDetail(row.code,'/wc')}>
                <CaijTableCell>{row.code}</CaijTableCell>
                <CaijTableCell>{row.nameFr}</CaijTableCell>
                <CaijTableCell>{row.nameEn}</CaijTableCell>
                <CaijTableCellAction 
                  pageEditUrl={path.getEdit(row.code)}
                  resourceCode={model.ResourceCode}
                  handleToggle={() => ContentPublishersModel.handleToggle(row.code, selectedRow, setSelectedRow)}
                >
                  <CaijDialogs
                    dialog={model.Dialog}
                    isOpen={selectedRow.indexOf(row.code) !== -1}
                    onSubmit={async () => {
                        await onDeleteContentPublisher(row.code);
                        setSelectedRow([]);
                    }}
                    setSelectedRow={setSelectedRow}
                  />
                </CaijTableCellAction>
              </CaijTableRowClickable>
            ))}
        </TableBody>
      </CaijTable>
      <CaijTablePagination
        showTablePaginationLight 
        len={contentPublishers.length}
        page={page}
        setPage={(l) => setPage(l)}
      />
    </MyCard>
  );
};

Results.propTypes = {
  contentPublishers: PropTypes.array.isRequired,
  onDeleteContentPublisher: PropTypes.func.isRequired,
  isEmptyList: PropTypes.bool.isRequired
};

export default Results;
