import { useEffect, useReducer } from "react";
import { 
    ContentPublishersModel, 
    DocumentTypeModel, 
    DocCollectionModel, 
    fetchDocumentTypes, 
    fetchDocCollections, 
    Forbidden, 
    Unauthorized, 
    _contentPublisher, 
    _documentType, 
    _docCollection,
    fetchContentPublishers
} from "src/common";
import { 
  DocumentCollectionResource, 
  ContentPublishersResource, 
  DocumentTypeResource 
} from 'src/common/types';
import { FETCH_CONTENT_PUBLISHERS, FETCH_DOCUMENT_TYPES, FETCH_DOCUMENT_COLLECTIONS } from "src/store/actions/ActionTypes";

interface Data {
  isEmptyList: boolean;
  isLoading: boolean;
  isAuthorize: boolean;
}

export interface Collection extends Data {
  docCollections: DocumentCollectionResource[];
}

interface ContentPublisher extends Data {
  contentPublishers: ContentPublishersResource[];
}

interface DocumentType extends Data {
  documentTypes: DocumentTypeResource[];
}

const initialStateBase = {
  isEmptyList: true,
  isLoading: false,
  isAuthorize: false,
};

export function useCollection() : Collection {
  const initialState: Collection = {
    ...initialStateBase,
    docCollections: []
  };
  const [state, dispatch] = useReducer(_docCollection, initialState);
  
  useEffect(() => {
    const initialise = async () => {
      const model = new DocCollectionModel();
      model.skipHandleError = true;
      const collections = await model.getDocCollections();
      const { error } = model;
      if (error) {
        if (error.status === Forbidden || error.status === Unauthorized) {
          dispatch(fetchDocCollections(collections, true, false, false));
        }else{
          dispatch(fetchDocCollections(collections, true, true, true));
        }
      } else {
        dispatch(fetchDocCollections(collections, true, !Array.isArray(collections) || collections.length === 0, true));  
      }
    }
    (async () => {
      await initialise();
    })();
    return () => { dispatch({ type: FETCH_DOCUMENT_COLLECTIONS, payload: initialState}); }
  },[]);
  const { docCollections, isEmptyList, isLoading, isAuthorize } = state;
  return { docCollections, isEmptyList, isLoading, isAuthorize }
};

export function useContentPublisher() : ContentPublisher{
  const initialState: ContentPublisher = {
    ...initialStateBase,
    contentPublishers: []
  };
  const [state, dispatch] = useReducer(_contentPublisher, initialState);

  useEffect(() => {
    const initialise = async () => {
      const model = new ContentPublishersModel();
      model.skipHandleError = true;
      const contentPublishers = await model.getContentPublishers();
      const { error } = model;
      if (error) {
        if (error.status === Forbidden || error.status === Unauthorized) {
          dispatch(fetchContentPublishers(contentPublishers, true, false, false));
        }else{
          dispatch(fetchContentPublishers(contentPublishers, true, true, true));
        }
      } else {
        dispatch(fetchContentPublishers(contentPublishers, true, !Array.isArray(contentPublishers) || contentPublishers.length === 0, true));  
      }
    }
    (async () => {
      await initialise();
    })();
    return () => { dispatch({type: FETCH_CONTENT_PUBLISHERS, payload: initialState}); }
  },[]);
  const { contentPublishers, isEmptyList, isLoading, isAuthorize } = state;
  return { contentPublishers,isEmptyList,isLoading,isAuthorize }
};

export function useDocumentType() : DocumentType {
  const initialState: DocumentType = {
    ...initialStateBase,
    documentTypes: []
  };
  const [state, dispatch] = useReducer(_documentType, initialState);

  useEffect(() => {
    const initialise = async () => {
      const model = new DocumentTypeModel();
      const documentTypes = await model.getDocumentTypes();
      if (!model.error) {
        dispatch(fetchDocumentTypes(documentTypes, true, !Array.isArray(documentTypes) || documentTypes.length === 0, true));  
      }
    }
    (async () => {
      await initialise();
    })();
    return () => { dispatch({type: FETCH_DOCUMENT_TYPES, payload: initialState}); }
  },[]);
  const { documentTypes, isEmptyList, isLoading, isAuthorize } = state;
  return { documentTypes, isEmptyList, isLoading, isAuthorize }
};

export function usePublication() {
  return {
    sCollection: useCollection(),
    sContentPublisher: useContentPublisher(),
    sDocumentType: useDocumentType()
  }
}