import { SubscriptionGroupProductsResource, SubscriptionResource } from "src/common/types";

interface ToogleSelectedItems {
  selectedItems?: number[];
  selectedSubscriptionGroupProducts?: SubscriptionGroupProductsResource[];
};

interface SelectedGroups extends ToogleSelectedItems {
  selectedGroups?: number[];
};

export const toogleSelectedItems = (value: number, toogleSelectedItems: ToogleSelectedItems) => {
  let currentIndex: number;
  if(toogleSelectedItems.selectedItems){
    const items = [...toogleSelectedItems.selectedItems];
    currentIndex = items.indexOf(value);
    currentIndex === -1 ? items.push(value) : items.splice(currentIndex, 1);
    return items;
  }else if(toogleSelectedItems.selectedSubscriptionGroupProducts){
    const items = [...toogleSelectedItems.selectedSubscriptionGroupProducts];
    const currentIndex = items.findIndex(({groupId}) => groupId === value);
    currentIndex === -1 ? items.push({groupId: value, parameter: null}) : items.splice(currentIndex, 1);
    return items;
  }
}

export async function selectAllGroups(subscriptions: SubscriptionResource[], selectedGroups: SelectedGroups){
  if(selectedGroups.selectedSubscriptionGroupProducts ){
    const items = [...selectedGroups.selectedSubscriptionGroupProducts];
    for await (let subscription of subscriptions){
      for(let group of subscription.groups)
        items.findIndex(({groupId}) => groupId === group.id) === -1 ? items.push({groupId: group.id}) : '';
    }
    return items;
  }else if(selectedGroups.selectedGroups){
    const items = [...selectedGroups.selectedGroups];
    for await (let subscription of subscriptions){
      for(let group of subscription.groups)
        items.includes ? items.push(group.id) : '';
    }
    return items;
  }
}