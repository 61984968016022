import PropTypes from 'prop-types';
import { FC, ReactNode } from 'react';
import { createContext } from 'react';
import { ContentPublishersModel } from 'src/common';
import { ContentPublishersResource } from 'src/common/types';

export interface ContentPublisherContextValue {
  verifyContentPublisherCode: (value:string, currentCode: string) => Promise<boolean>
}

interface ContentPublisherProviderProps {
  children: ReactNode;
}

const ContentPublisherContext = createContext<ContentPublisherContextValue>({
  verifyContentPublisherCode : (value:string, currentCode: string) => new Promise(resolve => resolve(true))
});

export const ContentPublisherProvider: FC<ContentPublisherProviderProps> = ({ children }) => {
  
  const verifyContentPublisherCode = async (value: string, currentCode: string) : Promise<boolean> => {
    const model = new ContentPublishersModel();
    const contentPublishers = await model.getContentPublishers();
    const index = contentPublishers.findIndex((cp: ContentPublishersResource) => cp.code.toLowerCase().trim() === value.toLowerCase().trim() && cp.code !== currentCode);
      if (index >= 0) {
        return false;
      }
      return true;
  }

  return (
    <ContentPublisherContext.Provider
      value={{
        verifyContentPublisherCode
      }}
    >
      {children}
    </ContentPublisherContext.Provider>
  );
};

ContentPublisherProvider.propTypes = {
  children: PropTypes.any.isRequired
};

export default ContentPublisherContext;
