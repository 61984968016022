import React, { useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  TableBody,
  Link
} from '@mui/material';
import {
  labelConfig,
  EmptyList,
  EnhancedTableHead,
  link,
  ContentMostViewedModel
} from 'src/common';
import type { ContentMostViewedResource } from 'src/common/types';
import MyCard from 'src/components/card/MyCard';
import CaijTable from 'src/components/table/CaijTable';
import CaijDialogs from 'src/components/dialog';
import CaijTablePagination from 'src/components/pagination/CaijTablePagination';
import { CaijTableCell } from 'src/components/table/CaijTableCell';
import CaijTableCellAction from 'src/components/table/CaijTableCellAction';
import { CaijTableRowClickable } from 'src/components/table/CaijTableRowClickable';
import useSort from 'src/functions/hooks/sort';

export interface ResultsProps {
  model: ContentMostViewedModel;
  contentMostVieweds: ContentMostViewedResource[];
  isEmptyList: boolean;
  onDeleteContentMostViewed: (id: number) => Promise<void>;
}

const Results: FC<ResultsProps> = ({
  model,
  contentMostVieweds,
  isEmptyList,
  onDeleteContentMostViewed
}) => {
  const [page, setPage] = useState<number>(0);
  const [selectedRow, setSelectedRow] = useState([]);
  const path = model.Path;
  const { order, orderBy, sort, handleRequestSort } = useSort();

  return isEmptyList ? <EmptyList /> : (
    <MyCard>
      <CaijTable>
        <EnhancedTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          headCells={model.getHeadCells()}
        />
        <TableBody>
          { sort<ContentMostViewedResource>(contentMostVieweds, page).map((row: ContentMostViewedResource) => (
              <CaijTableRowClickable key={row.id} path={path.getDetail(row.id)}>
                <CaijTableCell>{row.title}</CaijTableCell>
                <CaijTableCell>
                  <Link
                    component="button"
                    underline="none"
                    onClick={(e) => {
                      e.stopPropagation();
                      window.open(row.url, '_blank');
                    }}
                    sx={{...link,color:'text.secondary' }}
                  >
                    {row.url}
                  </Link>
                </CaijTableCell>
                <CaijTableCell allowedLabel txtActive={labelConfig.yes} txtInActive={labelConfig.no} active={row.secured} />
                <CaijTableCell>{row.rank}</CaijTableCell>
                <CaijTableCellAction 
                  pageEditUrl={path.getEdit(row.id)}
                  resourceCode={model.ResourceCode}
                  handleToggle={() => ContentMostViewedModel.handleToggle(row.id, selectedRow, setSelectedRow)}
                >
                  <CaijDialogs
                    dialog={model.Dialog}
                    isOpen={selectedRow.indexOf(row.id) !== -1}
                    onSubmit={async () => {
                      await onDeleteContentMostViewed(row.id);
                      setSelectedRow([]);
                    }}
                    setSelectedRow={setSelectedRow}
                  />
                </CaijTableCellAction>
              </CaijTableRowClickable>
            ))}
        </TableBody>
      </CaijTable>
      <CaijTablePagination
        showTablePaginationLight 
        len={contentMostVieweds.length}
        page={page}
        setPage={(l) => setPage(l)}
      />
    </MyCard>
  );
};

Results.propTypes = {
  contentMostVieweds: PropTypes.array,
  isEmptyList: PropTypes.bool,
  onDeleteContentMostViewed: PropTypes.func.isRequired
};

export default Results;
