import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import type { 
  ContentPublishersResource, 
  DocumentTypeResource, 
  DocumentCollectionResource, 
  DocumentResource, 
  LawResource, 
  TribunalResource 
} from 'src/common/types';
import {
  Box,
  SvgIcon,
} from '@mui/material';
import {DocumentModel, jurisdictionConfig} from 'src/common';
import { FileText as FileTextIcon } from 'react-feather';
import CaijTypography from 'src/components/typography';
import { useDocument } from 'src/functions/hooks/document';
import CaijLabel from 'src/components/label/CaijLabel';

export interface ContentInfoProps {
  document: DocumentResource;
  model: DocumentModel;
}

type Params = DocumentCollectionResource[] 
| DocumentTypeResource[] 
| ContentPublishersResource[];

export const getNameFr = (items: Params, codeVal: string) => {
  const index = items.findIndex(({code}) => code === codeVal);
  if(index !== -1) return items[index].nameFr;
  return "N/A";
}

const getTribunalName = (tribunals: TribunalResource[], code: string) => {
  const index = tribunals.findIndex((tribunal: TribunalResource) => tribunal.codeFr === code);
  if(index !== -1) return tribunals[index].labelFr;
  return "N/A";
}

const getLawTitle = (laws : LawResource[], id: number ) => {
  const index = laws.findIndex((law: LawResource) => law.id === id);
  if(index !== -1) return laws[index].titleFr;
  return "N/A";
}

const getJuridiction = (juridiction: string) => {
  const key = jurisdictionConfig && Object.keys(jurisdictionConfig).find((key) => key === juridiction);
  if(key) return jurisdictionConfig[key]
  return "N/A";
};

const ContentInfo: FC<ContentInfoProps> = ({model, document}) => {
  const { sCollection, sDocumentType, sLaw, sTribunal, sContentPublisher } = useDocument();
  const { collection, lawId, publisher, tribunal, juridiction, documentType } = document;
  return (
    <Box sx={{mb:'2em'}} display='flex'>
      <SvgIcon fontSize='small'>
        <FileTextIcon />
      </SvgIcon>
      <Box display='flex' marginLeft='0.5em' flexDirection='column'>
        <Box display='flex' alignItems='center'>
          <CaijTypography sx={{ fontWeight: 'bold' }}>Contenus</CaijTypography>
        </Box>
        { collection && sCollection.isLoading && <CaijLabel label={model.Collection.Label}>{getNameFr(sCollection.docCollections, collection)}</CaijLabel> }
        { lawId && sLaw.isLoading && <CaijLabel label={model.LawId.Label}>{getLawTitle(sLaw.laws,lawId)}</CaijLabel> }
        { documentType && sDocumentType.isLoading && <CaijLabel label={model.DocumentType.Label}>{getNameFr(sDocumentType.documentTypes,documentType)}</CaijLabel> }
        { publisher && sContentPublisher.isLoading && <CaijLabel label={model.Publisher.Label}>{getNameFr(sContentPublisher.contentPublishers,publisher)}</CaijLabel> }
        { tribunal && sTribunal.isLoading && <CaijLabel label={model.Tribunal.Label}>{getTribunalName(sTribunal.tribunaux,tribunal)}</CaijLabel> }
        { juridiction && <CaijLabel label={model.Juridiction.Label}>{getJuridiction(juridiction)}</CaijLabel> }
      </Box>
    </Box>
  );
};

ContentInfo.propTypes = {
  document: PropTypes.object.isRequired
};

export default ContentInfo;