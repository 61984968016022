import {
	Success,
	NoContent,
	MSG_NO_CONTENT_ERROR,
	Created,
	AccessType
} from 'src/common';
import type {
	Error,
	ReperageResource,
	Sort,
	UserContentItemsResource,
	ReperageResourceForCreate,
	CreateResponse,
	ReperageResourceForEdit,
	CustomerResource,
	GetReperagesAsyncOptionalParams,
	RequestedDocumentFile,
	UserContentItemsResourceForEdit
} from 'src/common/types';
import AppModel from './App';
import axios from 'src/utils/axios';
import { FormModel } from './Form';
import { $enum } from 'ts-enum-util';
import { PagedList } from 'src/types/pagination';
import { AxiosResponse } from 'axios';
import printMessage from 'src/views/errors/MessageError';

type TypeMap = Map<number, string>;

export interface ProductSubscription {
	subscriptionId: number[];
};

export default class ReperageModel extends AppModel {
	private _selectedSubscriptionParameters: TypeMap;
	private static _instance: ReperageModel;

	readonly Name = new FormModel('name', 'Nom du dossier', 255);
	readonly ClientLabel = new FormModel('clientLabel', 'Nom du membre', 255);
	readonly ClientId = new FormModel('clientId', 'ID CAIJ', 255);
	readonly Courriel = new FormModel('courriel', 'Courriel', 255);
	readonly Infos = new FormModel('infos', 'Informations relatives à la demande (interne)');
	readonly Reason = new FormModel('reason', "Reason");
	readonly ParametersRequired = new FormModel('parametersRequired', 'Paramètre requis');
	readonly Note = new FormModel('note', 'Message pour client');
	readonly Firstname = new FormModel('firstname', 'Prénom', 255);
	readonly Lastname = new FormModel('lastname', 'Nom', 255);
	readonly CarteCaij = new FormModel('card', '# Carte Caij', 255);
	readonly SearchCourriel = new FormModel('email', 'Courriel', 255)
	readonly NoBarreau = new FormModel('extIdentifier', 'NoBarreau', 255);
	readonly ContentItemName = new FormModel('contentItemName', 'Nom', 255)

	infos?: string;
	reason?: string;
	id?: number;
	note?: string;

	constructor() {
		super('/reperage');
		this.initialize();
	}

	private initialize() {
		this._resourceCode = 'REPERAGE';
		this._headerTitle = 'Liste des repérages documentaires';
		this._btnAddText = 'Nouvelle demande';
		this.Path.PathName = '/documentary-tracking';
	}

	get Section() {
		return {
			resourceCode: this._resourceCode,
			title: 'Repérages documentaires',
			href: this.Path.Home,
			visible: true
		}
	}

	set SelectedSubscriptionParameters(selectedSubscriptionParameters: TypeMap) {
		this._selectedSubscriptionParameters = selectedSubscriptionParameters;
	}

	get SelectedSubscriptionParameters(): TypeMap {
		return this._selectedSubscriptionParameters;
	}

	get Types(){
		return 'Type(s) de repérages';
	}
	
	static getAccessTypeByKey = (key: string) => {
		return $enum(AccessType).getValueOrDefault(key);
	}

	getHeadCells(status: string) {
		return [
			{
				id: 'folderId', width: '5%', numeric: false, disablePadding: false, label: '#Répérage'
			},
			{
				id: this.Name.Name,  width: '20%', numeric: false, disablePadding: false, label: this.Name.Label
			},
			{
				id: 'createdByLabel', width: '20%', numeric: false, disablePadding: false, label: 'Créé par'
			},
			{
				id: this.ClientLabel.Name, width: '20%', numeric: false, disablePadding: false, label: this.ClientLabel.Label
			},
			{
				id: 'dateCreated', width: '10%', numeric: false, disablePadding: false, label: 'Créé'
			},
			{
				id: 'dateModified', width: '10%', numeric: false, disablePadding: false, label: 'Modifié'
			},
			{
				id: '', width: '10%', numeric: false, disablePadding: false, label: status
			},
			{
				id: '', width: '5%', align: 'right' as 'right'
			}
		];
	}

	getProfilHeadCells(status: string){
		let headCells = this.getHeadCells(status).filter(({id}) => id !== this.ClientLabel.Name);
		headCells[0].id = "id";
		return headCells;
	}
	
	static getInstance(): ReperageModel {
		if (!ReperageModel._instance) {
			ReperageModel._instance = new ReperageModel();
		}
		return ReperageModel._instance;
	}


	static getInitialValues(values: ReperageResource, customer?: CustomerResource): ReperageResource {
		return {
			...values,
			id: values.id,
			infos: values.infos || '',
			userId: values.userId || null,
			clientId: customer?.noCaij || values.clientId || '',
			clientLabel: customer?.fullname || values.clientLabel || '',
			courriel: customer?.email || values.courriel || '',
			createdByLabel: values.createdByLabel || '',
			status: values.status || '',
			reason: values.reason || null,
			types: values.types || [],
			name: values.name || '',
			note: values.note || '',
			itemsCount: values.itemsCount || 0,
			alertItemsCountUnread: values.alertItemsCountUnread || 0,
			archived: values.archived,
			contentItems: values.contentItems || [],
		}
	}

	async getReperages(params: GetReperagesAsyncOptionalParams): Promise<PagedList<ReperageResource>> {
		let pagedList: PagedList<ReperageResource>;
		await axios.get<PagedList<ReperageResource>>(this.route, { params }).then(
			async response => {
				const { status, data } = response;
				if (status === Success) {
					pagedList = data;
				}
			},
			async error => {
				this.error = error;
				if (!this.skipHandleError) {
					await this.handleError(this.error);
				}
			},
		);
		return pagedList;
	}

	async getReperageById(id: string | number): Promise<ReperageResource> {
		let reperage: any;
		await axios.get<any>(`${this.route}/${+id}`).then(async (response) => {
			const { status, data } = response;
			try {
				if (status === Success) {
					reperage = data;
				} else if (status === NoContent) {
					throw new Error(MSG_NO_CONTENT_ERROR);
				}
			} catch (ex) {
				this.error = { status, message: ex.message };
				await this.handleError(this.error);
			}
		},
			async (error) => {
				this.error = error;
				await this.handleError(this.error);
			});
		return reperage;
	}

	async insertReperage(submitData: ReperageResourceForCreate): Promise<CreateResponse> {
		let result: CreateResponse;
		await axios.post<ReperageResourceForCreate, CreateResponse>(this.route, submitData).then(
			response => {
				const { status } = response;
				if (status === Created) {
					result = response;
					result.message = 'Repérage documentaire créé.';
				}
			},
			async error => {
				this.error = error;
				await this.handleError(this.error);
			},
		);
		return result;
	}

	async updateReperage(submitData: ReperageResourceForEdit): Promise<Error> {
		let result: Error;

		await axios.put<ReperageResourceForEdit, Error>(`${this.route}/${submitData.id}`, submitData).then(async (response) => {
			const { status } = response;
			try {
				if (status === Success) {
					result = response;
					result.message = 'Repérage modifié.';
				} else if (status === NoContent) {
					throw new Error(MSG_NO_CONTENT_ERROR);
				}
			} catch (ex) {
				this.error = { status, message: ex.message };
				await this.handleError(this.error);
			}
		}, async (error) => {
			this.error = error;
			await this.handleError(this.error);
		});
		return result;
	}

	async deleteReperage(id: number, allowedRedirect: boolean = false): Promise<boolean> {
		let success: boolean = false;
		await axios.delete<any, Error>(`${this.route}/${id}`).then(
			response => {
				const { status } = response;
				if (status === Success) {
					printMessage({
						status,
						message: 'Repérage supprimé.'
					})
					if (allowedRedirect)
						this.redirect(this.Path.Home);
					success = true;
				}
			},
			async error => {
				this.error = error;
				await this.handleError(this.error);
			},
		);
		return success;
	}

	async getRefreshReperage(id: string): Promise<any> {
		let newReperage: any;
		const reperage = await this.getReperageById(id);
		if (!this.error) {
			newReperage = reperage;
		}
		return newReperage;
	}

	async sendReperage(submitData: ReperageResourceForEdit): Promise<Error> {
		let result: Error;
		await axios.post<any, Error>(`${this.route}/${submitData.id}/send`, submitData).then(
			response => {
				const { status } = response;
				if (status === Success) {
					result = response;
					result.message = 'Repérage envoyé au client.';
				}
			},
			async error => {
				this.error = error;
				await this.handleError(this.error);
			},
		);

		return result;
	}

	async copyReperage(id: string): Promise<Error> {
		let result: Error;
		await axios.post<any, Error>(`${this.route}/${id}/copy`).then(
			response => {
				const { status } = response;
				if (status === Success) {
					result = response;
					result.message = 'Repérage copier.';
				}
			},
			async error => {
				this.error = error;
				await this.handleError(this.error);
			},
		);
		return result;
	}

	async addDocumentFile(id: string, file: FormData): Promise<any> {
		let result: Error
		await axios.post<any, AxiosResponse<string>>(`${this.route}/${id}/file`, file, {
			headers: { "Content-Type": "multipart/form-data" },
		}).then(
			response => {
				const { status } = response;
				if (status === Success) {
					result = response;
					result.message = 'Fichier sauvegardé.';
				}
			},
			async error => {
				this.error = error;
				await this.handleError(this.error);
			},
		);
		return result;
	}

	async deleteContentItem(item: UserContentItemsResource): Promise<any> {
		let result: Error;
		await axios.delete<any, AxiosResponse<string>>(`${this.route}/${item.folderId}/deleteContentItem/${item.id}`).then(
			response => {
				const { status } = response;
				if (status === Success) {
					result = response;
					result.message = 'Contenu supprimé.';
				}
			},
			async error => {
				this.error = error;
				await this.handleError(this.error);
			},
		);
		return result;
	}

	async editContentItem(id: string, item: UserContentItemsResourceForEdit): Promise<Error> {
		let result: Error;
		await axios.put<UserContentItemsResourceForEdit, AxiosResponse<string>>(`${this.route}/${id}/file`, item).then(
			response => {
				const { status } = response;
				if (status === Success) {
					result = response;
					result.message = 'Nom modifié.';
				}
			},
			async error => {
				this.error = error;
				await this.handleError(this.error);
			},
		);
		return result;
	}
	
	
	async getDocumentFile(item: UserContentItemsResource): Promise<RequestedDocumentFile> {
		let result: RequestedDocumentFile;
		try {
			const response = await axios.get<any, AxiosResponse<RequestedDocumentFile>>(`${this.route}/${item.folderId}/document-file/${item.id}`);
			result = response.data;
		} catch(err) {
			await this.handleError(err);
		}
		return result;
	}
}

