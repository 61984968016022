import {
  Success,
  NoContent,
  MSG_NO_CONTENT_ERROR,
  Created
} from 'src/common';
import type {
  Error,
  ContentPublishersResourceForCreate,
  ContentPublishersResourceForEdit,
  ContentPublishersResource,
  CreateResponse
} from 'src/common/types';
import AppModel from '../App';
import axios from 'src/utils/axios';
import { BtnOk } from 'src/components/dialog/CaijDialog';
import { FormModel } from '../Form';
import printMessage from 'src/views/errors/MessageError';

type TypeResourceForEdit = ContentPublishersResourceForCreate | ContentPublishersResourceForEdit;

export default class ContentPublishersModel extends AppModel 
{
  private static _instance: ContentPublishersModel;
  readonly Code = new FormModel('code', 'Code', 50)
  readonly NameFr = new FormModel('nameFr','Nom',250);
  readonly NameEn = new FormModel('nameEn','Name',250);
  readonly HomeUrlFr = new FormModel('homeUrlFr','Lien Url', 500);
  readonly HomeUrlEn = new FormModel('homeUrlEn','Url Link', 500);
  readonly ContentUrlFr = new FormModel('contentUrlFr','Contenu Url',500);
  readonly ContentUrlEn = new FormModel('contentUrlEn','Url Content',500);
  readonly LogoUrlFr = new FormModel('logoUrlFr','Logo',1000);
  readonly LogoUrlEn = new FormModel('logoUrlEn','Logo',1000);
  
  constructor(){
    super('/content/publishers');
    this.initialize();
  }
  
  private initialize(){
    this._resourceCode = 'CONTENT_PUBLISHERS';
    this._headerTitle = 'Liste des éditeurs de contenu';
    this._btnAddText= 'Ajouter un éditeur de contenu';
    this.Path.PathName = '/contents/publishers';

    //Dialog
    this.Dialog.Header = "Supprimer l'éditeur";
    this.Dialog.BtnText = BtnOk.DELETED;
    this.Dialog.Name = "l'éditeur";
  }

  get Section(){
    return {
      resourceCode: this._resourceCode, 
      title: 'Éditeurs de contenu',
      href: this.Path.Home,
      visible: true
    }
  }

  getHeadCells(){
    return [
      {
        id: this.Code.Name, width: '15%', numeric: false, disablePadding: false, label: this.Code.Label
      },
      {
        id: this.NameFr.Name, width: '37%', numeric: false, disablePadding: false, label: this.NameFr.Label
      },
      {
        id: this.NameEn.Name, width: '38%', numeric: false, disablePadding: false, label: this.NameEn.Label
      },
      {
        id: '', width: '0%'
      }
    ];
  }

  static getInstance(): ContentPublishersModel {
    if (!ContentPublishersModel._instance) {
      ContentPublishersModel._instance = new ContentPublishersModel();
    }
    return ContentPublishersModel._instance;
  }

  async getContentPublishers(): Promise<ContentPublishersResource[]> {
    let contentPublishers: ContentPublishersResource[];
    await axios.get<ContentPublishersResource[]>(this.route).then(async (response) => {
      const { status, data } = response;
      try {
        if (status === Success) {
          contentPublishers = data;
        } else if (status === NoContent) {
          throw new Error(MSG_NO_CONTENT_ERROR);
        }
      } catch (ex) {
        this.error = { status, message: ex.message };
        await this.handleError(this.error);
      }
    },
    async (error) => {
      this.error = error;
      if(!this.skipHandleError){
        await this.handleError(this.error);
      }
    });
    return contentPublishers;
  }

  async getContentPublisherByCode(code: string): Promise<ContentPublishersResource> {
    let contentPublisher: ContentPublishersResource;
    await axios.get<ContentPublishersResource>(`${this.route}/${code}`).then(async (response) => {
      const { status, data } = response;
      try {
        if (status === Success) {
          contentPublisher = data;
        } else if (status === NoContent) {
          throw new Error(MSG_NO_CONTENT_ERROR);
        }
      } catch (ex) {
        this.error = { status, message: ex.message };
        await this.handleError(this.error);
      }
    },
    async (error) => {
      this.error = error;
      await this.handleError(this.error);
    });
    return contentPublisher;
  }

  async updateContentPublisher(submitData: TypeResourceForEdit): Promise<Error> {
    let result: Error;
    await axios.put<ContentPublishersResourceForEdit, Error>(`${this.route}/${submitData.code}`, submitData).then(async (response) => {
      const { status } = response;
      try {
        if (status === Success) {
          result = response;
          result.message = 'Éditeur de contenu modifié.';
        } else if (status === NoContent) {
          throw new Error(MSG_NO_CONTENT_ERROR);
        }
      } catch (ex) {
        this.error = { status, message: ex.message };
        await this.handleError(this.error);
      }
    }, async (error) => {
      this.error = error;
      await this.handleError(this.error);
    });
    return result;
  }

  async insertContentPublisher(submitData: TypeResourceForEdit): Promise<CreateResponse> {
    let result: CreateResponse;
    await axios.post<ContentPublishersResourceForCreate, CreateResponse>(this.route, submitData).then((response) => {
      const { status } = response;
      if (status === Created) {
        result = response;
        result.message = 'Éditeur de contenu créé.';
      }
    }, async (error) => {
      this.error = error;
      await this.handleError(this.error);
    });
    return result;
  }

  async delete(code: string|number, allowedRedirect: boolean = false): Promise<boolean> {
    let success: boolean = false;
    await axios.delete<any, Error>(`${this.route}/${code}`).then((response) => {
      const { status } = response;
      if (status === Success) {
        printMessage({
          status: status, 
          message: 'Éditeur de contenu supprimé.'
        });
        if(allowedRedirect)
          this.redirect(this.Path.Home);
        success = true;
      }
    }, async (error) => {
      this.error = error;
      await this.handleError(this.error);
    });
    return success;
  }
}
