import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import type {
  ContentPublicationsResourceForEdit,
  ContentPublicationsResource
} from 'src/common/types';
import { Box } from '@mui/material';
import {
  Formik,
  handleChange,
  handleChangeImageUrl,
  Mode,
  CaijButtonSubmit,
  CaijButtonReset,
  btnSubmit,
  handleChangeDateChange
} from 'src/common';
import ContentPublicationsModel from 'src/model/content/ContentPublications';
import printMessage from 'src/views/errors/MessageError';
import Authorize from 'src/components/Authorize';
import ContentPublicationForm from 'src/components/content/contentPublication/Forms';
import { Root } from 'src/components/styled';
import validateSchema from '../../Schema';

export interface DetailsEditFormProps {
  model: ContentPublicationsModel;
  contentPublication: ContentPublicationsResource;
  onHandleRefreshContentPublication: () => Promise<void>;
  onSwitchMode: (isCancel: boolean) => void;
  onSubmit?: (value: ContentPublicationsResource) => void;
}

const DetailsEditForm: FC<DetailsEditFormProps> = ({
  model,
  contentPublication,
  onHandleRefreshContentPublication,
  onSwitchMode,
  onSubmit
}) => {

  const switchMode = (isCancel: boolean) => {
    onSwitchMode(isCancel);
  };

  const handleSubmit = async (values: ContentPublicationsResource) => {
    if(onSubmit) {
      onSubmit(values);
      return;
    }
    const model = new ContentPublicationsModel();
    const newCover = values.cover;
    let submitData = values as ContentPublicationsResourceForEdit;
    submitData.cover = newCover;
    if (submitData && submitData.code) {
      const response = await model.updateContentPublication(submitData);
      if (!model.error) {
        printMessage({
          status: response.status,
          message: response.message
        });
        await onHandleRefreshContentPublication();
      }
    } 
  };

  return (
    <Formik
      initialValues={{...ContentPublicationsModel.getInitialValues(contentPublication)}}
      validationSchema={validateSchema(model)}
      validateOnChange={false}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleSubmit,
        setFieldValue,
        values,
        touched,
        isSubmitting
      }) => (
        <form onSubmit={handleSubmit}>
          <Root>
            <ContentPublicationForm
              model={model} 
              contentPublication={values} 
              isCreate={false}
              errors={errors}
              touched={touched}
              onHandleBlur={handleBlur}
              onHandleChange={handleChange}
              onHandleChangeImageUrl={handleChangeImageUrl}
              setFieldValue={setFieldValue}
            />
          </Root>
          <Box>
            <Authorize
              resourceCode={model.ResourceCode}
              mode={Mode.edit}
            >
              <CaijButtonSubmit disabled={isSubmitting} sx={btnSubmit} />
            </Authorize>
            <CaijButtonReset disabled={isSubmitting} onSwitchMode={switchMode} />
          </Box>
        </form>
      )}
    </Formik>
  );
};

DetailsEditForm.propTypes = {
  contentPublication: PropTypes.object.isRequired,
  onHandleRefreshContentPublication: PropTypes.func.isRequired,
  onSwitchMode: PropTypes.func.isRequired,
  onSubmit: PropTypes.func
};

DetailsEditForm.defaultProps = {
  onSubmit: null
};

export default DetailsEditForm;
