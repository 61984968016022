import {
  Box,
  CardContent,
  Grid
} from '@mui/material';
import PropTypes from 'prop-types';
import { FC } from 'react';
import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import {
  AccessPermissions,
  ANIMATION_TIMING, 
  Authorize, 
  btnSubmit, 
  CaijButtonReset,
  CaijButtonSubmit,
  DocCollectionModel, 
  Formik,
  handleChange, 
  ListType, 
  Mode
} from 'src/common';
import type {
  DocumentCollectionResource,
  DocumentCollectionResourceForEdit,
} from 'src/common/types';
import AccessTypeForm from 'src/components/access/AccessTypeForm';
import printMessage from 'src/views/errors/MessageError';
import Subscriptions  from 'src/components/listInfo/checkList';
import MyCard from 'src/components/card/MyCard';

interface SubscriptionFormProps {
  model: DocCollectionModel;
  docCollection: DocumentCollectionResource;
  onHandleRefreshDocCollection: () => Promise<void>;
  onSwitchMode: (isCancel: boolean) => void;
}

const SubscriptionForm: FC<SubscriptionFormProps> = ({
  model,
  docCollection,
  onHandleRefreshDocCollection,
  onSwitchMode,
}) => {
  const [selectedSubscriptionDocCollections, setSelectedSubscriptionDocCollections] = useState<{ ids: number[], selected: boolean }>({
    ids: [],
    selected: false
  });
  const [isAuth, setIsAuth] = useState<boolean>();

  const switchMode = (isCancel: boolean) => {
    onSwitchMode(isCancel);
  };

  return (
    <>
      <Formik
        initialValues={{
          ...docCollection,
          access: docCollection.access || AccessPermissions.Private,
          submit: null,
        }}
        onSubmit={async values => {
          const submitData = docCollection as DocumentCollectionResourceForEdit;
          if (submitData && submitData.id) {
            submitData.access = values.access;
            const model = new DocCollectionModel();
            model.setSubmitListData(submitData, selectedSubscriptionDocCollections);
            const response = await model.updateDocCollection(submitData);
            if (!model.error) {
              printMessage({
                status: response.status,
                message: response.message,
              });
              await onHandleRefreshDocCollection();
            }
          }
        }}
      >
        {({ errors, handleSubmit, setFieldValue, values, isSubmitting, touched }) => (
          <form onSubmit={handleSubmit}>
            <MyCard>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <Box>
                      <AccessTypeForm
                        data={values}
                        errors={errors}
                        touched={touched}
                        onHandleChange={handleChange}
                        setFieldValue={setFieldValue}
                      />
                      <Box mt={2}>
                        <Authorize resourceCode={model.ResourceCode} mode={Mode.edit} onIsAuth={l => setIsAuth(l)}>
                          <CaijButtonSubmit disabled={!isAuth || isSubmitting} sx={btnSubmit} />
                        </Authorize>
                        <CaijButtonReset disabled={isSubmitting} onSwitchMode={switchMode} />
                      </Box>
                    </Box>
                  </Grid>
                  <CSSTransition
                    in={values.access === AccessPermissions.Private}
                    timeout={ANIMATION_TIMING}
                    classNames='fade'
                    unmountOnExit
                    mountOnEnter
                  >
                    <Grid item md={6} xs={12}>
                      <Subscriptions 
                        listType={ListType.CollectionSubscription}
                        lists={values?.subscriptions}
                        onHandleSelectedItems={l =>
                          setSelectedSubscriptionDocCollections({
                            ...selectedSubscriptionDocCollections,
                            ids: l,
                            selected: true,
                          })
                        }
                      />
                    </Grid>
                  </CSSTransition>
                </Grid>
              </CardContent>
            </MyCard>
          </form>
        )}
      </Formik>
    </>
  );
};

SubscriptionForm.propTypes = {
  docCollection: PropTypes.object.isRequired,
  onHandleRefreshDocCollection: PropTypes.func.isRequired,
  onSwitchMode: PropTypes.func.isRequired,
};

export default SubscriptionForm;
