import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import type { ContentPublicationsResource } from 'src/common/types';
import {Box,SvgIcon} from '@mui/material';
import {ContentPublicationsModel, formatDate, labelConfig } from 'src/common';
import { FilePlus as FileIcon } from 'react-feather';
import CaijTypography from 'src/components/typography';
import CaijLabel from 'src/components/label/CaijLabel';
import { usePublication } from 'src/functions/hooks/publication';
import parse from 'html-react-parser';

export interface GeneralInfoProps {
  contentPublication: ContentPublicationsResource;
  model: ContentPublicationsModel;
}

const GeneralInfo: FC<GeneralInfoProps> = ({model, contentPublication}) => {
  const { title, authors, lang, year, callnumber, toc, published } = contentPublication;
  const { sCollection } = usePublication();
  sCollection.docCollections
  return (
    <Box sx={{mb:'2em'}} display='flex'>
      <SvgIcon fontSize='small'>
        <FileIcon />
      </SvgIcon>
      <Box display='flex' marginLeft='0.5em' flexDirection='column'>
        <CaijTypography sx={{ fontWeight: 'bold' }}>Informations générale</CaijTypography>
        { title && <CaijLabel label={model.Titre.Label}>{parse(title) as string}</CaijLabel> }
        { authors && <CaijLabel label={model.Authors.Label}>{authors}</CaijLabel>}
        { lang && <CaijLabel label={labelConfig.Langue}>{ContentPublicationsModel.getLanguageByVal(lang)}</CaijLabel>}
        { year && <CaijLabel label={labelConfig.year}>{year}</CaijLabel> }
        { published && <CaijLabel label={labelConfig.year}>{formatDate(published)}</CaijLabel>}
        { callnumber && <CaijLabel label={model.Callnumber.Label}>{callnumber}</CaijLabel> }
        { toc && <CaijLabel label={model.Toc.Label}>{toc}</CaijLabel>}
      </Box>
    </Box>
  );
};

GeneralInfo.propTypes = {
  contentPublication: PropTypes.object.isRequired
};

export default GeneralInfo;