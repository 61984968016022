import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  TableBody,
  Typography
} from '@mui/material';
import {
  labelConfig,
  Authorize,
  EmptyList,
  EnhancedTableHead,
  Mode,
  CaijButton,
  SubscriptionModel,
  GroupType,
  Label
} from 'src/common';
import { SubscriptionGroupResource } from 'src/common/types';
import MyCard from 'src/components/card/MyCard';
import CaijTable from 'src/components/table/CaijTable';
import CaijDialogs from 'src/components/dialog';
import { EditAccessGroup } from 'src/types/accessGroup';
import { CaijTableCell } from 'src/components/table/CaijTableCell';
import CaijTablePagination from 'src/components/pagination/CaijTablePagination';
import CaijTableCellAction, { IHandleEditAccessGroup } from 'src/components/table/CaijTableCellAction';
import { CaijTableRowClickable } from 'src/components/table/CaijTableRowClickable';
import useSort from 'src/functions/hooks/sort';

export interface ResultsProps {
  subscriptionId: number;
  groupList: SubscriptionGroupResource[];
  isEmptyList: boolean;
  onEditAccessGroup: (value: EditAccessGroup) => void;
  onEditSubscription: () => void;
  onDeleteGroup: (subscriptionId: number, groupId: number) => Promise<void>;
}

const headCells = [
  {
    id: 'id', width: '10%', numeric: false, disablePadding: false, label: 'Id'
  },
  {
    id: 'name', width: '85%', numeric: false, disablePadding: false, label: labelConfig.nameFr
  },
  {
    id: '', width: '5%'
  }
];

const Results: FC<ResultsProps> = ({
  subscriptionId,
  groupList,
  isEmptyList,
  onEditAccessGroup,
  onEditSubscription,
  onDeleteGroup
}) => {
  const [page, setPage] = useState<number>(0);
  const [selectedRow, setSelectedRow] = useState([]);
  const [isCreateAuth, setIsCreateAuth] = useState<boolean>();
  const { order, orderBy, setOrderBy, sort, handleRequestSort } = useSort();
  const model = SubscriptionModel.getInstance();
  model.Dialog.Header = "Supprimer le groupe d'accès";
  model.Dialog.Name = "le groupe d'accès";
  
  useEffect(() => setOrderBy('name'),[]);

  const deleteGroup = async (id: number, groupId: number) : Promise<void>=> {
    await onDeleteGroup(id, groupId);
  };

  return (
    <MyCard>
       <Box p={2} minHeight={56} display='flex' alignItems='center' justifyContent='space-between'>
        <Box />
        <Box display='flex' alignItems='right' justifyContent='space-between'>
          <Authorize resourceCode={model.ResourceCode} mode={Mode.add}  onIsAuth={(l) => setIsCreateAuth(l)}> 
            <CaijButton
              type='add'
              color='secondary'
              variant='contained'
              disabled={!isCreateAuth}
              onHandleClick={() => onEditSubscription()}
            >
              Ajouter un groupe
            </CaijButton>
          </Authorize>
        </Box>
      </Box>
      { isEmptyList && !groupList ? <EmptyList /> : 
        (
          <>
            <CaijTable>
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                headCells={headCells}
              />
              <TableBody>
                { sort<SubscriptionGroupResource>(groupList, page).map((row:SubscriptionGroupResource) => {
                    const isEndorsement = row.groupType == GroupType.Endorsed;
                    return (
                      <CaijTableRowClickable key={row.id} callBack={() => onEditAccessGroup({isEdit: false,groupId: row.id,detailsPage: true,isDefault: row.isDefault})}>
                        <CaijTableCell>{row.id}</CaijTableCell>
                        <CaijTableCell>
                          <Box display='flex' alignItems='center'>
                            <Typography variant='body2' sx={{ color: 'text.secondary', mr: '30px' }}>
                              {row.name}
                            </Typography>
                            {isEndorsement && <Label color={'warning5'}>Cautionnement</Label>}
                            {row.isDefault && <Label color={'secondary'}>Par défault</Label>}
                          </Box>
                        </CaijTableCell>
                        <CaijTableCellAction 
                          group={row}
                          resourceCode={model.ResourceCode}
                          handleEditAccessGroup={(l: IHandleEditAccessGroup) => onEditAccessGroup({...l})}
                          handleToggle={() => SubscriptionModel.handleToggle(row.id, selectedRow, setSelectedRow)}
                        >
                          <CaijDialogs
                            dialog={model.Dialog}
                            isOpen={selectedRow.indexOf(row.id) !== -1}
                            onSubmit={async () => {
                              await deleteGroup(subscriptionId, row.id);
                              setSelectedRow([]);
                            }}
                            setSelectedRow={setSelectedRow}
                          />
                        </CaijTableCellAction>
                      </CaijTableRowClickable>
                    )})
                  }
                </TableBody>
            </CaijTable>
            <CaijTablePagination
              showTablePaginationLight 
              len={groupList.length}
              page={page}
              setPage={(l) => setPage(l)}
            />
        </>
      )
    }
    </MyCard>
  );
};

Results.propTypes = {
  subscriptionId: PropTypes.number.isRequired,
  groupList: PropTypes.array.isRequired,
  isEmptyList: PropTypes.bool.isRequired,
  onEditAccessGroup: PropTypes.func.isRequired,
  onEditSubscription: PropTypes.func.isRequired,
  onDeleteGroup: PropTypes.func.isRequired
};

export default Results;
