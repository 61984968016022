import React, { Dispatch, SetStateAction } from 'react';
import { Box } from '@mui/material';
import {
  _subscription,
  ListType
} from 'src/common';
import type { SubscriptionResource } from 'src/common/types';
import CheckList from 'src/components/listInfo/checkList';
import RequireParameterList from 'src/components/requiredParameter';

interface TypeSelected {
  ids?:number[],
  selected?: boolean
};

interface SubscriptionAccessProps {
  subscription: SubscriptionResource,
  setSelectedProducts: Dispatch<SetStateAction<TypeSelected>>,
  selectedProducts: TypeSelected,
  setSelectedDatabanks: Dispatch<SetStateAction<TypeSelected>>,
  selectedDatabanks: TypeSelected,
  setSelectedDocCollections: Dispatch<SetStateAction<TypeSelected>>,
  selectedDocCollections: TypeSelected,
  setSelectedParameters: Dispatch<SetStateAction<Map<number, string>>>
}

class SubscriptionAccess extends React.Component<SubscriptionAccessProps> {
  constructor(props: SubscriptionAccessProps){
    super(props); 
  }
  render() {
    return(
      <>
          <Box mb={2}>
            <RequireParameterList
              listType={ListType.Product}
              lists={this.props.subscription.products}
              onHandleItemsSelected={l =>
                this.props.setSelectedProducts({
                  ...this.props.selectedProducts,
                  ids: l,
                  selected: true,
                })}
              onHandleRequireParmeters={l => this.props.setSelectedParameters(l)}
            />
          </Box>
          <Box mb={2}>
            <CheckList
              listType={ListType.Databank}
              lists={this.props.subscription.databanks}
              onHandleSelectedItems={l =>
                this.props.setSelectedDatabanks({
                  ...this.props.selectedDatabanks,
                  ids: l,
                  selected: true,
                })
              }
            />
          </Box>
          <Box mb={1}>
            <CheckList
              listType={ListType.Collection}
              lists={this.props.subscription.documentCollections}
              onHandleSelectedItems={l =>
                this.props.setSelectedDocCollections({
                  ...this.props.selectedDocCollections,
                  ids: l,
                  selected: true,
                })
              }
            />
          </Box>
      </>
    );
  }
};

export default SubscriptionAccess;
