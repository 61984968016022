import {
  Success,
  NoContent,
  MSG_NO_CONTENT_ERROR,
  SbGroup,
  Created,
  MSG_SUBSCRIPTION_DELETED_CONFLIT,
  Conflit,
  AccountExpiryMode,
  AccessModel,
  AccExpRenewMode
} from 'src/common';
import type {
  SubscriptionResourceForCreate,
  SubscriptionResourceForEdit,
  Error,
  SubscriptionResource,
  ProductItemResource,
  DatabankItemResource,
  DocumentCollectionItemResource,
  CreateResponse,
  SubscriptionGroupResourceForEdit,
  SubscriptionProductResource,
  SubscriptionGroupResource
} from 'src/common/types';
import {$enum} from "ts-enum-util";
import AppModel from './App';
import axios from 'src/utils/axios';
import { FormModel } from './Form';
import printMessage from 'src/views/errors/MessageError';

type TypeMap = Map<number, string>;
type TypeResourceEdit = SubscriptionResourceForCreate | SubscriptionResourceForEdit;
interface TypeSelected { ids: number[], selected: boolean }
type TypeMapSelected = Map<number, string>;

export default class SubscriptionModel extends AppModel 
{
  private static _instance: SubscriptionModel;
  private _selectedData: TypeMapSelected;
  private _selectedProductParameters: TypeMap; 
 
  readonly Group = new FormModel('group',"Type d'abonnement");
  readonly Name = new FormModel('name',"Nom de l'abonnement",255);
  readonly StartDate = new FormModel('startDate',"Date de début");
  readonly EndDate = new FormModel('endDate',"Date de fin");
  readonly Active = new FormModel('active',"Actif");
  readonly WfProfile = new FormModel('wfProfile',"Profil Symphony", 15);
  readonly Note = new FormModel('note',"Note interne");
  readonly RestrictedAccountCreation = new FormModel('restrictedAccountCreation',"Restreindre la création de comptes sur l’Admin CAIJ aux gestionnaires");
  readonly SendLcapNotif = new FormModel('sendLcapNotif',"Avertir le contact principal lorsqu'un membre se désabonne des envois courriels");

  //Access Group
  readonly AccessGroupName = new FormModel('name','Nom', 45);
  readonly AccessGroupNote = new FormModel('note','Note interne');
  readonly AccessGroupWfProfile = new FormModel('wfProfile','Profil Symphony', 15);

  //Contact
  readonly ContactName = new FormModel('contact.name',"Nom", 255);
  readonly ContactPhone = new FormModel('contact.phone','Téléphone', 20);
  readonly ContactEmail = new FormModel('contact.email','Courriel', 320);

  constructor(){
    super('/subscription');
    this.initialize();
  }

  private initialize(){
    this._resourceCode = 'SUBSCRIPTION';
    this._headerTitle = 'Liste des abonnements';
    this._btnAddText = 'Ajouter un abonnement';
    this.Path.PathName = '/subscriptions';
  }

  get Section(){
    return {
      resourceCode: this._resourceCode, 
      title: 'Abonnements',
      href: this.Path.Home,
      visible: true
    }
  }

  set SelectedData(selectedData: TypeMapSelected) {
    this._selectedData = selectedData;
  }

  get SelectedData(): TypeMapSelected {
    return this._selectedData;
  }

  set SelectedProductParameters(selectedProductParameters: TypeMap) {
    this._selectedProductParameters = selectedProductParameters;
  }

  get SelectedProductParameters(): TypeMap {
    return this._selectedProductParameters;
  }

  get LogoUrl(){
    return new FormModel('logoUrl', 'Logo', 2000)
  }
  
  getAccountDurationDays(name: 'accountExpiry.expireInDays' | 'subscriptionGroup.accountExpiry.expireInDays'){
    return new FormModel(name,'Nombre de jours', 11);
  }

  getAccountExpiryMode(name: 'accountExpiryMode' | 'subscriptionGroup.accountExpiryMode'){
    return new FormModel(name,'Expiration des comptes');
  }

  getAccountRenewMode(name: 'accountRenewMode' | 'subscriptionGroup.accountRenewMode'){
    return new FormModel(name,'Renouvellement d\'un compte');
  }

  getFormUrl(name: 'formUrl' | 'subscriptionGroup.formUrl'){
    return new FormModel(name,"Lien du formulaire d'abonnement", 255);
  }
  
  getDay(name: string){
    return new FormModel(name,'Jour');
  }

  getMonth(name: string){
    return new FormModel(name,'Mois');
  }

  getEmailTemplateId(name: string){ 
    if(name === 'emailTemplateId' || name === 'subscriptionGroup.emailTemplateId')
      return new FormModel(name,'Gabarit de courriel');
    throw new Error('Nom invalid');
  }

  set Conflit(error: Error) {
		const { status } = error;
		switch(status){
			case Conflit:
				this.error.message = MSG_SUBSCRIPTION_DELETED_CONFLIT;
				break;
			default:
				this.error = error;
				break;
		}
	}

  get Conflit(){
		return this.error;
	}

  static get Access(){
    return new AccessModel(this.getInstance().ResourceCode);
  }
  
  static getSbGroupByVal(value: string){
    return $enum(SbGroup).getKeyOrDefault(value);
  }

  static getAccountExpiryModeByVal(value: string){
    return $enum(AccountExpiryMode).getKeyOrDefault(value);
  }

  static getAccountRenewModeByVal(value: string){
    return $enum(AccExpRenewMode).getKeyOrDefault(value);
  }

  static getInstance(): SubscriptionModel {
    if (!SubscriptionModel._instance) {
      SubscriptionModel._instance = new SubscriptionModel();
    }
    return SubscriptionModel._instance;
  }

  static getInitialValues(values: SubscriptionResource) : SubscriptionResource {
    return {
      ...values,
      startDate: values.startDate || new Date(),
      endDate: values.endDate || null,
      contact: values.contact || {},
      sendLcapNotif: values.sendLcapNotif || false,
      group: values.group || $enum(SbGroup).getKeyOrDefault(SbGroup.B2B),
      ownershipData: 'INDIVIDUAL',
      databanks: values.databanks || [],
      documentCollections: values.documentCollections || [],
      products: values.products || []
    }
  }

  getHeadCells(status: string){
    return [
      {
        id: this.Group.Name, width: '10%', numeric: false, disablePadding: false, label: this.Group.Label
      },
      {
        id: this.Name.Name, width: '35%', numeric: false, disablePadding: false, label: this.Name.Label
      },
      {
        id: this.StartDate.Name, width: '13%', numeric: false, disablePadding: false, label: this.StartDate.Label
      },
      {
        id: this.EndDate.Name, width: '13%', numeric: false, disablePadding: false, label: this.EndDate.Label
      },
      {
        id: '', width: '5%', numeric: false, disablePadding: false, label: status
      },
      {
        id: '', width: '0%'
      }
    ];
  };

  validateOnApprovalRenewMode = (accExpRenewMode: string) => accExpRenewMode === SubscriptionModel.getAccountRenewModeByVal(AccExpRenewMode.OnApproval);
      
  async getSubscriptions(): Promise<SubscriptionResource[]> {
    let subscriptions: SubscriptionResource[];
    await axios.get<SubscriptionResource[]>(this.route).then(async response => {
      const { status, data } = response;
      try {
          if (status === Success) {
            subscriptions = data;
          } else if (status === NoContent) {
            throw new Error(MSG_NO_CONTENT_ERROR);
          }
      } catch (ex) {
          this.error = { status, message: ex.message };
          if(!this.skipHandleError){
            await this.handleError(this.error);
          }
        }
    },async error => {
        this.error = error;
        if(!this.skipHandleError){
          await this.handleError(this.error);
        }
      },
    );
    return subscriptions;
  }

  async getSubscriptionById(id: string): Promise<SubscriptionResource> {
    let subscription: SubscriptionResource;
    await axios.get<SubscriptionResource>(`${this.route}/${+id}`).then(async response => {
      const { status, data } = response;
      try {
          if (status === Success) {
            subscription = data;
          } else if (status === NoContent) {
            throw new Error(MSG_NO_CONTENT_ERROR);
          }
        } catch (ex) {
          this.error = { status, message: ex.message };
          if(!this.skipHandleError){
            await this.handleError(this.error);
          }
        }
      },
      async error => {
        this.error = error;
        if(!this.skipHandleError){
          await this.handleError(this.error);
        }
      },
    );
    return subscription;
  }

  async updateSubscription(submitData: TypeResourceEdit): Promise<Error> {
    let result: Error;
    await axios.put<SubscriptionResourceForEdit, Error>(`${this.route}/${submitData.id}`, submitData).then(async response => {
        const { status } = response;
        try {
          if (status === Success) {
            result = response;
            result.message = 'Abonnement modifié.';
          } else if (status === NoContent) {
            throw new Error(MSG_NO_CONTENT_ERROR);
          }
        } catch (ex) {
          this.error = { status, message: ex.message };
          await this.handleError(this.error);
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return result;
  }

  async insertSubscription(submitData: TypeResourceEdit): Promise<CreateResponse> {
    let result: CreateResponse;
    await axios.post<SubscriptionResourceForCreate, CreateResponse>(this.route, submitData).then(async response => {
        const { status } = response;
        if (status === Created) {
          result = response;
          result.message = 'Abonnement créé.';
        } 
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return result;
  }

  async deleteSubscription(id: number): Promise<Error> {
    let result: Error;
    await axios.delete<any, Error>(`${this.route}/${id}`).then(response => {
        const { status } = response;
        if (status === Success) {
          result = response;
          result.message = 'Abonnement supprimé.';
        }
      },
      async error => {
        this.error = error;
        this.Conflit = this.error;
        await this.handleError(this.Conflit);
      },
    );
    return result;
  }

//#region Groups
  async getGroups(): Promise<SubscriptionGroupResource[]> {
    let groupList: SubscriptionGroupResource[];
    await axios.get<SubscriptionGroupResource[]>(`${this.route}/groups`).then(async response => {
        const { status, data } = response;
        try {
          if (status === Success) {
            groupList = data;
          } else if (status === NoContent) {
            throw new Error(MSG_NO_CONTENT_ERROR);
          }
        } catch (ex) {
          this.error = { status, message: ex.message };
          if(!this.skipHandleError){
            await this.handleError(this.error);
          }
        }
      },
      async error => {
        this.error = error;
        if(!this.skipHandleError){
          await this.handleError(this.error);
        }
      },
    );
    return groupList;
  }

  async getGroupList(id: number): Promise<SubscriptionGroupResource[]> {
    let groupList: SubscriptionGroupResource[];
    await axios.get<SubscriptionGroupResource[]>(`${this.route}/${id}/group`).then(async response => {
        const { status, data } = response;
        try {
          if (status === Success) {
            groupList = data;
          } else if (status === NoContent) {
            throw new Error(MSG_NO_CONTENT_ERROR);
          }
        } catch (ex) {
          this.error = { status, message: ex.message };
          if(!this.skipHandleError){
            await this.handleError(this.error);
          }
        }
      },
      async error => {
        this.error = error;
        if(!this.skipHandleError){
          await this.handleError(this.error);
        }
      },
    );
    return groupList;
  }

  async getGroup(id: number, groupId: number): Promise<SubscriptionGroupResource> {
    let group: SubscriptionGroupResource;
    await axios.get<SubscriptionGroupResource>(`${this.route}/${id}/group/${groupId}`).then(async response => {
      const { status, data } = response;  
      try {
          if (status === Success) {
            group = data;
          } else if (status === NoContent) {
            throw new Error(MSG_NO_CONTENT_ERROR);
          }
        } catch (ex) {
          this.error = { status, message: ex.message };
        }
      },
      async error => {
        this.error = error;
      },
    );
    return group;
  }

  async insertGroup(id: number, submitData: SubscriptionGroupResourceForEdit): Promise<CreateResponse> {
    let result: CreateResponse;
    await axios.post<SubscriptionGroupResourceForEdit, CreateResponse>(`${this.route}/${id}/group`, submitData).then(async response => {
      const { status } = response;  
      try {
          if (status === Created) {
            result = response;
            result.message = 'Groupe d\'accès créé.';
          }
        } catch (ex) {
          this.error = { status, message: ex.message };
          await this.handleError(this.error);
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return result;
  }

  async updateGroup(id: number, groupId: number, submitData: SubscriptionGroupResourceForEdit): Promise<Error> {
    let result: Error;
    await axios.put<SubscriptionGroupResourceForEdit, Error>(`${this.route}/${id}/group/${groupId}`, submitData).then(
      async response => {
        const { status } = response;
        try {
          if (status === Success) {
            result = response;
            result.message = 'Groupe d\'accès modifié.';
          }
        } catch (ex) {
          this.error = { status, message: ex.message };
        }
      },
      async error => {
        this.error = error;
        this.handle503Error(error.status);
        this.handle400Error(error);
      },
    );
    return result;
  }

  async deleteGroup(id: number, groupId: number): Promise<boolean> {
    let success: boolean = false;
    await axios.delete<any, Error>(`${this.route}/${id}/group/${groupId}`).then(response => {
        const { status } = response;
        if (status === Success) {
          printMessage({status,message: 'Groupe d\'accès supprimé.'});
          success = true;
        }
      },async error => {
        this.error = error;
        this.handle503Error(error.status);
        this.handle400Error(error);
      },
    );
    return success;
  }
//#endregion Groups
  private getSelectedProductParameters = (key: number): string => {
    let data: string = null;
    if (this.SelectedProductParameters && this.SelectedProductParameters.has(key)) {
      data = this.SelectedProductParameters.get(key);
    }
    return data;
  }

  private getSelectedProduct(selectedProducts?: TypeSelected) : SubscriptionProductResource[] {
      return selectedProducts.ids.reduce((initialValues: SubscriptionProductResource[], id: number) => {
        const parameter = +this.getSelectedProductParameters(id);
        initialValues.push({
          id,
          parameter: isNaN(parameter) || parameter === 0 ? null : parameter,
        });
        return initialValues;
      }, []);
  }

  getSubmitListData(submitData: TypeResourceEdit, data: SubscriptionResource, selectedProducts?: TypeSelected,selectedDatabanks?: TypeSelected, selectedDocCollections?: TypeSelected) : void {
      const { products, databanks, documentCollections } = data;
      if(selectedProducts.selected){
        submitData.products = this.getSelectedProduct(selectedProducts);
      }else{
        submitData.products = Array.isArray(products) ? products.reduce((initialVal: SubscriptionProductResource[], currentVal: ProductItemResource) => {
          initialVal.push({
            id:currentVal.id,
            parameter: currentVal.parameter
          });  
          return initialVal;
        },[]) : [];
      }
      if (selectedDatabanks.selected) {
        submitData.databanks = selectedDatabanks.ids;
      }else{
        submitData.databanks = Array.isArray(databanks) ? databanks.map((subscriptionDatabank: DatabankItemResource)=> subscriptionDatabank.id) : [];
      }
      if(selectedDocCollections.selected){
        submitData.documentCollections = selectedDocCollections.ids;
      }else{
        submitData.documentCollections = Array.isArray(documentCollections) ? documentCollections.map((sbDocumentCollection: DocumentCollectionItemResource) => sbDocumentCollection.id) : [];
      }
  }

  saveListAccessGroupRecords(submitData: SubscriptionGroupResourceForEdit, data: SubscriptionGroupResource, selectedProducts?: TypeSelected,selectedDatabanks?: TypeSelected, selectedDocCollections?: TypeSelected) : void {
    const { products, databanks, documentCollections } = data;
    if(selectedProducts.selected){
      submitData.products = this.getSelectedProduct(selectedProducts);
    }else{
      submitData.products = Array.isArray(products) ? products.reduce((initialVal: SubscriptionProductResource[], currentVal: ProductItemResource) => {
        initialVal.push({
          id: currentVal.id,
          parameter: currentVal.parameter
        });  
        return initialVal;
      },[]) : [];
    }
    if(selectedDatabanks.selected){
      submitData.databanks = selectedDatabanks.ids;
    }else{
      submitData.databanks = Array.isArray(databanks) ? databanks.map((subscriptionDatabank: DatabankItemResource)=> subscriptionDatabank.id) : [];
    }
    if(selectedDocCollections.selected){
      submitData.documentCollections = selectedDocCollections.ids
    }else{
      submitData.documentCollections = Array.isArray(documentCollections) ? documentCollections.map((sbDocumentCollection: DocumentCollectionItemResource) => sbDocumentCollection.id) : [];
    }
  }

  validateContact(values: SubscriptionResource, B2B: string, printMessage: any) : boolean {
    const { sendLcapNotif, contact, group } = values;
    if(group === B2B && sendLcapNotif && (!contact.name || !contact.email)){
      if(!contact.name){
        location.href = '#name';
        document.getElementById('contact.name').focus();
      }else{
        location.href = '#email';
        document.getElementById('contact.email').focus();
      }
      printMessage();
      return false;
    }
    return true;
  }

  async getRefreshSubscription(id: string): Promise<SubscriptionResource> {
    let newSubscription : SubscriptionResource;
    const subscription = await this.getSubscriptionById(id);
    if(!this.error){
      newSubscription = subscription;
    }
    return newSubscription;
  }
}
