import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import type { DocumentCollectionResource } from 'src/common/types';
import {
  Box,
  Divider
} from '@mui/material';
import {CaijCheckbox, DocCollectionModel} from 'src/common';
import MyCard from 'src/components/card/MyCard';
import CardHeaderDetail from 'src/components/card/CardHeaderDetail';
import { useDocumentType } from 'src/functions/hooks/publication';

export interface ContentTypesProps {
  docCollection: DocumentCollectionResource;
  model: DocCollectionModel;
}

const ContentTypes: FC<ContentTypesProps> = ({model, docCollection}) => {
  const { isLoading, isEmptyList, isAuthorize, documentTypes } = useDocumentType();
  return (
    <MyCard>
      <CardHeaderDetail title={model.DocumentType.Label} />
      <Divider />
      <Box display='flex' flexDirection='column'>
        { (isLoading && !isEmptyList && isAuthorize) && documentTypes.map(({code, nameFr}) => (
            <Box sx={{marginLeft: '15px'}}>
              <CaijCheckbox label={nameFr} disabled checked={docCollection.documentTypes.includes(code)} />
            </Box>
          )) }
      </Box>
    </MyCard>
  );
};

ContentTypes.propTypes = {
  docCollection: PropTypes.object.isRequired
};

export default ContentTypes;
