import React, { useState } from 'react';
import type { FC, ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import {
  labelConfig,
  CaijInput,
  CaijInputPhone,
  CaijInputEmail,
  CaijCard,
  FormikTouched,
  FormikErrors,
  CaijInputAddress,
  SubscriptionModel
} from 'src/common';
import type { SubscriptionResource } from 'src/common/types';
import { Box } from '@mui/material';

interface ContactFormProps {
  model: SubscriptionModel;
  subscription: SubscriptionResource;
  errors: FormikErrors<SubscriptionResource>;
  touched: FormikTouched<SubscriptionResource>;
  handleBlur: (e: ChangeEvent<HTMLInputElement>) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const ContactForm: FC<ContactFormProps> = ({
  model,
  subscription,
  errors,
  touched,
  handleBlur,
  setFieldValue
}) => {
  const { contact } = subscription;
  const [name, setName] = useState<string>(contact?.name);
  const [phone, setPhone] = useState<string>(contact?.phone || '');
  const [email, setEmail] = useState<string>(contact?.email);
  const [line1, setLine1] = useState<string>(contact.address?.line1 || '');
  const [line2, setLine2] = useState<string>(contact.address?.line2 || '');
  const [city, setCity] = useState<string>(contact.address?.city || '');
  const [country, setCountry] = useState<string>(contact.address?.country || '');
  const [state, setState] = useState<string>(contact.address?.state || ''); 
  const [zip, setZip] = useState<string>(contact.address?.zip || '');
  const { 
    ContactName, ContactEmail, ContactPhone, AddressLine1, AddressLine2, AddressCity, 
    AddressState, AddressZip, AddressCountry
  } = model;
  
  return (
    <CaijCard title="Personne contact">
        <div id="name">
            <CaijInput
                name={ContactName.Name}
                id={ContactName.Name}
                value={name}
                label={ContactName.Label}
                error={Boolean((touched.contact?.name) && (errors.contact?.name))}
                helperText={(touched.contact?.name) && (errors.contact?.name)}
                inputAttr={{
                    maxLength: ContactName.MaxLength, 
                    'data-testid': ContactName.Name,
                    autoComplete: 'none' 
                }}
                InputLabelProps={{ shrink: true }}
                onHandleChange={(e:ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                onHandleBlur={(e:ChangeEvent<HTMLInputElement>) => {
                    contact.name = name;
                    handleBlur(e);
                }}
                setFieldValue={setFieldValue}
            />
        </div>
        <div id="email">
            <CaijInputEmail
                name={ContactEmail.Name}
                title="contactEmail"
                id={ContactEmail.Name}
                value={email}
                label={ContactEmail.Label}
                error={Boolean((touched.contact?.email) && (errors.contact?.email))}
                helperText={(touched.contact?.email) && (errors.contact?.email)}
                InputLabelProps={{ shrink: true }}
                inputProps={{ maxLength: ContactEmail.MaxLength, 'data-testid': ContactEmail.Name }}
                onHandleChange={(e:ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                onHandleBlur={(e:ChangeEvent<HTMLInputElement>) => {
                    contact.email = email;
                    handleBlur(e);
                }}
                setFieldValue={setFieldValue}
            />
        </div>
        <CaijInputPhone
            name={ContactPhone.Name}
            title="contactPhone"
            value={phone}
            label={ContactPhone.Label}
            error={Boolean((touched.contact?.phone) && (errors.contact?.phone))}
            helperText={(touched.contact?.phone) && (errors.contact?.phone)}
            InputLabelProps={{ shrink: true }}
            inputProps={{ maxLength: ContactPhone.MaxLength, 'data-testid': ContactPhone.Name }}
            onHandleChange={(e:ChangeEvent<HTMLInputElement>) => setPhone(e.target.value)}
            onHandleBlur={(e:ChangeEvent<HTMLInputElement>) => {
                contact.phone = phone;
                handleBlur(e);
            }}
            setFieldValue={setFieldValue}
        />
        <CaijInputAddress
            name="contact.address.line1" 
            title="line1"
            value={line1} 
            label={AddressLine1.Label}
            InputLabelProps={{ shrink: true }} 
            error={Boolean((touched.contact?.address?.line1) && ( errors.contact?.address?.line1))}
            helperText={(touched.contact?.address?.line1) && (errors.contact?.address?.line1)}
            inputProps={{ maxLength: AddressLine1.MaxLength, 'data-testid': AddressLine1.Name }}
            onHandleChange={(e:ChangeEvent<HTMLInputElement>) => setLine1(e.target.value)}
            onHandleBlur={(e:ChangeEvent<HTMLInputElement>) => {
                contact.address.line1 = line1;
                handleBlur(e);
            }}
            setFieldValue={setFieldValue}
        />
        <CaijInputAddress
            name="contact.address.line2"
            title="line2"
            value={line2}
            label={AddressLine2.Label}
            InputLabelProps={{ shrink: true }} 
            error={Boolean((touched.contact?.address?.line2) && (errors.contact?.address?.line2))}
            helperText={(touched.contact?.address?.line2) && (errors.contact?.address?.line2)}
            inputProps={{ maxLength: AddressLine2.MaxLength, 'data-testid': AddressLine2.Name }}
            onHandleChange={(e:ChangeEvent<HTMLInputElement>) => setLine2(e.target.value)}
            onHandleBlur={(e:ChangeEvent<HTMLInputElement>) => {
                contact.address.line2 = line2;
                handleBlur(e);
            }}
            setFieldValue={setFieldValue}
        />
        <CaijInput
            name="contact.address.city"
            value={city}
            label={AddressCity.Label}
            error={Boolean((touched.contact?.address?.city) && (errors.contact?.address?.city))}
            helperText={(touched.contact?.address?.city) && (errors.contact?.address?.city)}
            inputAttr={{
                maxLength: AddressCity.MaxLength, 
                'data-testid': 'city',
                autoComplete: 'none' 
            }}
            InputLabelProps={{ shrink: true }}
            onHandleChange={(e:ChangeEvent<HTMLInputElement>) => setCity(e.target.value)}
            onHandleBlur={(e:ChangeEvent<HTMLInputElement>) => {
                contact.address.city = city;
                handleBlur(e);
            }}
            setFieldValue={setFieldValue}
        />
        <Box display='flex' alignItems='center' justifyContent='space-between'>
            <Box>
                <CaijInput
                    name="contact.address.country"
                    label={AddressCountry.Label}
                    value={country}
                    error={Boolean((touched.contact?.address?.country) && (errors.contact?.address?.country))}
                    helperText={(touched.contact?.address?.country) && (errors.contact?.address?.country)}
                    inputAttr={{
                        maxLength: AddressCountry.MaxLength, 
                        'data-testid': 'country',
                        autoComplete: 'none' 
                    }}
                    InputLabelProps={{ shrink: true }}
                    onHandleChange={(e:ChangeEvent<HTMLInputElement>) => setCountry(e.target.value)}
                    onHandleBlur={(e:ChangeEvent<HTMLInputElement>) => {
                        contact.address.country = country;
                        handleBlur(e);
                    }}
                    setFieldValue={setFieldValue}
                />
            </Box>
            <Box>
                <CaijInput
                    name="contact.address.state"
                    label={AddressState.Label}
                    value={state}
                    error={Boolean((touched.contact?.address?.state) && (errors.contact?.address?.state))}
                    helperText={(touched.contact?.address?.state) && (errors.contact?.address?.state)}
                    inputAttr={{ 
                        maxLength: AddressState.MaxLength,  
                        'data-testid': 'state',
                        autoComplete: 'none' 
                    }}
                    InputLabelProps={{ shrink: true }}
                    onHandleChange={(e:ChangeEvent<HTMLInputElement>) => setState(e.target.value)}
                    onHandleBlur={(e:ChangeEvent<HTMLInputElement>) => {
                        contact.address.state = state;
                        handleBlur(e);
                    }}
                    setFieldValue={setFieldValue}
                />
            </Box>
            <Box>
                <CaijInput
                    name="contact.address.zip"
                    label={AddressZip.Label}
                    value={zip}
                    error={Boolean((touched.contact?.address?.zip) && (errors.contact?.address?.zip))}
                    helperText={(touched.contact?.address?.zip) && (errors.contact?.address?.zip)}
                    inputAttr={{ 
                        maxLength: AddressZip.MaxLength, 
                        'data-testid' : 'zip',
                        autoComplete: 'none' 
                    }}
                    InputLabelProps={{ shrink: true }}
                    onHandleChange={(e:ChangeEvent<HTMLInputElement>) => setZip(e.target.value)}
                    onHandleBlur={(e:ChangeEvent<HTMLInputElement>) => {
                        contact.address.zip = zip;
                        handleBlur(e);
                    }}
                    setFieldValue={setFieldValue}
                />
            </Box>
        </Box>
    </CaijCard>
  );
};

ContactForm.propTypes = {
  subscription: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired
};

export default ContactForm;
