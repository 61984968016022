import type { ContentPublishersResource } from 'src/common/types';
import * as actionTypes from '../ActionTypes';

export const fetchContentPublishers = (contentPublishers: ContentPublishersResource[], isLoading?: boolean, isEmptyList?: boolean, isAuthorize?: boolean): {
   type: 'FETCH_CONTENT_PUBLISHERS',
   payload: {
     contentPublishers: ContentPublishersResource[],
     isLoading?: boolean,
     isEmptyList?: boolean,
     isAuthorize?: boolean
   }
} => ({
  type: actionTypes.FETCH_CONTENT_PUBLISHERS,
  payload: {
    contentPublishers,
    isLoading,
    isEmptyList,
    isAuthorize
  }
});

export const fetchContentPublisher = (contentPublisher: ContentPublishersResource,isLoading?: boolean): { 
  type: 'FETCH_CONTENT_PUBLISHER', 
  payload: { 
    contentPublisher: ContentPublishersResource,
    isLoading?: boolean
  } 
} => ({
  type: actionTypes.FETCH_CONTENT_PUBLISHER,
  payload: {
    contentPublisher,
    isLoading
  }
});

export const fetchDetailsContentPublisher = (contentPublisher: ContentPublishersResource): {
  type: 'FETCH_DETAILS_CONTENT_PUBLISHERS'
  payload: {
    contentPublisher: ContentPublishersResource,
  }
} => ({
  type: actionTypes.FETCH_DETAILS_CONTENT_PUBLISHERS,
  payload: {
    contentPublisher
  }
});

export const deleteContentPublisher = (code: string) : {
  type: 'DELETE_CONTENT_PUBLISHERS'
  payload: {
    code: string,
  }
} => ({
  type: actionTypes.DELETE_CONTENT_PUBLISHERS,
  payload: {
    code
  }
});
