import { SubscriptionModel } from 'src/common';
import type {
  DatabankResource,
  SubscriptionGroupResource,
  SubscriptionResource
} from 'src/common/types';
import * as actionTypes from './ActionTypes';

export const fetchSubscriptions = (
  subscriptions: SubscriptionResource[],
  isLoading?: boolean,
  isEmptyList?: boolean,
  isAuthorize?: boolean,
  model?:SubscriptionModel
): {
  type: 'FETCH_SUBSCRIPTIONS';
  payload: { subscriptions: SubscriptionResource[]; isLoading?: boolean; isEmptyList?: boolean; isAuthorize?: boolean; model?:SubscriptionModel};
} => ({
  type: actionTypes.FETCH_SUBSCRIPTIONS,
  payload: {
    subscriptions,
    isLoading,
    isEmptyList,
    isAuthorize,
    model
  },
});

export const fetchSubscription = (subscription: SubscriptionResource, isLoading?: boolean) : { 
  type: 'FETCH_SUBSCRIPTION'; 
  payload: { 
    subscription: SubscriptionResource,
    isLoading?: boolean
  } 
} => ({
  type: actionTypes.FETCH_SUBSCRIPTION,
  payload: {
    subscription,
    isLoading
  },
});

export const fetchDetailsSubscription = (
  subscription: SubscriptionResource,
  databanks: DatabankResource[]
): {
  type: 'FETCH_DETAILS_SUBSCRIPTION';
  payload: {
    subscription: SubscriptionResource;
    databanks: DatabankResource[];
  };
} => ({
  type: actionTypes.FETCH_DETAILS_SUBSCRIPTION,
  payload: {
    subscription,
    databanks
  },
});

export const fetchGroupList = (groupList: SubscriptionGroupResource[], isLoading?: boolean, isEmptyList?: boolean): {
  type: 'FETCH_GROUP_LIST',
  payload: {
    groupList: SubscriptionGroupResource[],
    isLoading?: boolean,
    isEmptyList?: boolean
  }
} => ({
  type: actionTypes.FETCH_GROUP_LIST,
  payload: {
    groupList,
    isLoading,
    isEmptyList
  }
});

export const fetchGroup = (group: SubscriptionGroupResource, isLoading: boolean, allowedInsert): { 
  type: 'FETCH_GROUP', 
  payload: { group: SubscriptionGroupResource, isLoading: boolean, allowedInsert: boolean } 
} => ({
  type: actionTypes.FETCH_GROUP,
  payload: {
    group,
    isLoading,
    allowedInsert
  }
});

export const addGroup = (group: SubscriptionGroupResource): { 
  type: 'ADD_GROUP', 
  payload: { 
    group: SubscriptionGroupResource
  } 
} => ({
  type: actionTypes.ADD_GROUP,
  payload: {
    group
  }
});

export const updateGroup = (groupId: number, group: SubscriptionGroupResource): { 
  type: 'UPDATE_GROUP', 
  payload: { 
    groupId: number;
    group: SubscriptionGroupResource
  } 
} => ({
  type: actionTypes.UPDATE_GROUP,
  payload: {
    groupId,
    group
  }
});

export const deleteGroup = (groupId: number): { 
  type: 'DELETE_GROUP', 
  payload: { 
    groupId: number
  } 
} => ({
  type: actionTypes.DELETE_GROUP,
  payload: {
    groupId
  }
});




